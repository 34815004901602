<template>
    <v-row class="mx-0" >

    <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
    >
        <v-card>
            <v-card-title>
                <span class="headline my-4">Edit</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                label="Developer name*"
                                v-model="item.name"
                                outlined
                                :rules="[v => !!v || 'Field is required']"
                                required
                                :error-messages="!errors.name ? [] : ['Name Already Exists']"
                                class="mb-0"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                label="Office address"
                                v-model="item.office_address"
                                outlined
                                :rules="[v => !!v || 'Field is required']"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                label="Contact person*"
                                v-model="item.contact_person"
                                outlined
                                :rules="[v => !!v || 'Field is required']"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                label="Contact number*"
                                v-model="item.contact_number"
                                :rules="[v => !!v || 'Field is required']"
                                outlined
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-textarea
                                label="Details"
                                v-model="item.details"
                                :rules="[v => !!v || 'Field is required']"
                                required
                                outlined
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="mb-3">
                            <v-img v-if="image" :src="image"></v-img>
                            <v-img v-else :src="item.pictures" max-height="150" max-width="250"></v-img>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-file-input
                                color="blue accent-4"
                                counter
                                label="Logo"
                                placeholder="Change logo (.jpeg, .jpg, .png)"
                                prepend-icon="mdi-image"
                                outlined
                                :rules="[v => ((item.pictures && item.pictures.length > 0) || !!v) || 'Field is required']"
                                required
                                :show-size="1000"
                                @change="onFileChange"
                            >
                                <template v-slot:selection="{ index, text }">
                                    <v-chip
                                        v-if="index < 2"
                                        color="deep-purple accent-4"
                                        dark
                                        label
                                        small
                                    >
                                        {{ text }}
                                    </v-chip>

                                    <span
                                        v-else-if="index === 2"
                                        class="overline grey--text text--darken-3 mx-2"
                                    >
                                        +{{ imageFiles.length - 2 }} File(s)
                                    </span>
                                </template>
                            </v-file-input>
                        </v-col>
                        <v-col cols="12" v-if="invalidImages.length > 0">
                            <v-alert type="error">
                                Invalid image format:
                                <span v-for="(invalidImage, key) in invalidImages" :key="`invalidImage-${key}`"
                                      class="font-weight-bold"
                                >{{ invalidImage }}</span>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
                <small>*indicates required field</small>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="closeModal"
                >
                    Close
                </v-btn>
                <v-btn
                    color="primary"
                    :loading="loader"
                    :disabled="loader"
                    @click="updateDeveloper"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </v-row>
</template>
<script>

    export default {
        name: 'EditDeveloperModal',
        props: {
            item: {
                type: Object,
                required: true
            },
            errors: {
                type: Object,
                default: {}
            },
            visible: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            image: "",
            imageFiles: "",
            invalidImages: [],
            loader: false
        }),
        computed: {
            dialog: {
                get() {
                    return this.visible
                },
                set(value) {
                    return this.$emit('trigger', value)
                }
            }
        },
        watch: {
            dialog(newVal, oldVal) {
                if (!newVal) {
                    this.loader = false
                }
                this.$nextTick()
                    .then(_ => {
                        this.$refs.form.reset()
                    })
            },
            errors: {
                deep: true,
                handler(newVal, oldVal) {
                    this.onError()
                }
            }
        },
        methods: {
            updateDeveloper() {
                this.loader = true
                let isValid = this.$refs.form.validate()
                if (!isValid) {
                    this.loader = false
                    return
                }
                let formData = new FormData();

                formData.append("name", this.item.name);
                formData.append("contact_number", this.item.contact_number);
                formData.append("contact_person", this.item.contact_person);
                formData.append("office_address", this.item.office_address);
                formData.append("details", this.item.details);
                if (this.item.pictures instanceof File) {
                    formData.append("pictures", this.item.pictures);
                }

                this.$emit('updateDeveloper', {id: this.item.id, formData: formData})
            },
            closeModal() {
                return this.$emit('trigger', false)
            },
            onFileChange(files) {
                if (!files) {
                    return;
                }

                this.image = "";
                this.imageFiles = "";
                this.invalidImages = []; //default to empty

                if (!files.type.match(/.(jpg|jpeg|png)$/i)) {
                    this.invalidImages.push(files.name)
                } else {
                    const reader = new FileReader()
                    reader.onload = (e) => {
                        this.image = reader.result
                        this.item.pictures = files;
                        this.imageFiles = files;
                    }


                    reader.readAsDataURL(files)
                }
            },
            onError() {
                this.loader = false
            }
        }
    }
</script>
<style scoped>
    .col {
        padding-bottom: 0;
        padding-top: 0;
    }
    .row {
        flex: 0 0 auto;
    }
</style>
