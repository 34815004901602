<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          rounded
          v-bind="attrs"
          v-on="on"
          class="ml-2"
        >
          View
        </v-btn>
      </template>
      <v-card>
          <v-btn
              icon
              dark
              class="dialog-btn-close"
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-img :src="item.pictures"></v-img>
          <v-card-title>
            <h1 class="headline">{{ item.name}}</h1>
          </v-card-title>
        <v-card-text>
            <v-list-item>
              <v-icon class="mr-1">mdi-map-marker</v-icon><span class="subheading">{{ item.office_address }}</span>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
            <v-list-item-subtitle>Contact Person</v-list-item-subtitle>
              <v-list-item-title>{{ item.contact_person }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
            <v-list-item-subtitle>Contact Number</v-list-item-subtitle>
              <v-list-item-title>{{ item.contact_number }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle>Details</v-list-item-subtitle>
              <v-list-item-title class="not-hidden">{{ item.details }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: 'ViewDeveloperModal',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>
<style lang="scss" scoped>
.col {
    padding-bottom: 0;
    padding-top: 0;
}
.v-list-item__title {
    overflow: auto;
    white-space: normal;
}
</style>
