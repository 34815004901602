<template>
  <div class="home">
    <Menu />
    <Cover label="Contact YDH Realty Services, Inc."/>
    <v-container>
      <h1 class="text-center mb-8">Do you have property concerns? We can help you.</h1>
        <v-row class="justify-center">
            <v-col md="4" cols="12">
              <v-list-item class="mb-4 mt-4">
                <v-list-item-icon>
                  <v-icon color="blue" x-large>
                    mdi-phone
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Phone</v-list-item-title>
                  <v-list-item-subtitle>Mobile: 0918-227-7700</v-list-item-subtitle>
                  <v-list-item-subtitle>Landline: 032-346-9529</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="blue" x-large>
                    mdi-office-building-marker
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Office</v-list-item-title>
                  <v-list-item-subtitle>2F, Room 4, Lugay Bldg., M.L. Quezon Ave., Maguikay, Mandaue City</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col md="4" cols="12">
              <MessageUs />
            </v-col>
        </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Cover from '@/components/CoverNoSearch.vue'
import MessageUs from '@/components/MessageUs.vue'
import Footer from '@/components/Footer.vue'
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'ContactUs',
  data () {
    return {

    }
  },
  components: {
    Menu,
    Cover,
    MessageUs,
    Footer
  }
}
</script>
<style lang="scss" scoped>
//@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css?family=Karla");
@import '@/styles/styles.scss';

  .container {
    padding: 4rem 0;
    // font-family: 'Lato'!important;
  }

  ::v-deep {
    .v-list-item__subtitle{
      overflow: initial;
      white-space: unset;
    }
    .v-list-item__icon,
    .v-list-item__content {
      align-items: center;
    }
    .container {
      padding: 4rem 0;
    }
    .v-card {
      &.card-section {
        box-shadow: none;
      }
    }
    .v-card .col { padding: 0; }
    .v-card__subtitle{
      color: $primary!important;
      font-size: 1rem;
      font-weight: bold;
      font-family: 'Karla';
      text-transform: uppercase;
      border-bottom: 1px solid $primary;
      padding: 1rem 3rem 1rem 2rem;
    }
    .v-card__text {
      padding: 2rem;
    }
    .v-card__text .text--primary,
    .v-container__main-title {
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: normal;
      margin-bottom: 2rem;
    }
    .v-container__main-title {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
</style>
