<template>
    <v-container fluid>
        <h1>Developers</h1>
        <v-divider class="my-4"></v-divider>
        <v-card-title class="mb-4">
            <AddDeveloperModal
                @createDeveloper="createDeveloper"
                :errors="add_form_errors"
                :visible="addDialogModal"
                @trigger="addDialogModalTrigger"/>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            >
            </v-text-field>
        </v-card-title>
        <v-card>

            <v-data-table
                :headers="headers"
                :items="developers"
                :search="search"
                :loading="loading"
            >
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex my-4">
                        <ViewDeveloperModal :item="item"/>
                        <v-btn
                            color="primary"
                            rounded
                            class="ml-2"
                            @click="openEditModal(item.id)"
                        >
                            Edit
                        </v-btn>
                        <DeleteDeveloperModal :item="item" @deleteDeveloper="deleteDeveloper"/>
                    </div>
                </template>
            </v-data-table>
            <template v-if="current_edited_item">
            <EditDeveloperModal
                :item="current_edited_item"
                @updateDeveloper="updateDeveloper"
                :errors="edit_form_errors"
                :visible="editDialogModal"
                @trigger="editDialogModalTrigger"/>
            </template>
        </v-card>
    </v-container>
</template>
<script>

    import AddDeveloperModal from '@/components/modal/AddDeveloper'
    import EditDeveloperModal from '@/components/modal/EditDeveloper'
    import ViewDeveloperModal from '@/components/modal/ViewDeveloper'
    import DeleteDeveloperModal from '@/components/modal/DeleteDeveloper'
    import {mapActions} from 'vuex'
    import {ACTION} from '@/store/action-types'

    export default {
        name: 'Developers',
        components: {
            AddDeveloperModal,
            EditDeveloperModal,
            ViewDeveloperModal,
            DeleteDeveloperModal
        },
        created() {
            this.loading = true;
            this.get_developers()
            .then(res => {
                this.developers = this.toData(res.data)
                this.loading = false
            })
        },
        data() {
            return {
                search: '',
                headers: [
                    {text: 'Name', value: 'name'},
                    {text: 'Office Address', value: 'office_address', sortable: false},
                    {text: 'Contact Number', value: 'contact_number', sortable: false},
                    {text: 'Contact Person', value: 'contact_person', sortable: false},
                    {text: 'Actions', value: 'actions', class: 'actions', sortable: false}
                ],
                loading: false,
                developers: [],
                add_form_errors: {
                    name: false
                },
                current_edited_item: null,
                addDialogModal: false,
                edit_form_errors: {
                    name: false
                },
                editDialogModal: false
            }
        },
        methods: {
            ...mapActions({
                get_developers: ACTION.GET_DEVELOPERS,
                create_developers: ACTION.CREATE_DEVELOPER,
                update_developers: ACTION.UPDATE_DEVELOPER,
                delete_developers: ACTION.DELETE_DEVELOPER,
            }),
            addDialogModalTrigger(data) {
                this.addDialogModal = data
                if (!data) {
                    this.add_form_errors = Object.assign(this.add_form_errors, {name: false})
                }
            },
            openEditModal(id) {
                let item = Object.assign({}, this.developers.find(item => item.id == id))
                this.$nextTick(_ => {
                    this.current_edited_item = item
                    this.editDialogModal = true
                })
            },
            editDialogModalTrigger(data) {
                this.editDialogModal = data
                if (!data) {
                    this.current_edited_item = null
                    this.edit_form_errors = Object.assign(this.edit_form_errors, {name: false})
                }
            },
            toData(data) {
                return data.map(item => ({
                    id: item.id,
                    name: item.name,
                    office_address: item.office_address,
                    contact_number: item.contact_number,
                    contact_person: item.contact_person,
                    details: item.details,
                    pictures: item.pictures
                }))
            },
            updateDeveloper(payload) {
                this.edit_form_errors = Object.assign(this.edit_form_errors, {name: false})
                this.update_developers(payload)
                    .then((res) => {
                        let dev = res.data;
                        this.developers = this.developers.map(d => {
                            if (d.id == dev.id) {
                                return this.toData([dev])[0]
                            }
                            return d
                        })
                        this.current_edited_item = null
                        this.editDialogModal = false
                    })
                    .catch(res => {
                        let data = res.response.data
                        if (data.name) {
                            this.edit_form_errors = Object.assign(this.edit_form_errors, {name: true})
                        }
                    })
            },
            deleteDeveloper(id) {
                this.delete_developers(id)
                    .then(_ => {
                        this.developers = this.developers.filter(d => d.id != id)
                    })
            },
            createDeveloper({formData, data}) {
                this.add_form_errors = Object.assign(this.add_form_errors, {name: false})
                this.create_developers(formData)
                    .then(res => {
                        let data = this.toData([res.data])
                        this.developers.push(data[0])
                        this.addDialogModal = false
                    })
                    .catch(res => {
                        let data = res.response.data
                        if (data.name) {
                            this.add_form_errors = Object.assign(this.add_form_errors, {name: true})
                        }
                    })
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>
