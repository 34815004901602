<template>
    <v-row class="mb-5">
        <v-col cols="12">
            <h4>{{ title }}</h4>
        </v-col>
        <v-col cols="12">
            <v-file-input
                v-model="image_files"
                color="blue accent-4"
                counter
                accept="image/*"
                :label="title"
                multiple
                placeholder="Add images (.jpeg, .jpg, .png)"
                prepend-icon="mdi-image"
                outlined
                :show-size="1000"
            >
                <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                    >
                        {{ text }}
                    </v-chip>

                    <span
                        v-else-if="index === 2"
                        class="overline grey--text text--darken-3 mx-2"
                    >
                +{{ image_files.length - 2 }} File(s)
              </span>
                </template>
            </v-file-input>
            <v-btn
                color="blue-grey"
                class="ma-2 white--text"
                @click="upload"
            >
                Upload
                <v-icon
                    right
                    dark
                >
                    mdi-cloud-upload
                </v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-sheet
                color="grey lighten-1"
                max-width="1080"
            >
                <v-slide-group
                    class="mx-auto pa-4"
                    show-arrows
                >
                    <div class="caption" v-if="pictures.length == 0">No Images</div>
                    <v-slide-item
                        v-for="img in pictures"
                        :key="img.id + 'img'"
                        v-slot="{ active, toggle }"
                    >
                      <v-card
                        class="ma-4"
                        height="200"
                        width="200"
                      >
                        <v-img
                            :src="img.image"
                            height="200"
                            width="200"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                            <v-app-bar
                                flat
                                color="rgba(0, 0, 0, 0)"
                                class="pt-0 image-toolbar"
                                height="24"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="error"
                                    icon
                                    small
                                    dark
                                    @click="trigger_delete_picture(img.id)"
                                >
                                    <v-icon>mdi-cancel</v-icon>
                                </v-btn>
                            </v-app-bar>
                        </v-img>
                      </v-card>
                    </v-slide-item>
                    <!-- <v-btn
                        v-if="pictures.length > 0"
                        color="info"
                        class="download"
                        large
                        dark
                        @click="downloadImage"
                    >
                        Download Images
                    </v-btn> -->
                </v-slide-group>
            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        props: [
            'project_id',
            'initial_data',
            'upload_fn',
            'delete_fn',
            'title'
        ],
        data: () => ({
            image_files: [],
            pictures: []
        }),
        created() {
            this.$nextTick(() => {
                this.pictures = this.initial_data
            })
        },
        methods: {
            upload() {
                if (this.image_files.length == 0) {
                    return
                }
                let payload = new FormData()

                // let payload = this.image_files.map(item=> ({'image': item}))
                this.image_files.forEach(item => {
                    payload.append('image[]', item)
                });
                this.upload_fn({id: this.project_id, payload})
                    .then((res) => {
                        let data = res.data
                        this.pictures = [...this.pictures, ...data]
                    })
                this.image_files = []
            },
            trigger_delete_picture(id) {
                this.delete_fn(id)
                    .then(() => {
                        this.pictures = this.pictures.reduce((accu, cur) => {
                            if (cur.id != id) {
                                accu.push(cur)
                            }
                            return accu
                        }, [])
                    })
            }
        }
    }
</script>
<style scoped lang="scss">
    .download {
        position: absolute;
        bottom: 0;
        right: 0;
    }
</style>
