import axios from '@/services/axios-auth';

const state = {
    salesperson: [],
    salespersonpage: ''
};

const getters = {
    salesperson: (state) => {
        return state.salesperson;
    },
    salespersonpage: (state) => {
        return state.salespersonpage;
    },
};

const actions = {
    addSalesperson({commit}, data) {
        data.image = null;
        return axios.post('/admin/sales-person/', data)
        .then(response => response)
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
    updateSalesperson({commit}, data) {
        console.log('updateSalesperson', data)
        return axios.patch(`/admin/sales-person/${data.id}/`, data.formData)
        .then(response => response)
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
    getSalespersons({ commit }, listParams) {
        return axios.get(`/admin/sales-person/?${new URLSearchParams(listParams).toString()}`)
    },
    getSalesperson({ commit }, listParams) {
        return axios.get(`/admin/sales-person/${listParams.id}/`)
    },
    deleteSalesperson({commit}, data) {
        return axios.delete(`/admin/sales-person/${data.id}`)
        .then(response => response)
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
};

const mutations = {
    setSalesperson(state, salesperson) {
        state.salesperson = salesperson
    },
    setSalespersonpage(state, salespersonpage) {
        console.log('lead', salespersonpage)
        state.salespersonpage = salespersonpage
    }
};

export default {
    state,
    getters,
    actions,
    mutations
  }
