import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import CKEditor from 'ckeditor4-vue';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faTiktok } from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faTiktok)

/* add font awesome icon component */
Vue.component('font-awesome-icon',FontAwesomeIcon)

Vue.use( CKEditor );
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
