<template>
    <v-app-bar
        app
        color="white"
        light

        flat
        dense
        elevation="3"
        class="justify-space-between header-menu"
    >
        <a class="site-logo d-flex align-center" :href="$router.resolve({ name: 'home', query: { status: 'For Sale'} }).href">YDH Realty</a>
        <div class="menu-container">
            <a :class="{ active : saleStatus }" @mouseover="addClass" @mouseleave="removeClass" :href="$router.resolve({ name: 'home', query: { status: 'For Sale'} }).href">Buy</a>
            <a :class="{ active : rentStatus }" @mouseover="addClass" @mouseleave="removeClass" :href="$router.resolve({ name: 'home', query: { status: 'For Rent'}}).href">Rent</a>
        </div>
    </v-app-bar>
</template>
<script>
    import localforage from 'localforage'


    export default {
        name: 'Menu',
        data() {
            return {}
        },
        computed: {
            loggedIn() {
                return localStorage.getItem('login-token')
            },
            user() {
                return this.$store.getters.user
            },
            saleStatus() {
                return this.$route.name == 'home' || this.$route.name == 'search'  || this.$route.name == 'listingpage' ? this.$route.query ? (this.$route.query.status == 'For Sale' || !this.$route.query.status) ? true : false : false: false;
            },
            rentStatus() {
                return this.$route.query ? this.$route.query.status == 'For Rent' ? true : false : false;
            },
            pageName() {
                return this.$route.name;
            }
        },
        methods: {
          addClass(event) {
            event.target.classList.add('is-active')
          },
          removeClass(event) {
            event.target.classList.add('is-active')
          }
        }
    }
</script>
<style lang="scss" scoped>
  .header-menu{
    .menu-container:hover ::v-deep a,
    .menu-container:hover ::v-deep a.active {
      border-bottom: transparent;
    }
      ::v-deep .v-toolbar__content {
          display: flex;
          align-items: center;
          justify-content: space-between;

          a {
              font-size: 1rem;
              font-weight: bold;
              color: #424242;
              text-decoration: none;
              padding: 4px 1rem 4px;
              &.active{
                  border-bottom: 4px solid $primary;
              }
              &:hover {
                  border-bottom: 4px solid $primary!important;
              }
          }

          .site-logo img {
              height: 40px;
          }
          .site-logo {
            font-weight: bold;
            font-size: 1.1em;
            letter-spacing: .5px;
            color: $primary;
            &:hover {
              border-bottom: 0!important;
            }
          }
      }

      sup {
        color: #fff;
        font-size: 10px;
        background-color: #000;
        padding: 8px 5px 8px 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 4px;
        margin-left: 2px;
      }
  }
</style>
