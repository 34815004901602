<template>
  <v-overlay
    :absolute="false"
    :opacity="opacity"
    :value="true"
    :z-index="9"
    class="login-overlay"
  >
    <v-card
      width="100%"
      max-width="500"
      light
      class="mx-auto"
    >
      <v-col class="pa-10">
        <v-img
          :src="require('../assets/ydh-logo.jpg')"
          contain
          height="200"
        />
        <v-card-title primary-title class="mt-4">
          <h2 class="login-headline text-center blue--text text--darken-4">Register</h2>
        </v-card-title>
        <v-card
          class="mb-5 pa-5"
          outlined
        >
          <form>
            <v-text-field
              prepend-icon="far fa-person"
              label="Name"
              placeholder=""
              v-model="name"
              required
            ></v-text-field>
            <v-text-field
              prepend-icon="far fa-envelope"
              label="Email address"
              placeholder=""
              v-model="email"
              required
            ></v-text-field>
            <v-text-field
              prepend-icon="fas fa-lock"
              label="Password"
              placeholder=""
              v-model="password"
              required
              class="mb-2"
            ></v-text-field>
            <v-btn @click="createAccount" round color="primary mr-2 ">Create Account</v-btn>
          </form>
        </v-card>
        <div class="my-5">Or you can join with:</div>
        <v-row class="mx-4">
          <i class="fab fa-facebook fa-2x blue--text text--darken-3"></i>
        </v-row>
      </v-col>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: 'Registration',

  data: () => ({
    opacity: 0.6,
    name: '',
    email: '',
    password: '',
    password_confirmation: ''
  })
}
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Pacifico");
@import '@/styles/styles.scss';

.login-overlay {
  font-family: 'Ubuntu'!important;
  height: 100vh;
  width: 100%;
  background: rgb(35,151,203);
  background: linear-gradient(90deg, rgba(35,151,203,1) 0%, rgba(14,30,103,1) 100%);
  position: fixed;
  .v-overlay__content{
    width: 100%;
    margin: 20px;
  }
  .login-headline {
    font-family: 'Pacifico'!important;
    width: 100%;
  }
  .v-btn{
    box-shadow: none;
  }
}
</style>
