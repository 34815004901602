<template>
    <v-card class="pa-4 contact-agent-form">
        <h2 class="text-h4 font-weight-bold mb-3 mt-4 text-center">More about this property</h2>
        <v-form v-model="valid">

            <v-text-field
                type="text"
                label="Full name"
                v-model="data.fullname"
                :rules="rules"
                required
                outlined
            ></v-text-field>

        <v-text-field
            type="email"
            label="Email address"
            placeholder=""
            v-model="data.email"
            outlined
            :rules="emailRules"
        ></v-text-field>

        <v-text-field
            type="phone"
            label="Phone number"
            v-model="data.phone"
            outlined
            :rules="rules"
        ></v-text-field>
        <v-textarea
            label="Message"
            v-model="data.message"
            outlined
            :rules="rules"
            rows="4"
            row-height="60"
        ></v-textarea>
        <v-btn
          @click="contactAgent"
          :disabled="!valid"
            rounded
            x-large
            block
            color="primary"
            class="mt-4"
            >Contact an agent</v-btn>
        <div class="mt-4">
            <small>By proceeding, you consent to receive calls and texts from real estate professionals such as brokers and agents at the number you provided.</small>
        </div>
      </v-form>
    </v-card>
</template>

<script>
export default {
  name: 'ContactAgengForm',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      valid: false,
      data: {
        fullname: '',
        email: '',
        phone: '',
        message: ''
      },
      value: '',
      rules: [
        value => !!value || 'Required.',
      ],
      emailRules: [
        value => !!value || 'E-mail is required',
        value => /.+@.+/.test(value) || 'E-mail must be valid',
      ],
    }
  },
  methods: {
    contactAgent () {

    }
  }
}
</script>
