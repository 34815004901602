export default {
    FETCH_LISTINGS: '/listing',
    FETCH_LISTING: '/listing/', // slug `/listing/slug`

    FETCH_DEVELOPER: '/developer/',
    FETCH_PROJECT: '/project/',

     // admin
    // GET, POST, DELETE, PATCH
    // For single object add id e.g. '/admin/developer/{id}'

    FETCH_ADMINLISTINGS: '/admin/listing/',
    ADMINLISTING: '/admin/listing/',
    ADMIN_LISTING_IMAGES: '/admin/listing-images/',

    ADMIN_AMENITIES: '/admin/listing/fetch_create_data/',
    ADMIN_LISTING_FETCH_CREATE_DATA: '/admin/listing/fetch_create_data/',

    FETCH_ADMINDEVELOPERS: '/admin/developer/',
    ADMINDEVELOPER: '/admin/developer/',

    FETCH_ADMINPROJECTS: '/admin/projects/',
    ADMINPROJECT: '/admin/project/',
    ADMIN_PROJECT_IMAGES: '/admin/project-images/',

    FETCH_ADMINAMENITIES: '/admin/amenities/',
    ADMINAMENITY: '/admin/amenities/',

    LOGIN: '/admin/login/',
    REFRESH: '/admin/login/refresh',

    // DEVELOPER: '/admin/developer/',
    // PROJECT: '/admin/project/',
    //leads
    FETCH_LEADS: '/admin/leads/',
    FETCH_BROKERS: '/broker/'
}
