<template>
    <v-container class="pt-0">
        <v-carousel
          cycle
          height="500"
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item
            v-for="(image, key) in images"
            :key="`images-${key}`"
            :src="image"
            @click="index = key"
          ></v-carousel-item>
        </v-carousel>
        <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
    </v-container>
</template>
<script>
import VueGallerySlideshow from 'vue-gallery-slideshow'

export default {
  name: 'Gallery',
  props: {
    images: {
      type: Array,
      required: true,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      index: null
    }
  },
  components: {
    VueGallerySlideshow
  }
}
</script>
<style lang="scss" scoped>
    ::v-deep {
        .vgs__container { height: 100%; }
        .vgs__gallery { padding-left: 2rem; }
        .v-btn--round .v-btn__content .v-icon {
            font-size: 24px;
        }
    }
</style>
