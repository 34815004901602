<template>
<v-container class="grey lighten-3" fluid>
    <v-container>
      <h2 class="v-container__main-title">Our Services</h2>
      <v-row
        align="center"
        justify="center"
      >
        <v-col class="text-center" cols="3"
            v-for="(service, i) in services"
            :key="i"
        >
            <i :class="service.icon"></i>
            <h3 class="mb-2">{{ service.title }}</h3>
        </v-col>
      </v-row>
    </v-container>
</v-container>
</template>
<script>
export default {
  name: 'HomeStaticServices',
  data () {
    return {
      services: [
        {
          icon: 'fas fa-dollar-sign',
          title: 'Project Selling',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'
        },
        {
          icon: 'fas fa-handshake',
          title: 'Brokerage',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'
        },
        {
          icon: 'fas fa-award',
          title: 'Appraisal',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'
        },
        {
          icon: 'fas fa-file-alt',
          title: 'Documentation',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'
        },
        {
          icon: 'fas fa-house-user',
          title: 'Property management',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'
        },
        {
          icon: 'fas fa-comments',
          title: 'Consultancy',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'
        },
        {
          icon: 'fas fa-tools',
          title: 'Repair',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
  .fa, .fas, .fab {
    color: $white;
    font-size: 2rem;
    width: 80px;
    height: 80px;
    background-color: $primary;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
  }
  h3 {
    font-family: 'Karla';
    font-size: 1rem;
    text-transform: uppercase;
  }
  .subheading {
    font-size: .9rem;
    padding: 0 1rem;
  }
</style>
