<template>
    <div>
        <v-container class="pb-12" fluid>
            <Breadcrumbs :items="crumbs"/>
            <v-row>
                <v-col md="8" cols="12">
                    <Gallery :images="current_listing.images"/>
                    <v-container v-model="current_listing">
                        <div class="d-flex justify-space-between">
                            <div class="listing-price d-flex align-center">
                                <h2 class="text-h4 font-weight-bold" v-html="current_listing.price"></h2>
                                <v-chip
                                    label
                                    color="blue"
                                    text-color="white"
                                    class="ml-2"
                                >
                                    {{ status }}
                                </v-chip>
                            </div>
                        </div>
                        <v-divider class="mt-3"/>
                        <h1 class="text-h5 mt-4 font-weight-bold">{{ current_listing.title }}</h1>
                        <!-- <h3 class="text-h3 mt-4 font-weight-bold">{{ current_listing.dhsudlts }}</h3> -->
                        <v-col>
                            <v-icon class="mr-1">mdi-map-marker</v-icon>
                            <span class="subheading">{{ current_listing.location }}</span>
                        </v-col>
                        <div class="subtitle-2" >
                          <v-alert
                            text
                            color="info"
                          >
                            <strong>Listing type:</strong> {{ listing_type }}
                          </v-alert>
                        </div>
                        <div class="subtitle-2" v-if="current_listing.project !== ''">
                            <div class="my-2 mx-5 listing-project">
                              <router-link :to="{name: 'projectpage', params: {'slug': current_listing.project_slug}}" class="pr-3">
                                {{ current_listing.project }}
                              </router-link>
                              <router-link :to="{name: 'developerpage', params: {'slug': current_listing.contractor_slug}}" class="pl-4">
                                {{ current_listing.contractor }}
                              </router-link>
                            </div>
                        </div>

                        <h3 class="mt-4">Overview</h3>
                        <v-col>
                            <v-row class="justify-start">
                                <v-col cols="6" sm="2" md="2" v-show="subtype !== 'Lot Only' && unitType !== 'Studio' && propertyType !== 'Industrial'">
                                    <v-icon class="mr-1" color="blue">mdi-bed-king</v-icon>
                                    <span class="subheading mr-2 text-lighten-2">{{ current_listing.bedrooms }}</span>
                                    <div>Bedroom</div>
                                </v-col>
                                <v-col cols="6" sm="2" md="2" v-if="subtype !== 'Lot Only'">
                                    <v-icon class="mr-1" color="blue">mdi-shower-head</v-icon>
                                    <span class="subheading mr-2">{{ current_listing.bathroom }}</span>
                                    <div>Bathroom</div>
                                </v-col>
                                <v-col cols="6" sm="2" md="2" v-if="subtype !== 'Condominium'">
                                    <v-icon class="mr-1" color="blue">mdi-vector-square</v-icon>
                                    <span class="subheading mr-2">{{ lotarea }} sqm</span>
                                    <div>Lot area</div>
                                </v-col>
                                <v-col cols="6" sm="2" md="2" v-if="subtype !== 'Lot Only'">
                                    <v-icon class="mr-1" color="blue">mdi-shape-square-plus</v-icon>
                                    <span class="subheading mr-2">{{ floorarea }} sqm</span>
                                    <div>Floor area</div>
                                </v-col>
                                <v-col cols="6" sm="4" md="4" v-if="(listing_type == 'Project' && propertyType == 'Residential' && subtype == 'House and Lot') || (listing_type == 'Brokerage' && propertyType == 'Residential' && (subtype == 'Condominium' || subtype == 'House and Lot'))">
                                    <v-icon class="mr-1" color="blue">mdi-car</v-icon>
                                    <span class="subheading mr-2">{{ current_listing.carpark }}</span>
                                    <div>Car park</div>
                                </v-col>
                                <v-col cols="6" sm="4" md="4" v-if="(subtype == 'Condominium' && listing_type !== 'Brokerage') && subtype !== 'House and Lot'" class="d-flex align-center">
                                    <v-icon class="mr-1" color="blue">mdi-car</v-icon>
                                    <span class="subheading mr-2" v-if="current_listing.parking">Can avail a parking space</span>
                                    <span class="subheading mr-2" v-else>Cannot avail a parking space</span>
                                </v-col>

                            </v-row>
                        </v-col>

                        <h3 class="mt-4">Property type</h3>
                        <v-col>
                          <span class="subheading mr-2 text-lighten-2">{{ current_listing.property_type }}</span>
                        </v-col>

                        <h3 class="mt-4">Property subtype</h3>
                        <v-col>
                          <span class="subheading mr-2 text-lighten-2">{{ subtype }}</span>
                        </v-col>
                        <template v-if="propertyType == 'Residential' && subtype !== 'Lot Only'">
                          <h3 class="mt-4">Property unit type</h3>
                          <v-col>
                            <span class="subheading mr-2 text-lighten-2">{{ unitType }}</span>
                          </v-col>
                        </template>

                        <template v-if="(propertyType == 'Residential' && subtype !== 'Lot Only') || propertyType !== 'Residential'">
                          <template v-if="current_listing.project_amenities && current_listing.amenities.length > 0">
                            <h3 class="mt-4">Listing amenities</h3>
                            <v-col>
                                <div class="d-flex justify-start">
                                    <v-chip v-for="(amenity, key) in current_listing.amenities" :key="`amenity-${key}`"
                                            class="mr-2">
                                        {{ amenity }}
                                    </v-chip>
                                </div>
                            </v-col>
                          </template>
                        </template>

                        <template v-if="propertyType == 'Residential' || propertyType == 'Memorial'">
                          <template v-if="current_listing.project_amenities && current_listing.project_amenities.length > 0">
                              <h3 class="mt-4">Project amenities</h3>
                              <v-col>
                                  <div class="d-flex justify-start">
                                      <v-chip v-for="(amenity, key) in current_listing.project_amenities" :key="`project-amenity-${key}`"
                                              class="mr-2">
                                          {{ amenity }}
                                      </v-chip>
                                  </div>
                              </v-col>
                            </template>
                        </template>

                        <h3 class="mt-4">Details</h3>
                        <v-col class="description">
                            <div v-html="current_listing.description"></div>
                        </v-col>
                    </v-container>
                </v-col>
                <v-col md="4" cols="12">
                    <ContactAgent :id="$route.params.slug"/>
                </v-col>
            </v-row>
        </v-container>
        <Footer />
    </div>
</template>

<script>
    import ContactAgent from '@/components/ContactAgent.vue'
    import Breadcrumbs from '@/components/Breadcrumbs.vue'
    import Gallery from '@/components/Gallery.vue'
    import Footer from '@/components/Footer.vue'
    import {mapActions} from 'vuex'
    import {ACTION} from '@/store/action-types'

    export default {
        name: 'ListingPage',
        data() {
            return {
                current_listing: {},
            }
        },
        computed: {
            crumbs() {
                return [
                    {
                        text: 'Home',
                        to: '/'
                    },
                    {
                        text: this.current_listing.title,
                        to: this.$route.fullPath
                    }
                ]
            },
            floorarea() {
              return Number(this.current_listing.floorarea).toLocaleString()
            },
            lotarea() {
              return Number(this.current_listing.lotarea).toLocaleString()
            },
            statusBefore() {
              return this.current_listing ? this.current_listing.property_status ? this.current_listing.property_status.charAt(0).toUpperCase() + this.current_listing.property_status.slice(1).toLowerCase() : '' : '';
            },
            status() {
              return this.statusBefore == 'Ready for occupancy' ? this.current_listing.property_type == 'Memorial' ? 'Ready-to-use' : this.statusBefore.replace(/\s+/g, '-') : this.statusBefore;
            },
            subtype() {
              return this.current_listing ? this.current_listing.subtype : '';
            },
            unitType() {
              return this.current_listing ? this.current_listing.property_unit_type : '';
            },
            propertyType() {
              return this.current_listing ? this.current_listing.property_type : '';
            },
            listing_type() {
              return this.current_listing ? this.current_listing.listing_type == 0 ? 'Project' : 'Brokerage' : '';
            }
        },
        created() {
            this.fetch_data(this.$route.params.slug)
                .then(res => {
                    this.current_listing = {
                        title: res.data.name,
                        contractor: res.data.developer_name,
                        contractor_slug: res.data.developer_slug,
                        project: res.data.project_name,
                        project_slug: res.data.project_slug,
                        price: res.data.price_full.replace(/\s/g, '').replace(/—/g, '&nbsp;&#8211;&nbsp;'),
                        property_type: res.data.property_type,
                        bedrooms: res.data.bedroom,
                        bathroom: res.data.bathroom,
                        lotarea: res.data.lot_area,
                        subtype: res.data.property_subtype,
                        monthly_equity: res.data.monthly_equity,
                        floorarea: res.data.floor_area,
                        location: res.data.location,
                        availability: res.data.availability,
                        amenities: res.data.amenities.map(am => am.name),
                        project_amenities: res.data.project_amenities,
                        description: res.data.details,
                        images: res.data.pictures.map(pic => pic.url),
                        parking: res.data.parking,
                        carpark: res.data.carpark,
                        property_status: res.data.property_status,
                        property_unit_type: res.data.property_unit_type,
                        listing_type: res.data.type
                    }

                    let query = {
                        tab: res.data.project_name ? 'For Sale' : res.data.property_status,
                        status: res.data.project_name ? 'For Sale' : res.data.property_status
                    }
                    this.$router.push({query : query }).catch(err => {
                        // Ignore the vuex err regarding  navigating to the page they are already on.
                        if (
                          err.name !== 'NavigationDuplicated' &&
                          !err.message.includes('Avoided redundant navigation to current location')
                        ) {
                          // But print any other errors to the console
                          logError(err);
                        }
                    });
                })
                .catch((res, err) => {
                    if (res.response.status == 404) {
                        this.$router.push({name: 'notFound'})
                    }
                })
        },
        methods: {
            ...mapActions({
                fetch_data: ACTION.GET_LISTING
            })
        },
        components: {
            ContactAgent,
            Breadcrumbs,
            Gallery,
            Footer
        }
    }
</script>
<style lang="scss" scoped>
    .description p,
    .description p ::v-deep p {
        white-space: break-spaces;
        br { display: none; }
    }
    .listing-project {
        a {
            color: #1e1e1e;
            text-decoration: none;

            &:hover {
                color: #0d47a1;
            }
            &:first-child{
              border-right: 1px solid #1e1e1e;
              padding-right: 8px;
            }
        }
    }
    .brokerage-status {
      span {
          color: #1e1e1e;
          &:first-child{
            border-right: 1px solid #1e1e1e;
            padding-right: 8px;
          }
      }
    }
    ::v-deep .v-btn--icon.v-size--default {
        height: 50px;
        width: 50px;
    }
</style>
