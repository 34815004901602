import { render, staticRenderFns } from "./ViewSalesperson.vue?vue&type=template&id=0457eac6&scoped=true&"
import script from "./ViewSalesperson.vue?vue&type=script&lang=js&"
export * from "./ViewSalesperson.vue?vue&type=script&lang=js&"
import style0 from "./ViewSalesperson.vue?vue&type=style&index=0&id=0457eac6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0457eac6",
  null
  
)

export default component.exports