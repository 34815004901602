<template>
    <v-container fluid>
        <v-container v-model="current_listing">
            <v-tabs
              color="deep-purple accent-4"
              right
            >
                <v-tab>Info</v-tab>
                <v-tab v-if="listing_type == 'Brokerage'">Documents</v-tab>
                <v-tab-item>
                    <Gallery :images="current_listing.images" />
                    <v-container>
                      <div class="d-flex justify-space-between">
                        <div class="listing-price d-flex align-center">
                          <h2 class="text-h4 font-weight-bold">{{ current_listing.title }}</h2>
                          <v-chip
                            label
                            color="blue"
                            text-color="white"
                            class="ml-6"
                          >
                            {{ status }}
                          </v-chip>
                        </div>
                      </div>
                      <v-col>
                        <v-icon class="mr-1">mdi-map-marker</v-icon><span class="subheading">{{ current_listing.location }}</span>
                      </v-col>

                      <v-divider class="mt-3"/>
                      <div class="d-flex align-center mt-4">
                        <h1  class="text-h5 font-weight-bold" v-html="price"></h1>
                        <span v-if="listing_type == 'Brokerage' && status !== 'For sale'">/month</span>
                      </div>
                      <v-alert type="info" dense text class="mt-4" :icon="false">
                        <strong>Listing type:</strong> {{ listing_type }}
                      </v-alert>
                      <div class="subtitle-2" v-if="current_listing.project !== ''">
                          <div class="my-2 mx-5 listing-project d-flex">
                            <div class="pr-3">
                              {{ current_listing.project }}
                            </div>
                            <div class="pl-4">
                              {{ current_listing.contractor }}
                            </div>
                          </div>
                      </div>

                      <h3 class="mt-4">Overview</h3>
                      <v-col>
                        <v-row class="justify-start">
                            <v-col cols="6" sm="3" md="2" v-show="subtype !== 'Lot Only' && unitType !== 'Studio' && propertyType !== 'Industrial'">
                              <v-icon class="mr-1" color="blue">mdi-bed-king</v-icon>
                              <span class="subheading mr-2 text-lighten-2">{{ current_listing.bedrooms }}</span>
                              <div>Bedrooms</div>
                            </v-col>
                            <v-col cols="6" sm="3" md="2" v-if="subtype !== 'Lot Only'">
                              <v-icon class="mr-1" color="blue">mdi-shower-head</v-icon>
                              <span class="subheading mr-2">{{ current_listing.bathroom }}</span>
                              <div>Bathroom</div>
                            </v-col>
                            <v-col cols="6" sm="3" md="2" v-if="subtype !== 'Condominium'">
                              <v-icon class="mr-1" color="blue">mdi-vector-square</v-icon>
                              <span class="subheading mr-2">{{ lotarea }}</span> sqm
                              <div>Lot area</div>
                            </v-col>
                            <v-col cols="6" sm="3" md="2" v-if="subtype !== 'Lot Only'">
                              <v-icon class="mr-1" color="blue">mdi-shape-square-plus</v-icon>
                              <span class="subheading mr-2">{{ floorarea }}</span> sqm
                              <div>Floor area</div>
                            </v-col>
                            <v-col cols="6" sm="4" md="4" v-if="(listing_type == 'Project' && propertyType == 'Residential' && subtype == 'House and Lot') || (listing_type == 'Brokerage' && propertyType == 'Residential' && (subtype == 'Condominium' || subtype == 'House and Lot'))">
                                <v-icon class="mr-1" color="blue">mdi-car</v-icon>
                                <span class="subheading mr-2">{{ current_listing.carpark }}</span>
                                <div>Car park</div>
                            </v-col>
                            <v-col cols="6" sm="4" md="4" v-if="(subtype == 'Condominium' && listing_type !== 'Brokerage') && subtype !== 'House and Lot'" class="d-flex align-center">
                                <v-icon class="mr-1" color="blue">mdi-car</v-icon>
                                <span class="subheading mr-2" v-if="current_listing.parking">Can avail a parking space</span>
                                <span class="subheading mr-2" v-else>Cannot avail a parking space</span>
                            </v-col>
                        </v-row>
                      </v-col>

                      <h3 class="mt-4">Property type</h3>
                      <v-col>
                        <span class="subheading mr-2 text-lighten-2">{{ current_listing.property_type }}</span>
                      </v-col>

                      <h3 class="mt-4">Property subtype</h3>
                      <v-col>
                        <span class="subheading mr-2 text-lighten-2">{{ subtype }}</span>
                      </v-col>
                      <template v-if="propertyType == 'Residential' && subtype !== 'Lot Only'">
                        <h3 class="mt-4">Property unit type</h3>
                        <v-col>
                          <span class="subheading mr-2 text-lighten-2">{{ unitType }}</span>
                        </v-col>
                      </template>
                      <template v-if="propertyType == 'Residential' || propertyType == 'Memorial'">
                        <template v-if="current_listing.project_amenities && current_listing.project_amenities.length > 0">
                            <h3 class="mt-4">Project amenities</h3>
                            <v-col>
                                <div class="d-flex justify-start">
                                    <v-chip v-for="(amenity, key) in current_listing.project_amenities" :key="`project-amenity-${key}`"
                                            class="mr-2">
                                        {{ amenity }}
                                    </v-chip>
                                </div>
                            </v-col>
                          </template>
                      </template>
                      <template v-if="current_listing.amenities && current_listing.amenities.length > 0">
                        <h3 class="mt-4">Amenities</h3>
                        <v-col>
                          <div class="d-flex justify-start">
                            <v-chip v-for="(amenity, key) in current_listing.amenities" :key="`amenity-${key}`"
                              class="mr-2">
                              {{ amenity }}
                            </v-chip>
                          </div>
                        </v-col>
                      </template>
                      <v-divider class="mt-3"/>
                      <v-row v-if="listing_type == 'Project'">
                        <v-col cols="12" sm="4">
                          <v-list-item-content>
                              <v-list-item-subtitle>Equity percentage</v-list-item-subtitle>
                              <v-list-item-title class="text--darken font-weight-bold">{{ project_data.equity_percentage }}&percnt;</v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-list-item-content>
                              <v-list-item-subtitle>Equity terms</v-list-item-subtitle>
                              <v-list-item-title class="text--darken font-weight-bold">{{ project_data.equity_terms }} months</v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-list-item-content>
                              <v-list-item-subtitle class="mt-4">Reservation Fee</v-list-item-subtitle>
                              <v-list-item-title class="text--darken font-weight-bold">&#8369;{{ project_data.reservation_fee }}</v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-list-item-content>
                              <v-list-item-subtitle>Total equity amount</v-list-item-subtitle>
                              <v-list-item-title class="text--darken font-weight-bold">&#8369;{{ equity }}</v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-list-item-content>
                              <v-list-item-subtitle>Monthly equity</v-list-item-subtitle>
                              <v-list-item-title class="text--darken font-weight-bold">&#8369;{{ monthly_equity }} / month</v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-list-item-content>
                              <v-list-item-subtitle>Loanable amount</v-list-item-subtitle>
                              <v-list-item-title class="text--darken font-weight-bold">&#8369;{{ loanable_amount }} </v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols="12" sm="4">
                          <v-list-item-content>
                              <v-list-item-subtitle>Tax declaration #</v-list-item-subtitle>
                              <v-list-item-title class="text--darken font-weight-bold" v-if="brokerage_data.tax_declaration_number !== ''">{{ brokerage_data.tax_declaration_number }}</v-list-item-title>
                              <v-list-item-title v-else>No tax declaration # provided.</v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-list-item-content>
                              <v-list-item-subtitle>Title #</v-list-item-subtitle>
                              <v-list-item-title class="text--darken font-weight-bold" v-if="brokerage_data.title_number !== ''">{{ brokerage_data.title_number }}</v-list-item-title>
                              <v-list-item-title v-else>No title # provided.</v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-list-item-content>
                              <v-list-item-subtitle>Terms of payment</v-list-item-subtitle>
                              <v-list-item-title class="text--darken font-weight-bold">{{ brokerage_data.terms_of_payment }}</v-list-item-title>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                      <v-divider class="mt-3"/>
                      <template v-if="listing_type == 'Brokerage'">
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-list-item-content>
                                <v-list-item-subtitle>Property owner(s)</v-list-item-subtitle>
                                <v-list-item-title class="text--darken font-weight-bold">{{ brokerage_data.owner_of_the_property }}</v-list-item-title>
                            </v-list-item-content>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-list-item-content>
                                <v-list-item-subtitle>Listing owner(s)</v-list-item-subtitle>
                                <v-list-item-title class="text--darken font-weight-bold">{{ brokerage_data.listing_owner }}</v-list-item-title>
                            </v-list-item-content>
                          </v-col>
                        </v-row>
                        <v-divider class="mt-3"/>
                      </template>

                      <template v-if="listing_type == 'Brokerage'">
                        <v-list-item-content>
                            <v-list-item-subtitle>Financing option</v-list-item-subtitle>
                            <v-col>
                              <div class="d-flex justify-start">
                                <template v-if="financingOptions && financingOptions.length > 0">
                                  <v-chip v-for="(financing, key) in financingOptions" :key="`financing-option-${key}`"
                                    class="mr-2">
                                    {{ financing.text }}
                                  </v-chip>
                                </template>
                                <template v-else> No financing options provided. </template>
                              </div>
                            </v-col>
                        </v-list-item-content>
                      </template>
                      <h3 class="mt-4">Description</h3>
                      <v-col class="description">
                        <p v-html="current_listing.description"></p>
                      </v-col>
                    </v-container>
                </v-tab-item>
                <v-tab-item v-if="listing_type == 'Brokerage'">
                    <v-row>
                        <v-col cols="12">
                            <h2>Images</h2>
                        </v-col>
                    </v-row>
                    <v-row class="image-list">
                        <v-col cols="12" sm="6" md="4">
                            <strong>Title</strong>
                            <v-img v-if="brokerage_title && brokerage_title.length > 0" :src="brokerage_title[0].image" @click="showDialog({title: 'Title', img: brokerage_title[0].image})"></v-img>
                            <div v-else class="ml-2">None uploaded.</div>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <strong>Tax Declaration</strong>
                            <v-img v-if="brokerage_tax_declaration && brokerage_tax_declaration.length > 0" :src="brokerage_tax_declaration[0].image" @click="showDialog({title: 'Tax Declaration', img: brokerage_tax_declaration[0].image})"></v-img>
                            <div v-else class="ml-2">None uploaded.</div>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <strong>Sketch Plan</strong>
                            <v-img v-if="brokerage_sketch_plan && brokerage_sketch_plan.length > 0" :src="brokerage_sketch_plan[0].image" @click="showDialog({title: 'Sketch Plan', img: brokerage_sketch_plan[0].image}) "></v-img>
                            <div v-else class="ml-2">None uploaded.</div>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <strong v-if="brokerage_data.brokerage_type == 0">Authority to Sell</strong>
                            <strong v-else>Authority to Lease</strong>
                            <v-img v-if="brokerage_authority_to_lease && brokerage_authority_to_lease.length > 0" :src="brokerage_authority_to_lease[0].image" @click="showDialog({title: 'Authority to Lease', img: brokerage_authority_to_lease[0].image})"></v-img>
                            <div v-else class="ml-2">None uploaded.</div>
                        </v-col>
                    </v-row>

                </v-tab-item>
            </v-tabs>
            <v-row>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-btn
                        color="error"
                        class="mr-3"
                        @click="cancel()"
                    >
                        Back
                    </v-btn>
                </v-card-actions>
            </v-row>
        </v-container>
        <v-dialog
        v-model="dialog"
        max-width="1080"
      >
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{ dialogTitle }}
          </v-card-title>

          <v-card-text class="mt-4">
            <v-img
                :src="dialogPicture"
                width="100%"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              @click="dialog = false"
            >
              Back
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
</template>
<script>
    import {mapActions} from "vuex"
    import CKEDITOR_CONF from '@/utils/ckeditor_config'
    import { FETCH_VIEW_LISTING, GET_ADMINLISTING, FETCH_EDIT_LISTING } from '@/store/action-types'
    import CreateListingProject from '@/views/components/CreateListingProject'
    import CreateListingBrokerage from '@/views/components/CreateListingBrokerage'
    import ListingDocuments from "./components/ListingDocuments";
    import Gallery from '@/components/Gallery.vue'


    export default {
        name: 'ListingView',
        CKEDITOR_CONF,
        components: {
            CreateListingProject,
            CreateListingBrokerage,
            ListingDocuments,
            Gallery
        },
        props: [
            'id'
        ],
        data: () => ({
            show_alert: false,
            options: {
                financing: [
                    {text: 'Bank Financing', value: 0},
                    {text: 'PAG-IBIG Financing', value: 1},
                    {text: 'In-House', value: 2},
                    {text: 'Cash', value: 3},
                ]
            },
            current_listing: [],
            project_data: {},
            brokerage_data: {},
            pictures: [],
            brokerage_title: [],
            brokerage_tax_declaration: [],
            brokerage_sketch_plan: [],
            brokerage_authority_to_lease: [],
            dialog: false,
            dialogPicture: '',
            dialogTitle: ''
        }),
        computed: {
          financingOptions() {
              return this.current_listing.listing_type == 1 ? this.options.financing.filter( (option) => { return this.brokerage_data.financing_option.includes(option.value) }) : [];
          },
          price() {
              if (this.current_listing.listing_type === 0) {
                  let min = this.project_data.minimum_price || '0'
                  let max = this.project_data.maximum_price || '0'
                  return min == max ? `₱${this.comma_sep_price(min)}` : `₱${this.comma_sep_price(min)} &#8211; ₱${this.comma_sep_price(max)}`
              } else {
                  let price = this.brokerage_data.price || '0'
                  return `₱${this.comma_sep_price(price)}`
              }
          },
          equity() {
              let result = parseFloat(this.project_data.equity_percentage) / 100.0;
              return (parseFloat(this.project_data.maximum_price) * result).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          },
          loanable_amount() {
              let max_price = parseFloat(this.project_data.maximum_price)
              let result = parseFloat(this.project_data.equity_percentage) / 100.0
              let equity = parseFloat(max_price) * result
              return (max_price - equity).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          },
          monthly_equity() {
              let no_comma = this.equity.toString().split(',').join('')
              return (parseFloat(no_comma) / parseFloat(this.project_data.equity_terms)).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
          },
          propertyType() {
            return this.current_listing ? this.current_listing.property_type : '';
          },
          floorarea() {
            return Number(this.current_listing.floorarea).toLocaleString()
          },
          lotarea() {
            return Number(this.current_listing.lotarea).toLocaleString()
          },
          statusBefore() {
            return this.current_listing ? this.current_listing.property_status ? this.current_listing.property_status.charAt(0).toUpperCase() + this.current_listing.property_status.slice(1).toLowerCase() : '' : '';
          },
          status() {
            return this.statusBefore == 'Ready for occupancy' ?
              ['memorial', 'lot only'].includes(this.subtype.toLowerCase()) ? 'Ready-to-use' : 'Ready-for-occupancy' : this.statusBefore;
          },
          subtype() {
            return this.current_listing ? this.current_listing.subtype : '';
          },
          unitType() {
            return this.current_listing ? this.current_listing.property_unit_type : '';
          },
          propertyType() {
            return this.current_listing ? this.current_listing.property_type : '';
          },
          listing_type() {
            return this.current_listing ? this.current_listing.listing_type == 0 ? 'Project' : 'Brokerage' : '';
          }
        },
        methods: {
            ...mapActions({
                fetch_view_listing: FETCH_VIEW_LISTING,
                get_listing: GET_ADMINLISTING,
                fetch_edit_listing: FETCH_EDIT_LISTING
            }),
            comma_sep_price(num) {
                return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
            },
            showDialog(item) {
              this.dialogPicture = item.img
              this.dialogTitle = item.title
              this.dialog = true
            },
            cancel() {
                this.$router.push({name: 'listings'})
            }
        },
        created() {
          this.fetch_edit_listing({id: this.id})
                .then(res => {
                    let data = res.data

                    if ('brokerage_listing' in data && data['brokerage_listing']) {
                        this.brokerage_data = Object.assign({}, data.brokerage_listing, {'project': data.project})
                        this.brokerage_title = data.brokerage_listing['title']
                        this.brokerage_tax_declaration = data.brokerage_listing['tax_declaration']
                        this.brokerage_sketch_plan = data.brokerage_listing['sketch_plan']
                        this.brokerage_authority_to_lease = data.brokerage_listing['authority_to_lease']
                    }
                    if ('project_listing' in data && data['project_listing']) {
                        this.project_data = Object.assign({}, data.project_listing, {'project': data.project})
                    }
                })

            this.fetch_view_listing({id: this.id})
                .then(res => {
                    this.current_listing = {

                      title: res.data.name,
                      contractor: res.data.developer_name,
                      contractor_slug: res.data.developer_slug,
                      project: res.data.project_name,
                      project_slug: res.data.project_slug,
                      price: res.data.price_full.replace(/\s/g, '').replace(/—/g, '&nbsp;&#8211;&nbsp;'),
                      property_type: res.data.property_type,
                      bedrooms: res.data.bedroom,
                      bathroom: res.data.bathroom,
                      lotarea: res.data.lot_area,
                      subtype: res.data.property_subtype,
                      monthly_equity: res.data.monthly_equity,
                      floorarea: res.data.floor_area,
                      location: res.data.location,
                      availability: res.data.availability,
                      amenities: res.data.amenities.map(am => am.name),
                      project_amenities: res.data.project_amenities,
                      description: res.data.details,
                      images: res.data.pictures.map(pic => pic.url),
                      parking: res.data.parking,
                      carpark: res.data.carpark,
                      property_status: res.data.property_status,
                      property_unit_type: res.data.property_unit_type,
                      listing_type: res.data.type

                    }
                })
        },
    }
</script>
<style scoped lang="scss">
.v-list-item__subtitle,
.v-list-item__title{
  overflow: visible;
  white-space: normal;
  text-overflow: unset;
}
.alert-box {
    position: fixed;
    right: 5%;
    bottom: 20px;
    z-index: 9;
}
.image-list ::v-deep .v-image {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity:.6;
  }
}
.listing-project {
    div, a {
        color: #1e1e1e;
        text-decoration: none;

        &:hover {
            color: #0d47a1;
        }
        &:first-child{
          border-right: 1px solid #1e1e1e;
          padding-right: 8px;
        }
    }
}
::v-deep .v-btn--icon.v-size--default {
    height: 50px;
    width: 50px;
}
</style>
