<template>
    <v-navigation-drawer
        v-model="drawer"
        :color="color"
        :expand-on-hover="expandOnHover"
        :mini-variant="miniVariant"
        :right="right"
        :permanent="permanent"
        dark
        class="sidebar-container"
    >
      <v-layout justify-space-between column fill-height>
        <v-list
            dense
            nav
            class="py-0"
            >
            <v-list-item two-line :class="miniVariant && 'px-0'">
                <v-list-item-avatar color="primary">
                    <span class="white--text headline"> {{ nameInitial(accountName) }}</span>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title>{{ accountName }}</v-list-item-title>
                <v-list-item-subtitle>Admin</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <router-link
                v-for="(item, index) in items"
                :value="item.active"
                :key="`menu-item${index}`"
                :to="{ name: item.path }"
                :class="{'router-link-exact-active router-link-active' : (isListing && item.path == 'listings') }"
            >
                <v-list-item v-if="!item.submenus" link>
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group
                    v-else
                    :key="item.title"
                    no-action
                    :value="true"
                >
                    <template v-slot:activator class="leads-item">
                        <v-list-item class="px-0">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                     </template>
                     <router-link
                         v-for="(submenu, menuindex) in item.submenus"
                         :value="submenu.active || submenu.path == currentLeadView"
                         :key="`menu-item${menuindex}`"
                         :to="{ name: submenu.path }"
                        :class="{'router-link-exact-active router-link-active' : (isLeads && submenu.path == currentLeadView) }"
                     >
                         <v-list-item link>
                             <v-list-item-content>
                                 <v-list-item-title>{{ submenu.title }}</v-list-item-title>
                             </v-list-item-content>
                         </v-list-item>
                    </router-link>
                </v-list-group>
            </router-link>
        </v-list>
        <v-btn
          @click="logoutAccount"
           justify-end
           rounded
           color="primary mb-8 mx-8"
           >Logout</v-btn>
      </v-layout>
    </v-navigation-drawer>
</template>
<script>
export default {
  name: 'Sidebar',
  data () {
    return {
      drawer: true,
      items: [
        // { title: 'Dashboard', icon: 'mdi-view-dashboard', path: 'admin-dashboard' },
        { title: 'Developers', icon: 'mdi-domain', path: 'developers' },
        { title: 'Projects', icon: 'mdi-home-group', path: 'projects' },
        { title: 'Listings', icon: 'mdi-map-marker', path: 'listings' },
        {
            title: 'Leads',
            icon: 'mdi-graph',
            path: 'leads',
            active: true,
            submenus: [
                { title: 'Listing Page', path: 'listing-leads' },
                { title: 'Contact Us Page', path: 'contact-leads' },
                { title: 'Sell/Lease', path: 'sell-leads' },
                { title: 'Appraisal', path: 'appraisal-leads' },
                { title: 'Add a Lead', path: 'add-leads' }
            ]
        },
        { title: 'Broker', icon: 'mdi-account', path: 'broker' },
        { title: 'Salespersons', icon: 'mdi-account', path: 'salesperson' },
        { title: 'Realty', icon: 'mdi-home', path: 'realty' },
      ],
      color: 'gray',
      colors: [
        'primary',
        'blue',
        'success',
        'red',
        'teal'
      ],
      right: false,
      permanent: true,
      miniVariant: false,
      expandOnHover: false,
      background: false
    }
  },
    computed: {
        accountName() { return this.$store.getters.user },
        isListing() {
          return (this.$route.name == 'addlisting' || this.$route.name == 'viewlisting' || this.$route.name == 'editlisting');
        },
        isLeads() {
            return this.$route.meta ?  this.$route.meta.leads ? true : false : false;
        },
        currentLeadView() {
            return this.$store.getters.leadpage;
        }
    },
    methods: {
        logoutAccount: function () {
            this.$store.dispatch('LOGOUT')
        },
        nameInitial(name) {
            return name ? name.charAt(0) : '';
        }
    }
}
</script>
<style lang="scss" scoped>
.sidebar-container {
    width: 100%!important;
    a {
      width: 100%;
      text-decoration: none;
      &.router-link-active .v-list-item:before,
      &.router-link-active ::v-deep .v-list-item--link:before{
        opacity:.08 !important;
      }
    }
    ::v-deep .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
        display: none;
    }
    ::v-deep .v-list-group__items {
        position: relative;
        margin-left: 40px;
        &::before {
            content: "";
            width: 2px;
            height: calc(100% - 25px);
            position: absolute;
            left: 0;
            top: 0;
            background-color: #FFF;
        }
        .v-list-item.v-list-item {
            padding-left: 32px;
            &::after {
                content: "";
                width: 25px;
                height: 2px;
                min-height: auto;
                position: absolute;
                left: 0;
                top: calc(50% - 1px);
                background-color: #FFF;
                opacity: 1!important;
            }
            &::before{
                opacity: 0!important;
            }
        }
        .router-link-exact-active.router-link-active .v-list-item{
            &::before{
                left: 25px;
                opacity: .08!important;
            }
        }
    }
    ::v-deep .v-list-group.v-list-group--no-action .v-list-item.v-list-group__header.v-list-item{
        pointer-events: none;
    }
    ::v-deep .v-list-group.v-list-group--no-action .v-list-group__items .v-list-item.v-list-group__header.v-list-item{
        pointer-events: all;
    }
}
.v-list--dense .v-list-item {
  height: 50px;
}
.v-list--dense .v-list-item .v-list-item__icon, .v-list-item--dense .v-list-item__icon {
  margin-top: 12px;
  margin-right: 12px;
}
.v-list--dense .v-list-item .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list-item--dense .v-list-item__title {
  font-size: inherit;
  line-height: inherit;
}
</style>
