<template>
    <v-row>
           <v-btn
            color="primary"
            dark
            x-large
            @click="dialog=true"
        >
            Add
        </v-btn>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-title>
                    <span class="headline my-4">Add developer</span>
                </v-card-title>
                <v-form ref="form">
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    label="Developer Name*"
                                    v-model="data.name"
                                    outlined
                                    :rules="[v => !!v || 'Field is required']"
                                    required
                                    class="mb-0"
                                    :error-messages="!errors.name ? [] : ['Name Already Exists']"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    label="Office address"
                                    v-model="data.office_address"
                                    :rules="[v => !!v || 'Field is required']"
                                    outlined
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="6"
                            >
                                <v-text-field
                                    label="Contact person*"
                                    v-model="data.contact_person"
                                    :rules="[v => !!v || 'Field is required']"
                                    outlined
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="6"
                            >
                                <v-text-field
                                    label="Contact number*"
                                    v-model="data.contact_number"
                                    :rules="[v => !!v || 'Field is required']"
                                    outlined
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-textarea
                                    label="Details"
                                    v-model="data.details"
                                    outlined
                                ></v-textarea>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-file-input
                                    color="blue accent-4"
                                    counter
                                    label="Logo"
                                    placeholder="Add logo(.jpeg, .jpg, .png)"
                                    prepend-icon="mdi-image"
                                    outlined
                                    :show-size="1000"
                                    @change="onFileChange"
                                >
                                    <template v-slot:selection="{ index, text }">
                                        <v-chip
                                            v-if="index < 2"
                                            color="deep-purple accent-4"
                                            dark
                                            label
                                            small
                                        >
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeModal"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="addDeveloper"
                        :loading="loader"
                        :disabled="loader"
                    >

                        Save
                        <template v-slot:loader>
                            <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                            </span>
                      </template>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        name: 'AddDeveloperModal',
        props: ['errors', 'visible'],
        data: () => ({
            data: {
                name: "",
                contact_number: "",
                contact_person: "",
                office_address: "",
                details: "",
                pictures: "",
            },
            loader: false
        }),
        mounted() {
            this.clearData();
        },
        computed: {
            dialog: {
                get() {
                    return this.visible
                },
                set(value) {
                    return this.$emit('trigger', value)
                }
            }
        },
        watch: {
            dialog(newVal, odlVal) {
                this.$nextTick()
                    .then(_ => {
                        this.clearData()
                        this.$refs.form.reset()
                    })
            },
            errors: {
                deep: true,
                handler(newVal, oldVal) {
                    this.onError()
                }
            }
        },
        methods: {
            addDeveloper() {
                this.loader = true
                let isValid = this.$refs.form.validate()
                if (!isValid) {
                    this.loader = false
                    return
                }

                let formData = new FormData();

                formData.append("name", this.data.name);
                formData.append("contact_number", this.data.contact_number);
                formData.append("contact_person", this.data.contact_person);
                formData.append("office_address", this.data.office_address);
                formData.append("details", this.data.details);
                if (this.data.pictures) {
                    formData.append("pictures", this.data.pictures);
                }
                this.$emit('createDeveloper', {formData, data: this.data})
            },
            closeModal() {
                return this.$emit('trigger', false)
            },
            clearData() {
                this.data.name = "";
                this.data.office_address = "";
                this.data.details = "";
                this.data.pictures = "";
                this.loader = false
            },
            onFileChange(files) {
                this.data.pictures = files;
            },
            onError() {
                this.loader = false
            }
        }
    }
</script>
<style scoped>
    .col {
        padding-bottom: 0;
        padding-top: 0;
    }
</style>
