import Vue from 'vue'
import Router from 'vue-router'
import  Cookies from 'js-cookie';
//import localforage from 'localforage'

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import ContactUs from '../views/ContactUs.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ListingPage from '../views/ListingPage.vue'
import DeveloperPage from '../views/DeveloperPage.vue'
import ProjectPage from '../views/ProjectPage.vue'
import Dashboard from '../views/Dashboard.vue'
import Developers from '../views/Developers.vue'
import Projects from '../views/Projects.vue'
import Listings from '../views/Listings.vue'
import SalesPerson from '../views/SalesPerson.vue'
import SearchResultsPage from '../views/SearchResultsPage.vue'
import Page404 from '../views/404.vue'
// import AddListing from '../views/AddListing.vue'
import ListingCreate from '../views/ListingCreate.vue'
import EditListing from '../views/ListingEdit.vue'
import ViewListing from '../views/ListingView.vue'
import Secured from '../views/SecuredPage.vue'
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  //base: process.env.BASE_URL,
    routes: [
        {
            path: '*',
            name: 'notFound',
            component: Page404
        },
        {
          path: '/',
          name: 'home',
          component: Dashboard
        },
    {
        path: '/listing/:slug/',
        name: 'listingpage',
        component: ListingPage,
        props: true,
        meta: {
            breadcrumbs: [
                {
                    text: 'Home',
                    disabled: false,
                    name: 'home'
                },
                {
                    text: ':title',
                    disabled: true
                }
            ]
        }
    },
    {
        path: '/developer/:slug/',
        name: 'developerpage',
        component: DeveloperPage,
        props: true,
        meta: {
            breadcrumbs: [
                {
                    text: 'Home',
                    disabled: false,
                    name: 'home'
                },
                {
                    text: ':title',
                    disabled: true
                }
            ]
        }
    },
    {
        path: '/project/:slug/',
        name: 'projectpage',
        component: ProjectPage,
        props: true,
        meta: {
            breadcrumbs: [
                {
                    text: 'Home',
                    disabled: false,
                    name: 'home'
                },
                {
                    text: ':title',
                    disabled: true
                }
            ]
        }
  	},
      {
          path: '/search',
          name: 'search',
          component: SearchResultsPage
  	},
      {
        path: '/about',
        name: 'aboutus',
        component: About
  	},
    {
      path: '/contactus',
      name: 'contactus',
      component: ContactUs
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/secured',
      name: 'secured',
      component: Secured,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true,
        page: 'dashboard'
      },
      children: [
        {
          path: 'developers',
          name: 'developers',
          component: Developers
        },
        {
          path: 'projects',
          name: 'projects',
          component: Projects
        },
        {
          path: 'listings',
          name: 'listings',
          component: Listings
        },
        {
          path: 'addlisting',
          name: 'addlisting',
          component: ListingCreate
        },
        {
          path: 'editlisting/:id',
          name: 'editlisting',
          component: EditListing,
          props: true,
        },
        {
          path: 'viewlisting/:id',
          name: 'viewlisting',
          component: ViewListing,
          props: true,
        },
        {
          path: 'leads',
          name: 'leads',
          component: () => import('@/views/Leads.vue'),
          redirect: { name: 'listing-leads' },
          children: [
                {
                    path: 'view/:id',
                    name: 'lead-view',
                    component: () => import('@/views/LeadsView.vue'),
                    meta: {
                        leads: true
                    }
                },
                {
                    path: 'listing',
                    name: 'listing-leads',
                    component: () => import('@/views/LeadsListing.vue'),
                    meta: {
                        leads: true
                    }
                },
                {
                    path: 'contact',
                    name: 'contact-leads',
                    component: () => import('@/views/LeadsContact.vue'),
                    meta: {
                        leads: true
                    }
                },
                {
                    path: 'sell',
                    name: 'sell-leads',
                    component: () => import('@/views/LeadsSell.vue'),
                    meta: {
                        leads: true
                    }
                },
                {
                    path: 'appraisal',
                    name: 'appraisal-leads',
                    component: () => import('@/views/LeadsAppraise.vue'),
                    meta: {
                        leads: true
                    }
                },
                {
                    path: 'add',
                    name: 'add-leads',
                    component: () => import('@/views/LeadsAdd.vue'),
                    meta: {
                        leads: true
                    }
                }
            ]
        },
        {
          path: 'salesperson',
          name: 'salesperson',
          component: SalesPerson
        },
        {
          path: 'broker',
          name: 'broker',
          component: () => import('@/views/Broker.vue'),
        },
        {
          path: 'realty',
          name: 'realty',
          component: () => import('@/views/Realty.vue'),
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (Cookies.get('login-token')) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
