<template>
    <v-container fluid>
        <h1>Add Listings</h1>
        <v-divider class="my-4"></v-divider>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    :items="options.listing_types"
                                    v-model="data.listing_type"
                                    label="Listing types"
                                    outlined
                                    required
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Listing name*"
                                    v-model="data.name"
                                    :rules="[v => !!v || 'Field is required']"
                                    outlined
                                    required
                                    class="mb-0"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Location*"
                                    v-model="data.location"
                                    :rules="[v => !!v || 'Field is required']"
                                    outlined
                                    :readonly="readonly_location"
                                    required
                                    class="mb-0"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3" v-if="propertySubtype !== 2 && propertySubtype !== -1">
                                <v-text-field
                                    label="Lot area*"
                                    v-model="lot_area"
                                    outlined
                                    required
                                    class="mb-0"
                                />
                            </v-col>
                            <v-col cols="3" v-if="propertySubtype !== 0 && propertySubtype !== -1">
                                <v-text-field
                                    label="Floor area*"
                                    v-model="floor_area"
                                    outlined
                                    required
                                    :readonly="property_unit_type_disabled"
                                    :disabled="property_unit_type_disabled"
                                    class="mb-0"
                                />
                            </v-col>
                            <v-col cols="3" v-if="propertySubtype !== 3 && propertySubtype !== -1 && propertySubtype !== 0 && !hideBedroom">
                                <v-text-field
                                    label="Bedroom"
                                    v-model="data.bedroom"
                                    outlined
                                    required
                                    :readonly="property_unit_type_disabled || disableData"
                                    :disabled="property_unit_type_disabled || disableData"
                                    class="mb-0"
                                    @keyup="numOnly($event, 'data.bedroom')"
                                />
                            </v-col>
                            <v-col cols="3" v-if="propertySubtype !== 3 && propertySubtype !== 0">
                                <v-text-field
                                    label="Bathroom"
                                    v-model="data.bathroom"
                                    outlined
                                    required
                                    :readonly="property_unit_type_disabled"
                                    :disabled="property_unit_type_disabled"
                                    class="mb-0"
                                    @keyup="numOnly($event, 'data.bathroom')"
                                />
                            </v-col>
                            <v-col cols="4" v-if="(listingType == 1 && propertySubtype !== 0 && propertySubtype !== 3) || (listingType == 0 && propertySubtype == 1)">
                                <v-text-field
                                    label="Car park"
                                    v-model="data.carpark"
                                    outlined
                                    required
                                    :readonly="property_unit_type_disabled"
                                    :disabled="property_unit_type_disabled"
                                    class="mb-0"
                                    @keyup="numOnly($event, 'carpark')"
                                />
                            </v-col>
                            <v-col cols="6" v-if="listingType == 0 && propertySubtype == 2">
                                <v-checkbox
                                    v-model="data.parking"
                                    label="Can avail parking space"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="6">
                                <v-checkbox
                                    v-model="data.featured"
                                    label="Featured listing"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    v-model="data.amenities"
                                    :items="options.amenities"
                                    label="Amenities"
                                    multiple
                                    persistent-hint
                                    small-chips
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    :items="availability"
                                    v-model="data.availability"
                                    label="Availability*"
                                    outlined
                                    required
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    :items="brokers"
                                    item-value="id"
                                    item-text="name"
                                    v-model="data.broker"
                                    label="Broker*"
                                    outlined
                                    required
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <CreateListingProject
                            v-if="data.listing_type == 0"
                            :options="options"
                            @update="project_component_listener"
                        />
                        <CreateListingBrokerage
                            v-else
                            :options="options"
                            @update="brokerage_component_listener"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="headline mt-4 mb-2 font-weight-bold">Details</div>
                        <ckeditor v-model="data.details" @ready="logEvent" @focus="logEvent" @blur="logEvent" @input="logEvent" :config="this.$options.CKEDITOR_CONF" class="mb-4"/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                        <v-btn
                            color="error"
                            class="mr-3"
                            @click="cancel()"
                        >
                            Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            @click="submit()"
                        >
                            Create
                        </v-btn>
                    </v-card-actions>

                </v-row>
            </v-form>
        </v-container>
    </v-container>
</template>
<script>
    import {mapActions} from "vuex"
    import CKEDITOR_CONF from '@/utils/ckeditor_config'
    import {FETCH_CREATE_DATA, CREATE_PROJECT_LISTING,
            CREATE_BROKERAGE_LISTING, FETCH_BROKERS} from '@/store/action-types'
    import CreateListingProject from '@/views/components/CreateListingProject'
    import CreateListingBrokerage from '@/views/components/CreateListingBrokerage'
    import { eventBus } from '@/services/eventBus';

    export default {
        CKEDITOR_CONF,
        components: {
            CreateListingProject,
            CreateListingBrokerage
        },
        data: () => ({
            disableData: false,
            hideBedroom: false,
            options: {
                listing_types: [
                    {text: 'Project', value: 0},
                    {text: 'Brokerage', value: 1}
                ],
                amenities: [],
                projects: [],
                project_unit_types: {},
            },
            data: {
                name: '',
                featured: false,
                listing_type: 0,
                location: '',
                lot_area: 0,
                floor_area: 0,
                bedroom: 0,
                bathroom: 0,
                carpark: 0,
                parking: false,
                details: '',
                amenities: [],
                availability: 0,
                reservation_fee: 0,
                broker: null
            },
            project_data: {},
            brokerage_data: {},
            imageFiles: [],
            images: [],
            invalidImages: [],
            project_list: [],
            brokers: []
        }),
        computed: {
            availability() {
                if (this.data.listing_type == 0) {
                    return [
                        {text: 'Available', value: 0},
                        {text: 'Sold-out', value: 1}
                    ]
                }
                return [
                    {text: 'Available', value: 0},
                    {text: 'Sold', value: 1}
                ]
            },
            readonly_location() {
                return this.data.listing_type === 0 || !!this.brokerage_data.project
            },
            reservation_fee_display: {
                get() {
                    return this.data.reservation_fee ? this.data.reservation_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('')
                    this.$set(this.data, 'reservation_fee', no_comma)
                }
            },
            property_unit_type_disabled() {
                if (Object.keys(this.project_data).length > 0 && this.project_data.project) {
                    let is_true = this.options.project_unit_types[this.project_data.project].length === 0
                    if (is_true) {
                        this.data = Object.assign({}, this.data, {
                            floor_area: 0,
                            bedroom: 0,
                            bathroom: 0,
                            carpark: 0,
                        })
                    }
                    return is_true
                }
                if (Object.keys(this.brokerage_data).length > 0) {
                    let is_true = this.brokerage_data.property_subtype === 0
                    if (is_true) {
                        this.data = Object.assign({}, this.data, {
                            floor_area: 0,
                            bedroom: 0,
                            bathroom: 0,
                            carpark: 0,
                        })
                    }
                    return is_true
                }
                return false
            },
            lot_area: {
                get() {
                    return this.data.lot_area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('')
                    this.$set(this.data, 'lot_area', no_comma)
                }
            },
            floor_area: {
                get() {
                    return this.data.floor_area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('')
                    this.$set(this.data, 'floor_area', no_comma)
                }
            },
            listingType() {
              return this.data.listing_type == 0 ? 0 : 1;
            },
            propertySubtype() {
              if(this.data.listing_type == 0) {
                 let projectData = this.project_list.find(p => p.id == this.project_data.project)
                 return projectData ? projectData.property_subtype : -1;
              } else {
                return this.brokerage_data.property_subtype
              }
            },
            propertyUnittype() {
              if(this.data.listing_type == 0) {
                 return this.project_data.property_unit_type;
              } else {
                return this.brokerage_data.property_unit_type
              }
            },
            withProject() {
              return this.listingType == 1 ? this.brokerage_data.project ? true : false : true;
            }
        },
        methods: {
            ...mapActions({
                get_amenities: FETCH_CREATE_DATA,
                create_project_listing: CREATE_PROJECT_LISTING,
                create_brokerage_listing: CREATE_BROKERAGE_LISTING,
                get_brokers: FETCH_BROKERS
            }),
            logEvent() {
              let source = document.querySelector('.cke_source');
              if(source) {
                this.$set(this.data, 'details', source.value)
              }
      			},
            project_component_listener(data) {
                this.project_data = data
                let {project} = data
                if (project) {
                    let {location} = this.project_list.find(p => p.id == project)
                    if (location) {
                        this.$set(this.data, 'location', location)
                    } else {
                        this.$set(this.data, 'location', "The project doesn't have location")
                    }
                }
            },
            brokerage_component_listener(data) {
                this.brokerage_data = data
                let {project} = data
                if (project) {
                    let {location} = this.project_list.find(p => p.id == project)
                    if (location) {
                        this.$set(this.data, 'location', location)
                    } else {
                        this.$set(this.data, 'location', "The project doesn't have location")
                    }
                }
            },
            submit() {
                let isvalid = this.$refs.form.validate()
                if (!isvalid) {
                    return
                }
                let res;
                let source = document.querySelector('.cke_source');
                if(source) {
                  this.$set(this.data, 'details', source.value)
                }
                if (this.data.listing_type == 0) {
                    let final_data = {...this.data, ...this.project_data}
                    console.log("final_data project", final_data)
                    res = this.create_project_listing(final_data)
                } else {
                    let final_data = {...this.data, ...this.brokerage_data}
                    console.log("final_data brokerage", final_data)
                    res = this.create_brokerage_listing(final_data)
                }
                res.then(({data}) => {
                    console.log("data", data)
                    this.$router.push({'name': 'editlisting', params: {id: data.id}})
                })
            },
            cancel() {
                this.$router.push({name: 'listings'})
            },
            numOnly(event, value) {
              this[value] = event.target.value.replace(/[^\d]/g,'');
              event.target.value = this[value];
            }
        },
        created() {
            this.get_amenities()
                .then(res => {
                    this.project_list = res.data.projects
                    this.$set(this.options, 'projects', res.data.projects.map(item => ({
                        text: item.name,
                        value: item.id,
                        property_type: item.property_type,
                        property_subtype: item.property_subtype
                    })))
                    this.$set(this.options, 'project_unit_types', res.data.projects.reduce((accu, item) => {
                        accu[item.id] = item.property_unity_type.map(o => ({
                            text: o.name,
                            value: o.id,
                        }))
                        return accu
                    }, {}))
                    this.$set(this.options, 'amenities', res.data.amenities.map(item => ({
                        text: item.name,
                        value: item.id,
                    })))
                });
            this.get_brokers()
                .then((res) => {
                    this.brokers = res.data
                    //sir Rodel's account
                    this.data.broker = 4;
                })
        },
        mounted() {
          eventBus.$on('handlePropertyUnitTypeChange', (data) => {
            this.disableData = false;
            this.hideBedroom = false;
            this.$set(this.data, 'bedroom', 0)
            this.$set(this.data, 'floor_area', 0)
            this.$set(this.data, 'bathroom', 0)
            switch (data.data) {
              case 'One-bedroom':
                this.$set(this.data, 'bedroom', 1)
                this.disableData = true;
                break;
              case 'Two-bedroom':
                this.$set(this.data, 'bedroom', 2)
                this.disableData = true;
                break;
              case 'Three-bedroom':
                this.$set(this.data, 'bedroom', 3)
                this.disableData = true;
                break;
              case 'Lot Only':
                this.$set(this.data, 'bedroom', 0)
                this.$set(this.data, 'floor_area', 0)
                this.$set(this.data, 'bathroom', 0)
                break;
              case 'Studio':
                this.$set(this.data, 'bedroom', 0)
                this.disableData = true;
                this.hideBedroom = true;
                break;
            }
      		})
          eventBus.$on('handleProjectChange', (data) => {
            switch (data.data) {
              case 'removed':
                this.$set(this.data, 'location', '')
                break;
            }
          })
      }
    }
</script>
