<template>
    <v-footer
        dark
        padless
    >
        <v-container
          flat
          class="text-center d-flex justify-space-between py-5"
        >
          <div>
            <router-link :to="{ name: 'aboutus' }" class="mr-5">About us</router-link>
            <router-link :to="{ name: 'contactus' }">Contact us</router-link>
          </div>
          <div>
              &#xA9; 2017–2021 YDH Realty Services, Inc. 
          </div>
        </v-container>

    </v-footer>
</template>
<script>

export default {
  name: 'Footer',
  data () {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
    .v-footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        @media only screen and (max-width: 600px) {
            padding: 0 1rem;
        }
    }
  .v-footer a {
    color: $white;
    text-decoration: none;
  }
</style>
