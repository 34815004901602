<template>
    <div class="home">
        <Menu />
        <v-container fluid class="text-center d-flex align-center justify-center flex-column">
            <img
                :src="require(`@/assets/pagenotfound.png`)"
                alt="404 - Page not Found"
            >
            <h2 class="text-h3 my-4"><kbd>Page not Found</kbd></h2>
            <p class="text-h5">This link is broken or this page has been removed.</p>
            <p class="text-h5">Not to worry. You can visit <router-link :to="{ name: 'home' }" class="text-decoration-none ">{{ home }}</router-link> or use the menu above to find your way.</p>
        </v-container>
        <Footer/>
    </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import { mapActions } from 'vuex'
import { ACTION } from '@/store/action-types'
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  data () {
    return {
        home: ''
    }
  },
  components: {
    Menu,
    Footer
  },
  created () {
      this.home = window.location.origin;
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/styles.scss';

.container {
    padding: 4rem 0;
    height: calc(100vh - 127px);
}

::v-deep {
    .container {
        padding: 4rem 0;
    }
    .text-h1 {
        font-size: 15rem!important;
        line-height: 15rem;
    }
}
</style>
