// auth.js
export const SET_USER = 'SET_USER'
export const REMOVE_USER = 'REMOVE_USER'

// listings
export const SET_LISTINGS = 'SET_LISTINGS'
export const ADDLISTING = 'ADD_LISTINGS'

//developers
export const SET_DEVELOPERS = 'SET_DEVELOPERS'
export const ADDDEVELOPER = 'ADD_DEVELOPER'

export const MUTATION = {
    SET_USER,
    REMOVE_USER,
    SET_LISTINGS,
    SET_DEVELOPERS,
    ADDDEVELOPER,
    ADDLISTING
}
