<template>
    <v-card class="pa-4 contact-agent-form">
        <h2 class="text-h4 font-weight-bold mb-2 mt-4">Message us</h2>
        <div class="pre-text mb-2">
          <small>Feel free to send us a message with all your property concerns, questions, and feedback. Please fill out the information below.</small>
        </div>
        <v-form v-model="valid">

            <v-text-field
                type="text"
                label="Full name"
                v-model="data.fullname"
                :rules="rules"
                required
                outlined
            ></v-text-field>

        <v-text-field
            type="email"
            label="Email address"
            placeholder=""
            v-model="data.email"
            outlined
            :rules="emailRules"
        ></v-text-field>

        <v-text-field
            type="phone"
            label="Phone number"
            v-model="data.phone"
            outlined
            :rules="rules"
        ></v-text-field>
        <v-textarea
            label="Message"
            v-model="data.message"
            outlined
            :rules="rules"
            rows="4"
            row-height="60"
        ></v-textarea>
        <v-btn
          @click="messageUs"
          :disabled="!valid"
            rounded
            x-large
            block
            color="primary"
            class="mt-4"
            >Submit</v-btn>
      </v-form>
    </v-card>
</template>

<script>
export default {
  name: 'ContactAgengForm',
  props: {

  },
  data () {
    return {
      valid: false,
      data: {
        fullname: '',
        email: '',
        phone: '',
        message: ''
      },
      value: '',
      rules: [
        value => !!value || 'Required.',
      ],
      emailRules: [
        value => !!value || 'E-mail is required',
        value => /.+@.+/.test(value) || 'E-mail must be valid',
      ],
    }
  },
  methods: {
    messageUs () {

    }
  }
}
</script>
<style scoped lang="scss">
.pre-text {
  line-height: 1;
}
</style>
