<template>
    <v-container fluid>
        <v-alert
          border="left"
          outlined
          dense
          dismissible
          type="success"
          :value="show_alert"
          class="alert-box"
        >
            Saved!
        </v-alert>
        <v-container>
            <v-tabs
              color="deep-purple accent-4"
              right
            >
                <v-tab>Info</v-tab>
                <v-tab>Documents</v-tab>
                <v-tab-item>
                    <v-col cols="12">
                        <div class="headline mb-4 font-weight-bold">{{listing_type}}</div>
                    </v-col>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="6">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Listing name*"
                                            v-model="data.name"
                                            :rules="[v => !!v || 'Field is required']"
                                            outlined
                                            required
                                            class="mb-0"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Location*"
                                            v-model="data.location"
                                            :rules="[v => !!v || 'Field is required']"
                                            outlined
                                            required
                                            :readonly="readonly_location"
                                            class="mb-0"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="3" v-if="propertySubtype !== 2 && propertySubtype !== -1">
                                        <v-text-field
                                            label="Lot area*"
                                            v-model="lot_area"
                                            outlined
                                            required
                                            class="mb-0"
                                        />
                                    </v-col>
                                    <v-col cols="3" v-if="propertySubtype !== 0 && propertySubtype !== -1">
                                        <v-text-field
                                            label="Floor area*"
                                            v-model="floor_area"
                                            outlined
                                            required
                                            :readonly="property_unit_type_disabled"
                                            :disabled="property_unit_type_disabled"
                                            class="mb-0"
                                        />
                                    </v-col>
                                    <v-col cols="3" v-if="propertySubtype !== 3 && propertySubtype !== -1 && propertySubtype !== 0 && !hideBedroom">
                                        <v-text-field
                                            label="Bedroom"
                                            v-model="data.bedroom"
                                            outlined
                                            required
                                            :readonly="property_unit_type_disabled || disableData"
                                            :disabled="property_unit_type_disabled || disableData"
                                            class="mb-0"
                                            @keyup="numOnly($event, 'data.bedroom')"
                                        />
                                    </v-col>
                                    <v-col cols="3" v-if="propertySubtype !== 3 && propertySubtype !== 0">
                                        <v-text-field
                                            label="Bathroom"
                                            v-model="data.bathroom"
                                            outlined
                                            required
                                            :readonly="property_unit_type_disabled"
                                            :disabled="property_unit_type_disabled"
                                            class="mb-0"
                                            @keyup="numOnly($event, 'data.bathroom')"
                                        />
                                    </v-col>
                                    <v-col cols="4" v-if="(listingType == 1 && propertySubtype !== 0 && propertySubtype !== 3) || (listingType == 0 && propertySubtype == 1)">
                                        <v-text-field
                                            label="Car park"
                                            v-model="data.carpark"
                                            outlined
                                            :readonly="property_unit_type_disabled"
                                            :disabled="property_unit_type_disabled"
                                            required
                                            class="mb-0"
                                            @keyup="numOnly($event, 'data.carpark')"
                                        />
                                    </v-col>
                                    <v-col cols="6" v-if="listingType == 0 && propertySubtype == 2">
                                        <v-checkbox
                                            v-model="data.parking"
                                            label="Can avail parking space"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-checkbox
                                            v-model="data.featured"
                                            label="Featured listing"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-select
                                            v-model="data.amenities"
                                            :items="options.amenities"
                                            label="Amenities"
                                            multiple
                                            persistent-hint
                                            small-chips
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            :items="availability"
                                            v-model="data.availability"
                                            label="Availability*"
                                            outlined
                                            required
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select
                                            :items="brokers"
                                            item-value="id"
                                            item-text="name"
                                            v-model="data.broker"
                                            label="Broker*"
                                            outlined
                                            required
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6">
                                <CreateListingProject
                                    v-if="data.listing_type == 0"
                                    :options="options"
                                    :initial_data="project_data"
                                    @update="project_component_listener"
                                />
                                <CreateListingBrokerage
                                    v-if="data.listing_type == 1"
                                    :options="options"
                                    :initial_data="brokerage_data"
                                    @update="brokerage_component_listener"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <div class="headline mt-4 mb-2 font-weight-bold">Details</div>
                                <ckeditor v-model="data.details" @ready="logEvent" @focus="logEvent" @blur="logEvent" @input="logEvent" :config="this.$options.CKEDITOR_CONF" class="mb-4"/>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-tab-item>
                <v-tab-item>
                    <v-row>
                        <v-col cols="12">
                            <h2>Images</h2>
                        </v-col>
                    </v-row>
                    <ListingDocuments
                        :listing_id="this.id"
                        :initial_data="pictures"
                        :upload_fn="upload_picture"
                        :delete_fn="delete_picture"
                        title="Pictures"
                    />
                    <template v-if="data.listing_type == 1">
                        <v-row class="my-5">
                            <v-col cols="12"><v-divider></v-divider></v-col>
                            <v-col cols="12">
                                <h2>Brokerage Documents</h2>
                            </v-col>
                        </v-row>
                        <ListingDocuments
                            :listing_id="this.id"
                            :initial_data="brokerage_title"
                            :upload_fn="upload_title"
                            :delete_fn="delete_picture"
                            title="Brokerage Title"
                        />
                        <ListingDocuments
                            :listing_id="this.id"
                            :initial_data="brokerage_tax_declaration"
                            :upload_fn="upload_tax_declaration"
                            :delete_fn="delete_picture"
                            title="Brokerage Tax Declaration"
                        />
                        <ListingDocuments
                            :listing_id="this.id"
                            :initial_data="brokerage_sketch_plan"
                            :upload_fn="upload_sketch_plan"
                            :delete_fn="delete_picture"
                            title="Sketch Plan"
                        />
                        <ListingDocuments
                            :listing_id="this.id"
                            :initial_data="brokerage_authority_to_lease"
                            :upload_fn="upload_authority_to_lease"
                            :delete_fn="delete_picture"
                            :title="this.brokerage_data.listing_status == 0 ? 'Authorithy to Sell' : 'Authority to Lease'"
                        />
                    </template>

                </v-tab-item>
            </v-tabs>
            <v-row>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-btn
                        color="error"
                        class="mr-3"
                        @click="cancel()"
                    >
                        Back
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="submit()"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-row>
        </v-container>
    </v-container>
</template>
<script>
    import {mapActions} from "vuex"
    import CKEDITOR_CONF from '@/utils/ckeditor_config'
    import { FETCH_CREATE_DATA, FETCH_EDIT_LISTING,
        UPDATE_LISTING, UPLOAD_IMAGE_LISTING, FETCH_BROKERS } from '@/store/action-types'
    import CreateListingProject from '@/views/components/CreateListingProject'
    import CreateListingBrokerage from '@/views/components/CreateListingBrokerage'
    import ListingDocuments from "./components/ListingDocuments";
    import { eventBus } from '@/services/eventBus';

    export default {
        CKEDITOR_CONF,
        components: {
            CreateListingProject,
            CreateListingBrokerage,
            ListingDocuments
        },
        props: [
            'id'
        ],
        data: () => ({
            disableData: false,
            hideBedroom: false,
            show_alert: false,
            options: {
                listing_types: [
                    {text: 'Project', value: 0},
                    {text: 'Brokerage', value: 1}
                ],
                amenities: [],
                projects: [],
                project_unit_types: {},
                availability: [
                    {text: 'Available', value: 0},
                    {text: 'Sold', value: 1}
                ],
            },
            data: {
                name: null,
                listing_type: null,
                location: null,
                lot_area: null,
                floor_area: 0,
                bedroom: 0,
                bathroom: 0,
                carpark: 0,
                parking: false,
                featured: false,
                details: '',
                amenities: [],
                availability: 0,
                reservation_fee: '',
                broker: null
            },
            project_data: {},
            brokerage_data: {},
            pictures: [],
            brokerage_title: [],
            brokerage_tax_declaration: [],
            brokerage_sketch_plan: [],
            brokerage_authority_to_lease: [],

            imageFiles: [],
            images: [],
            invalidImages: [],
            project_list: [],
            brokers: []
        }),
        computed: {
            availability() {
                if (this.data.listing_type == 0) {
                    return [
                        {text: 'Available', value: 0},
                        {text: 'Sold-out', value: 1}
                    ]
                }
                return [
                    {text: 'Available', value: 0},
                    {text: 'Sold', value: 1}
                ]
            },
            readonly_location() {
                return this.data.listing_type === 0 || !!this.brokerage_data.project
            },
            reservation_fee_display: {
                get() {
                    return this.data.reservation_fee ? this.data.reservation_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('')
                    this.$set(this.data, 'reservation_fee', no_comma)
                }
            },
            property_unit_type_disabled() {
                if (Object.keys(this.project_data).length > 0 && this.project_data.project) {
                    let is_true = this.options.project_unit_types[this.project_data.project] && this.options.project_unit_types[this.project_data.project].length === 0
                    if (is_true) {
                        this.data = Object.assign({}, this.data, {
                            floor_area: 0,
                            bedroom: 0,
                            bathroom: 0,
                            carpark: 0,
                        })
                    }
                    return is_true
                }
                if (Object.keys(this.brokerage_data).length > 0) {
                    let is_true = this.brokerage_data.property_subtype === 0
                    if (is_true) {
                        this.data = Object.assign({}, this.data, {
                            floor_area: 0,
                            bedroom: 0,
                            bathroom: 0,
                            carpark: 0,
                        })
                    }
                    return is_true
                }
                return false
            },
            listing_type() {
                if (this.data.listing_type == null) {
                    return ''
                }
                if (this.data.listing_type == 0) {
                    return 'Project'
                }
                return 'Brokerage'
            },
            lot_area: {
                get() {
                    return this.data.lot_area ? this.data.lot_area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('')
                    this.$set(this.data, 'lot_area', no_comma)
                }
            },
            floor_area: {
                get() {
                    return this.data.floor_area ? this.data.floor_area.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('')
                    this.$set(this.data, 'floor_area', no_comma)
                }
            },
            listingType() {
              return this.data.listing_type == 0 ? 0 : 1;
            },
            propertySubtype() {
              if(this.data.listing_type == 0) {
                 let projectData = this.project_list.find(p => p.id == this.project_data.project)
                 return projectData ? projectData.property_subtype : -1;
              } else {
                return this.brokerage_data.property_subtype
              }
            },
            propertyUnittype() {
              if(this.data.listing_type == 0) {
                 return this.project_data.property_unit_type;
              } else {
                return this.brokerage_data.property_unit_type
              }
            },
        },
        methods: {
            ...mapActions({
                get_amenities: FETCH_CREATE_DATA,
                fetch_edit_listing: FETCH_EDIT_LISTING,
                update_listing: UPDATE_LISTING,
                upload_picture: UPLOAD_IMAGE_LISTING,
                get_brokers: FETCH_BROKERS
            }),
            ...mapActions(['delete_picture', 'upload_brokerage_docs']),
            logEvent() {
              let source = document.querySelector('.cke_source');
              if(source) {
                this.$set(this.data, 'details', source.value)
              }
      			},
            upload_title(data){
                data['field'] = 'title'
                return this.upload_brokerage_docs(data)
            },
            upload_tax_declaration(data){
                data['field'] = 'tax_declaration'
                return this.upload_brokerage_docs(data)
            },
            upload_sketch_plan(data){
                data['field'] = 'sketch_plan'
                return this.upload_brokerage_docs(data)
            },
            upload_authority_to_lease(data){
                data['field'] = 'authority_to_lease'
                return this.upload_brokerage_docs(data)
            },
            project_component_listener(data) {
                this.project_data = data
                let { project } = data
                if (project && this.project_list.length) {
                    let projectObject = this.project_list.find(p => p.id == project)
                    let location = projectObject ? projectObject.location : '';
                    if (location) {
                        this.$set(this.data, 'location', location)
                    } else {
                        this.$set(this.data, 'location', "The project doesn't have location")
                    }
                }
            },
            brokerage_component_listener(data) {
                this.brokerage_data = data
                let {project} = data
                if (project) {
                    setTimeout(() => {
                        let {location} = this.project_list.find(p => p.id == project)

                        if (location) {
                            this.$set(this.data, 'location', location)
                        } else {
                            this.$set(this.data, 'location', "The project doesn't have location")
                        }
                    }, 0)
                }
            },
            submit() {
                let source = document.querySelector('.cke_source');
                if(source) {
                  this.$set(this.data, 'details', source.value)
                }

                if (this.data.listing_type == 0) {
                    let final_data = {...this.data, ...this.project_data}
                    this.update_listing({id: this.id, payload: final_data})
                        .then(_ => {
                            this.show_alert = true
                            setTimeout(() => {
                                this.show_alert = false
                            }, 1500)
                        })
                    this.$forceUpdate();
                } else {
                    let final_data = {...this.data, ...this.brokerage_data}
                    this.update_listing({id: this.id, payload: final_data})
                        .then(_ => {
                            this.show_alert = true
                            setTimeout(() => {
                                this.show_alert = false
                            }, 1500)
                        })
                    this.$forceUpdate();
                }
            },
            cancel() {
                this.$router.push({name: 'listings'})
            },
            numOnly(event, value) {
              this[value] = event.target.value.replace(/[^\d]/g,'');
              event.target.value = this[value];
            }
        },
        created() {
            this.get_amenities()
                .then(res => {
                    this.project_list = res.data.projects
                     this.$set(this.options, 'projects', res.data.projects.map(item => ({
                         text: item.name,
                         value: item.id,
                         property_type: item.property_type,
                         property_subtype: item.property_subtype
                    })))
                    this.$set(this.options, 'project_unit_types', res.data.projects.reduce((accu, item) => {
                        accu[item.id] = item.property_unity_type.map(o => ({
                            text: o.name,
                            value: o.id,
                        }))
                        return accu
                    }, {}))
                    this.$set(this.options, 'amenities', res.data.amenities.map(item => ({
                        text: item.name,
                        value: item.id,
                    })))
                });
            this.fetch_edit_listing({id: this.id})
                .then(res => {
                    let data = res.data
                    
                    this.data = {
                        name: data.name,
                        listing_type: data.type,
                        location: data.location,
                        lot_area: data.lot_area,
                        featured: data.featured,
                        floor_area: data.floor_area,
                        bedroom: data.bedroom,
                        bathroom: data.bathroom,
                        carpark: data.carpark || 0,
                        parking: data.parking,
                        project: data.project,
                        details: data.details,
                        amenities: data.amenities.map(item => item.id),
                        availability: data.availability,
                        reservation_fee: data.reservation_fee,
                        broker: data.broker_id ? data.broker_id : null
                    }
                    this.pictures = data.pictures
                    if ('brokerage_listing' in data && data['brokerage_listing']) {
                        this.brokerage_data = Object.assign({}, data.brokerage_listing, {'project': data.project})
                        this.brokerage_title = data.brokerage_listing['title']
                        this.brokerage_tax_declaration = data.brokerage_listing['tax_declaration']
                        this.brokerage_sketch_plan = data.brokerage_listing['sketch_plan']
                        this.brokerage_authority_to_lease = data.brokerage_listing['authority_to_lease']
                    }
                    if ('project_listing' in data && data['project_listing']) {
                        this.project_data = Object.assign({}, data.project_listing, {'project': data.project})
                        this.$set(this.data, 'reservation_fee', data.project_listing.reservation_fee)
                    }
                })
                this.get_brokers()
                .then((res) => {
                    this.brokers = res.data
                })
        },
        mounted() {
          eventBus.$on('handlePropertyUnitTypeChange', (data) => {
            this.disableData = false;
            this.hideBedroom = false;
            switch (data.data) {
              case 'One-bedroom':
                this.$set(this.data, 'bedroom', 1)
                this.disableData = true;
                break;
              case 'Two-bedroom':
                this.$set(this.data, 'bedroom', 2)
                this.disableData = true;
                break;
              case 'Three-bedroom':
                this.$set(this.data, 'bedroom', 3)
                this.disableData = true;
                break;
              case 'Lot Only':
                this.$set(this.data, 'bedroom', 0)
                this.$set(this.data, 'floor_area', 0)
                this.$set(this.data, 'bathroom', 0)
                break;
              case 'Studio':
                this.$set(this.data, 'bedroom', 0)
                this.disableData = true;
                this.hideBedroom = true;
                break;
            }
      		})
          eventBus.$on('handleProjectChange', (data) => {
            switch (data.data) {
              case 'removed':
                this.$set(this.data, 'location', '')
                break;
            }
          })
      }
    }
</script>
<style scoped>
.alert-box {
    position: absolute;
    left: 5%;
    bottom: 20px;
    z-index: 9;
}
.container {
  position: relative;
}
</style>
