import axios from '@/services/axios-auth';
import API from '@/store/api-routes';
import Cookie from "js-cookie";
const { MUTATION } = require('../mutation-types');
const { ACTION } = require('../action-types');


const state = {
    developers: []
};

const getters = {
    developers: (state) => {
        return state.developers;
    }
};

const actions = {
    [ACTION.FETCH_DEVELOPER]({ commit }, slug) {
        return axios.get(`${API.FETCH_DEVELOPER}${slug}`)
    },
    [ACTION.GET_DEVELOPERS]({ commit }, q) {
        return axios.get(API.FETCH_ADMINDEVELOPERS)
    },
    [ACTION.GET_DEVELOPER]({ commit }, slug) {
        return axios.get(`${API.FETCH_DEVELOPERS}${slug}`)
    },

    //admin
    [ACTION.GET_ADEVELOPERS]({ commit }, data) {
        return axios.get(API.ADMINDEVELOPERS)
    },
    [ACTION.GET_ADEVELOPER]({ commit }, data) {
        return axios.get(`${API.ADMINLISTING}${data.id}`)
    },
    [ACTION.CREATE_DEVELOPER]({ commit }, data) {
        return axios.post(API.ADMINDEVELOPER, data, res => {
          successCb(res);
        }, error => {
          errorCb(error);
        });
    },
    [ACTION.UPDATE_DEVELOPER]({commit}, data) {
        return axios.patch(`${API.ADMINDEVELOPER}${data.id}/`, data.formData, res => {
          successCb(res);
        }, error => {
          errorCb(error);
        });
    },
    [ACTION.DELETE_DEVELOPER]({commit}, data) {
        return axios.delete(`${API.ADMINDEVELOPER}${data}`, res => {
          successCb(res);
        }, error => {
          errorCb(error);
        });
    },
};

const mutations = {
    [ MUTATION.SET_DEVELOPERS ]( state, data ) {
        state.developers = data
    },
    [ MUTATION.ADD_DEVELOPER ]( state, data ) {
        state.developers.push(data);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
  }
