<template>
    <v-container fluid class="mx-auto pt-0 search-container">
        <v-card flat light>
            <v-card-text>
                <v-row class="search-form">
                    <v-col
                        class="py-0 text-field"
                        cols="12"
                        sm="5"
                    >
                        <v-text-field
                            solo
                            v-model="search"
                            label="Enter a location, project, or developer"
                            @keydown.enter="searchListing"
                            outlined
                        ></v-text-field>
                        <v-icon v-if="search !== ''" @click="clearTextField">mdi-close</v-icon>
                    </v-col>
                    <v-col
                        class="pa-0 px-2"
                        cols="6"
                        sm="3"
                    >
                        <v-select
                            @change="searchListing"
                            v-model="property_type"
                            :items="propertyType"
                            label="Property Type"
                            solo
                        ></v-select>
                    </v-col>
                    <v-col
                        class="pa-0 px-2"
                        cols="6"
                        sm="2"
                    >
                        <v-select
                            @change="searchListing"
                            :items="pricesList"
                            v-model="price"
                            label="Price"
                            solo
                        ></v-select>
                    </v-col>
                    <v-col
                        class="pa-0 px-2"
                        cols="12"
                        sm="2"
                    >
                        <v-btn
                            color="primary"
                            class="white--text"
                            @click="searchListing"
                        >
                            Search
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
    export default {
        name: 'SearchComponent',
        props: {
            home: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                search: '',
                items: [
                    {tab: 'Buy', content: 'Buy'},
                    {tab: 'Rent', content: 'Rent'}
                ],
                listingType: ['For Rent', 'For Sale'],
                property_type: 'Any',
                propertyType: ['Any', 'House', 'Land', 'Condominium', 'Commercial'],
                pricesList: ['Any price', 'Below 2M', '2M - 4M', '4M - 8M', '8M - 12M', '12M - 20M', '20M above'],
                bedroomsList: ['All', '1', '2', '3', '4', '5+'],
                bedrooms: '',
                price: 'Any price',
            }
        },
        computed: {
            listing_type() {
                return this.$route.query ? this.$route.query.status : '';
            },
            tab() {
                return this.$route.query.status
            }
        },
        created() {
            this.search = this.$route.query.search
            this.property_type = this.$route.query.type ? this.$route.query.type : 'Any'
        },
        methods: {
            searchListing() {
                let query = {
                    search: this.search,
                    type: this.property_type,
                    tab: this.tab,
                    price: this.price,
                    status: this.tab
                }
                this.$emit('search', query)
            },
            clearTextField() {
                this.search = '';
            }
        },
    }
</script>
<style lang="scss" scoped>
    .search-container {
        ::v-deep {

            .v-tabs-bar {
                background-color: transparent;
            }

            .v-card__text {
                padding: 1rem 1rem .5rem 1.5rem;
                @media only screen and (max-width: 600px) {
                    padding: 0;
                }
            }

            .v-tab {
                //background: rgba(255,255,255,.1);
                background-color: transparent;
                //border-radius: 4px 4px 0 0;
            }

            .v-tab.v-tab--active {
                //background: rgba(255,255,255,.2);
            }

            .v-tabs-slider-wrapper {
                bottom: 10px;
            }

            .v-tabs-slider {
                background-color: $primary;
            }

            .v-card {
                background: rgba(255, 255, 255, .2);
                @media only screen and (max-width: 600px) {
                    padding: 0;
                    margin: 0 !important;
                    width: 100%;
                }
            }

            .theme--light.v-tabs-items {
                background-color: transparent;
            }

            .v-btn {
                background-color: $primary;
                min-height: 60px;
            }

            .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
                min-height: 60px;
            }

            .v-text-field__details {
                display: none;
            }

            .text-field {
                position: relative;

                .v-icon {
                    position: absolute;
                    top: 12px;
                    right: 23px;
                    font-size: 2.2rem;
                    width: auto;
                }
            }

            a, button {
                width: 100%;
            }

            @media only screen and (max-width: 600px) {
                // .col-sm-2.col-2 {
                //     max-width: 50%;
                //     flex: 0 0 50%;
                //     a, button { width: 100%; }
                // }
                // .col-sm-6.col-6 {
                //     flex: 0 0 100%;
                //     max-width: 100%;
                //     padding-right: 0;
                // }
            }
        }

    }
</style>
