import axios from '@/services/axios-auth';

const state = {
    broker: [],
    brokerpage: ''
};

const getters = {
    broker: (state) => {
        return state.broker;
    },
    brokerpage: (state) => {
        return state.brokerpage;
    },
};

const actions = {
    addBroker({commit}, data) {
        data.image = null;
        return axios.post('/admin/broker/', data)
        .then(response => response)
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
    updateBroker({commit}, data) {
        return axios.patch(`/admin/broker/${data.id}/`, data.formData)
        .then(response => response)
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
    getBrokers({ commit }, listParams) {
        return axios.get(`/admin/broker/?${new URLSearchParams(listParams).toString()}`)
    },
    getBroker({ commit }, listParams) {
        return axios.get(`/admin/broker/${listParams.id}/`)
    },
    deleteBroker({commit}, data) {
        console.log('getBroker', data)
        return axios.delete(`/admin/broker/${data.id}`)
        .then(response => response)
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
    deleteLicense({commit}, id) {
        return axios.delete(`/admin/broker/license/${id}`)
        .then(response => response)
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
};

const mutations = {
    setBroker(state, broker) {
        state.broker = broker
    },
    setBrokerpage(state, brokerpage) {
        console.log('lead', brokerpage)
        state.brokerpage = brokerpage
    }
};

export default {
    state,
    getters,
    actions,
    mutations
  }
