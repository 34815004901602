<template>
    <div>
        <v-file-input
          color="blue accent-4"
          counter
          :label="label"
          placeholder="Add image(.jpeg, .jpg, .png)"
          prepend-icon="mdi-image"
          outlined
          :show-size="1000"
          @change="onFileChange"
        >
          <template v-slot:selection="{ index, text }">
            <v-chip
              v-if="index < 2"
              color="deep-purple accent-4"
              dark
              label
              small
            >
              {{ text }}
            </v-chip>

            <span
              v-else-if="index === 2"
              class="overline grey--text text--darken-3 mx-2"
            >
              +{{ imageFiles.length - 2 }} File(s)
            </span>
          </template>
        </v-file-input>
        <template v-if="invalidImage">
          <v-alert type="error">
            Invalid image format: <span class="font-weight-bold">{{ imageFile.name }}</span>
          </v-alert>
      </template>
    </div>
</template>
<script>
  export default {
    name: 'SingleFileUpload',
    data: () => ({
      imageFile: '',
      image: '',
      invalidImage: false,
    }),
    props: {
        label: String,
        data: String
    },
    methods: {
      onFileChange(files) {
          if (!files) {
            return;
          }

        this.imageFile = files;
        this.image = ''; //default to empty
        this.invalidImage = false; //default to empty

        if (!this.imageFile.type.match(/.(jpg|jpeg|png)$/i)) {
          this.invalidImage = true;
        } else {
          const reader = new FileReader()

          reader.onload = (e) => {
            this.$emit('sendImageFile', this.data, reader.result, this.imageFile)
          }

          reader.readAsDataURL(this.imageFile)
        }
    },
  }
}
</script>
<style scoped>

</style>
