<template>
    <v-parallax
        dark
        height="100%"
        homecover
        :src="require('@/assets/cover-bg.jpg')"
    >
        <v-row
            align="center"
            justify="center"
        >
            <v-col class="text-center" cols="12">
                <h2 class="subheading">Let us help you find your dream property.</h2>
                <Search @search="search"/>
            </v-col>
        </v-row>
    </v-parallax>
</template>
<script>
import Search from '@/components/Search.vue'

export default {
  name: 'HomeStaticCover',
  components: {
    Search
  },
  data () {
    return {}
  },
  methods: {
    search (query) {
      this.$emit('search', query)
    }
  }
}
</script>
<style lang="scss" scoped>
    .v-parallax {
        height: 500px;

        h2 {
            line-height: 1;
            @media screen and (max-width: 860px) {
                //height: 400px;
            }
        }

        .subheading {
            font-size: 3rem;
            font-weight: bold;

        }
        @media screen and (max-width: 860px) {
            height: 400px;
        }
    }

    ::v-deep .v-parallax__content {
        background: rgba(0, 0, 0, .6);
    }
    ::v-deep .v-parallax__image {
      //transform: none !important;
      top: -40%;
      width: 100% !important;
      @media screen and (max-width: 1060px) {
          top: -20%;
      }
      @media screen and (max-width: 800px) {
          top: -40%;
          width: 200% !important;
      }
      @media screen and (max-width: 450px) {
          top: -20%;
      }
    }
</style>
