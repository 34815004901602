<template>
  <div>
    <v-container class="pb-12" fluid>
        <Breadcrumbs :items="crumbs"/>
        <v-container v-model="current_developer">
            <v-row>
                <v-col cols="12" sm="3">
                    <v-img :src="current_developer.image"></v-img>
                </v-col>
                <v-col cols="12" sm="9">
                    <div class="page-title d-flex align-center">
                        <h1 class="text-h4 font-weight-bold mr-2">{{ current_developer.title }}</h1>
                    </div>
                    <v-col>
                        <v-icon class="mr-1">mdi-map-marker</v-icon>
                        <span class="subheading">{{ current_developer.location }}</span>
                    </v-col>
                    <h3 class="mt-2 ml-4">Contact Details</h3>
                    <div class="ml-4">
                        {{ current_developer.contact_person }} : {{ current_developer.contact_number }}
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mt-3"/>
            <h3 class="mt-4">Description</h3>
            <v-col class="description">
                <p v-html="current_developer.description"></p>
            </v-col>
            <h2 class="text-h5 font-weight-bold">Projects</h2>
            <v-row class="justify-space-between">
                <v-sheet
                    class="mx-auto homepage-listing"
                    width="100%"
                >
                    <v-slide-group
                        class="py-4 px-0"
                        show-arrows
                    >
                        <v-slide-item
                            v-for="(item, id) in projectListings"
                            :key="id"
                            v-slot="{ active, toggle }"
                        >
                            <Project :item="item" :key="`project-list-key-${id}`"/>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </v-row>
        </v-container>

    </v-container>
    <Footer />
  </div>
</template>

<script>
    import ContactAgent from '@/components/ContactAgent.vue'
    import Breadcrumbs from '@/components/Breadcrumbs.vue'
    import Gallery from '@/components/Gallery.vue'
    import Project from '@/components/card/Project.vue'
    import Footer from '@/components/Footer.vue'
    import {mapActions} from 'vuex'
    import {ACTION} from '@/store/action-types'

    export default {
        name: 'DeveloperPage',
        data() {
            return {
                current_developer: {},
                colors: [
                    'indigo',
                    'warning',
                    'pink darken-2',
                    'red lighten-1',
                    'deep-purple accent-4'
                ],
                slides: [
                    'First',
                    'Second',
                    'Third',
                    'Fourth',
                    'Fifth'
                ],
                projectListings: []
            }
        },
        computed: {
            crumbs() {
                return [
                    {
                        text: 'Home',
                        to: '/'
                    },
                    {
                        text: this.current_developer.name,
                        to: this.$route.fullPath
                    }
                ]
            }
        },
        created() {
            this.fetch_data(this.$route.params.slug)
                .then(res => {
                    this.current_developer = {
                      title: res.data.name,
                      contact_number: res.data.contact_number,
                      contact_person: res.data.contact_person,
                      location: res.data.office_address,
                      description: res.data.details,
                      image: res.data.pictures
                    }
                    this.projectListings = res.data.projects.map(item => ({
                        name: item.name,
                        location: item.location,
                        images: item.pictures,
                        slug: item.slug,
                        details: item.details
                    }))
                })
        },
        methods: {
            ...mapActions({
                fetch_data: ACTION.FETCH_DEVELOPER
            })
        },
        components: {
            ContactAgent,
            Breadcrumbs,
            Gallery,
            Project,
            Footer
        }
    }
</script>
<style lang="scss" scoped>
    .page-title {
        @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start !important;
            h1, div {
                width: 100%;
            }
        }
    }

    .description p,
    .description p p {
        white-space: break-spaces;
    }

    .description p ::v-deep p {
        white-space: break-spaces;
    }

    ::v-deep .project-list {
        width: 45%;
    }
</style>
