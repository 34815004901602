<template>
    <div class="search-page">
        <Menu/>
        <v-container class="px-0">
            <Search @search="search"/>
            <v-row class="justify-space-between search-filter-container">
                <v-col cols="12" sm="9" class="mx-auto"><h2 v-if="searchText !== ''">Search results for: <span>"{{ searchText }}"</span>
                </h2></v-col>
                <v-col cols="12" sm="3" class="mx-auto">
                    <v-select
                        v-model="sort"
                        :items="sortItems"
                        label="Select an option"
                        item-text="text"
                        item-value="value"
                        class="filter-dropdown ml-auto"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row class="justify-start" v-if="listing.length > 0">
                <template v-for="(item, id) in listing">
                    <List :item="item" :key="`list-key-${id}`"/>
                </template>
            </v-row>
            <v-container v-else>
              <div class="text-center">No listings found.</div>
            </v-container>
        </v-container>
        <Footer/>
    </div>
</template>

<script>
    import Menu from '@/components/Menu.vue'
    import Search from '@/components/SearchPageComponent.vue'
    import List from '@/components/Listing.vue'
    import Developer from '@/components/card/Developer.vue'
    import Project from '@/components/card/Project.vue'
    import Footer from '@/components/Footer.vue'
    import {mapActions} from 'vuex'
    import {ACTION} from '@/store/action-types'
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'

    export default {
        name: 'SearchPage',
        data() {
            return {
                listing: [],
                data: '',
                sort: '',
                sortItems: [
                    {value: 0, text: 'Most Recent'},
                    {value: 1, text: 'Price low to high'},
                    {value: 2, text: 'Price high to low'}
                ],
                searchText: this.$route.query.search,
                currentQuery: {}
            }
        },
        components: {
            Menu,
            Search,
            List,
            Developer,
            Project,
            Footer
        },
        created() {
            this.get_listing({
                    q: this.$route.query.search, status: this.$route.query.status, type: this.$route.query.type
                }
            )
            .then(res => {
                this.listing = this.toData(res.data)
            })
        },
        mounted() {
          //window.addEventListener('scroll', this.handleScroll());
        },
        methods: {
            ...mapActions({
                get_listing: ACTION.GET_LISTINGS
            }),
            search(query) {
                this.data = query.search;
                this.searchText = query.search;
                this.currentQuery = query;
                this.get_listing(
                    {q: query.search, status: query.tab, type: query.type, price: query.price}
                )
                .then(res => {
                    this.listing = this.toData(res.data)
                })
            },
            toData(data) {
                return data.results.map(item => ({
                    id: item.id,
                    title: item.name,
                    developer: item.developer_name,
                    project: item.project_name,
                    price_sort: item.price_sort,
                    developer_slug: item.developer_slug,
                    project_slug: item.project_slug,
                    price: item.price,
                    type: item.property_type,
                    bedrooms: item.bedroom,
                    monthly_equity: item.monthly_equity,
                    bathroom: item.bathroom,
                    parking: item.parking, // not needed
                    subtype: item.property_subtype,
                    location: item.location,
                    floorarea: `${item.floor_area}`,
                    status: item.property_status,
                    image: item.image,
                    slug: item.slug,
                    lotarea: item.lot_area,
                    unit_type: item.property_unit_type
                }))
            },
            handleScroll () {
              let query = this.currentQuery;
              window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

                if (bottomOfWindow) {
                  this.get_listing(
                      {q: query.search ? query.search : '', status: query.tab ? query.tab : '', type: query.type ? query.type : '', price: query.price ? query.price : ''}
                  )
                  .then(res => {
                      let listing = this.toData(res.data)
                      this.listing.push(...listing)
                  })
                }
              };
            },
        },
        watch: {
            sort(newVal) {
                if (newVal == 0) {
                    this.listing = this.listing.sort((a, b) => b.id - a.id)
                }
                if (newVal == 1) {
                    this.listing = this.listing.sort((a, b) => a.price_sort - b.price_sort)
                }
                if (newVal == 2) {
                    this.listing = this.listing.sort((a, b) => b.price_sort - a.price_sort)
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/styles/styles.scss';

    .container {
        padding: 4rem 0;
    }

    .search-page {
        min-height: calc(100vh - 130px);

        .search-filter-container {
            .col-2, .col {
                @media screen and (max-width: 600px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    .filter-dropdown {
                        max-width: 95%;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    ::v-deep {
        .v-slide-group__next, .v-slide-group__prev {
            @media screen and (max-width: 600px) {
                flex: 0 1 35px;
                min-width: 35px;
            }
        }

        .search-results-container {
            .v-card__title {
                @media only screen and (max-width: 600px) {
                    font-size: 1rem !important;
                    padding: 0 !important;
                }
            }
        }

        .container {
            padding: 4rem 0;
            @media screen and (max-width: 600px) {
                padding: 1rem 0;
                padding: 1rem 10px !important;
            }
        }

        .v-card {
            width: 95%;
            @media screen and (max-width: 600px) {
                //max-width: 320px;
                margin: 10px !important;
            }

            &.card-section {
                box-shadow: none;
            }
        }

        .v-card .col {
            padding: 0;
        }

        .v-card__subtitle {
            color: $primary !important;
            font-size: 1rem;
            font-weight: bold;
            font-family: 'Karla';
            text-transform: uppercase;
            border-bottom: 1px solid $primary;
            padding: 1rem 3rem 1rem 2rem;
        }

        .v-card__text {
            padding: 2rem;
        }

        .v-card__text .text--primary,
        .v-container__main-title {
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: normal;
            margin-bottom: 2rem;
        }

        .v-container__main-title {
            text-align: center;
            margin-bottom: 2rem;
        }

        .filter-dropdown {
            //max-width: 200px;
        }
    }
</style>
