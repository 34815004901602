<template>
<v-container>
    <v-carousel
        height="400"
        :hide-delimiters="true"
        show-arrows-on-hover
        prev-icon="fas fa-arrow-alt-circle-left"
        next-icon="fas fa-arrow-alt-circle-right"
        >
            <v-carousel-item
                v-for="(slide, i) in slides"
                :key="i"
            >
                <v-card
                    class="mx-auto featured-item card-section"
                    light
                >
                    <v-row>
                        <v-col cols="5">
                            <v-img :src="slide.img" />
                        </v-col>
                        <v-col cols="6">
                            <v-card-subtitle>{{ slide.pretext }}</v-card-subtitle>

                            <v-card-text>
                                <h2 class="text--primary">{{ slide.title }}</h2>
                                <div>{{ slide.description }}</div>
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
            </v-carousel-item>
        </v-carousel>
    </v-container>
</template>
<script>
export default {
  name: 'FeaturedProperties',
  data () {
    return {
      slides: [
        {
          pretext: 'Discover Alexandra Property',
          title: 'Alexandra Property',
          img: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          pretext: 'Discover Harlene Property',
          title: 'Harlene Property',
          img: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          pretext: 'Discover Ambrosia Property',
          title: 'Ambrosia Property',
          img: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
    @import url("https://fonts.googleapis.com/css?family=Karla");
    .featured-item {
        box-shadow: none;
        font-family: 'Karla';
    }
    ::v-deep {
        .v-window__next, .v-window__prev{
            background: transparent;
        }
        .v-btn__content .v-icon {
            color: $primary;
        }
    }
</style>
