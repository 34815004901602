<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          rounded
          v-bind="attrs"
          v-on="on"
          class="ml-2"
        >
          View
        </v-btn>
      </template>
      <v-card>
            <v-btn
                icon
                dark
                class="dialog-btn-close"
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
            </v-btn>
          <Gallery :images="pictures" />
        <v-card-title>
          <h1 class="headline font-weight-bold">{{ item.name }}</h1>
        </v-card-title>
        <v-card-text>
            <v-list-item class="ml-0 pl-0">
              <v-icon class="mr-1">mdi-map-marker</v-icon><span class="subheading">{{ item.location }}</span>
            </v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle>Developer</v-list-item-subtitle>
                <v-list-item-title class="text--darken font-weight-bold">{{ item.developer.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
                <v-list-item-subtitle>DHSUD LTS</v-list-item-subtitle>
                <v-list-item-title v-if="item.dhsud_lts" class="text--darken font-weight-bold">{{ item.dhsud_lts }}</v-list-item-title>
                <v-list-item-title v-else class="text--darken font-weight-bold">N/A</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
                <v-list-item-subtitle>Property type</v-list-item-subtitle>
                <v-list-item-title class="text--darken font-weight-bold">{{ item.property_type_name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
                <v-list-item-subtitle>Property subtype</v-list-item-subtitle>
                <v-list-item-title class="text--darken font-weight-bold">{{ propertySubTypeName }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-if="propertySubTypeName !== 'Lot Only'">
                <v-list-item-subtitle>Unit types</v-list-item-subtitle>
                <v-list-item-title class="text--darken font-weight-bold">
                    <template v-if="units.length > 0">
                      <span v-for="(unit, key) in units" :key="`unit-${key}`"
                        class="mr-2">
                        {{ unit }}
                      </span>
                    </template>
                    <template v-else> No unit types provided. </template>
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
                <v-list-item-subtitle>Financing Option</v-list-item-subtitle>
                <v-col>
                  <div class="d-flex justify-start flex-wrap">
                    <template v-if="financingList.length > 0">
                      <v-chip v-for="(financing, key) in financingList" :key="`financing-option-${key}`"
                        class="mr-2 mb-2">
                        {{ financing.text }}
                      </v-chip>
                    </template>
                    <template v-else> No financing options provided. </template>
                  </div>
                </v-col>
            </v-list-item-content>
            <v-list-item-content>
                <v-list-item-subtitle class="mt-4">Amenities</v-list-item-subtitle>
                <v-col>
                  <div class="d-flex justify-start flex-wrap">
                    <template v-if="amenityList.length > 0">
                      <v-chip v-for="(amenity, key) in amenityList" :key="`amenity-${key}`"
                        class="mr-2 mb-2">
                        {{ amenity.text }}
                      </v-chip>
                    </template>
                    <template v-else> No amenities provided. </template>
                  </div>
                </v-col>
            </v-list-item-content>
            <v-list-item-subtitle class="mt-4">Details</v-list-item-subtitle>
            <p class="font-weight-bold" v-html="item.details"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import Gallery from '@/components/Gallery.vue'

export default {
    name: 'ViewProjectModal',
    components: {
        Gallery
    },
    props: {
        item: {
          type: Object,
          required: true
        },
        amenities: {
          type: Array,
          required: true
        }
    },
    data: () => ({
        dialog: false,
        financingOptions: [
            {text: 'Bank Financing', value: 0},
            {text: 'PAG-IBIG Financing', value: 1},
            {text: 'In-House', value: 2},
            {text: 'Cash', value: 3},
        ],
        propertySubType: [
            {text: 'House and lot', value: 1},
            {text: 'Condominium', value: 2},
            {text: 'Lot Only', value: 0},
            {text: 'Building', value: 3},
            {text: 'Hotel', value: 4},
            {text: 'Resort', value: 5},
        ],
    }),
    computed: {
        pictures() {
            return this.item.pictures.map( (image) => { return image.image })
        },
        units() {
            return this.item.property_unity_type.map( (unit) => { return unit.name })
        },
        amenityList() {
            return this.amenities.filter( (amenity) => { return this.item.amenities.includes(amenity.value) })
        },
        financingList() {
            return this.financingOptions.filter( (option) => { return this.item.financing_option.includes(option.value) })
        },
        propertySubTypeName() {
            return this.propertySubType.find(type => type.value == this.item.property_subtype ).text
        },
    }
}
</script>
<style scoped>
.col {
    padding-bottom: 0;
    padding-top: 0;
}
</style>
