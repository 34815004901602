<template>
<v-breadcrumbs :items="items">
    <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
    </template>
    </v-breadcrumbs>
</template>
<script>

export default {
  name: 'Breadcrumbs',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>

</style>
