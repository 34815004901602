<template>
  <div class="home">
    <Menu />
    <Cover label="About YDH Realty Services, Inc."/>
    <v-row class="justify-center mt-6 mb-2 align-center">
        <v-col cols="2" class="pt-0">
            <v-img class="logo-image" :src="require('@/assets/ydh_logo_2.png')"/>
        </v-col>
        <v-col cols="6">
            <p>Founded in 2017 by a group of PRC licensed real estate brokers, real estate appraisers, a lawyer, a CPA, engineers, and a banker—YDH Realty will make your dream home and dream real estate investment into a reality.</p>
            <p>We are experienced real estate service professionals, passionate in serving and providing our clients with real estate service solutions beyond expectation.</p>
            <p>A hassle-free and quality transaction is our priority.</p>
        </v-col>
    </v-row>
    <Services />
    <v-container>
      <v-card class="row card-section">
        <v-col cols="3">
            <v-img :src="mission.img"/>
        </v-col>
        <v-col cols="9">
            <v-card-subtitle>{{ mission.pretext }}</v-card-subtitle>
            <v-card-text>
                <div class="text--primary">{{ mission.title }}</div>
                <div>{{ mission.description }}</div>
            </v-card-text>
        </v-col>
      </v-card>
    </v-container>
    <v-container>
      <v-card class="row card-section">
        <v-col cols="9">
            <v-card-subtitle>{{ vision.pretext }}</v-card-subtitle>
            <v-card-text>
                <div class="text--primary">{{ vision.title }}</div>
                <div>{{ vision.description }}</div>
            </v-card-text>
        </v-col>
        <v-col cols="3 text-right">
            <v-img :src="vision.img" />
        </v-col>
      </v-card>
    </v-container>
    <v-container class="mb-8">
      <v-card class="row card-section">
        <v-col cols="5">
            <v-img :src="mission.img"/>
        </v-col>
        <v-col cols="7">
            <v-card-subtitle>{{ vision.pretext }}</v-card-subtitle>
            <v-card-text>
                <div class="text--primary">Core Values</div>
                <div>
                  <v-list-item-title>Customer-centered services</v-list-item-title>
                  <v-list-item-title>Honesty</v-list-item-title>
                  <v-list-item-title>Reliability</v-list-item-title>
                  <v-list-item-title>Integrity</v-list-item-title>
                  <v-list-item-title>Servant leadership</v-list-item-title>
                  <v-list-item-title>Trustworthy</v-list-item-title>
                </div>
            </v-card-text>
        </v-col>
      </v-card>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import Cover from '@/components/CoverNoSearch.vue'
import Featured from '@/components/homestatic/FeaturedProperties.vue'
import Services from '@/components/homestatic/Services.vue'
import Board from '@/components/homestatic/Board.vue'
import Footer from '@/components/Footer.vue'
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'About',
  data () {
    return {
      mission: {
        pretext: '#1 in the philippines',
        title: 'Our Mission',
        img: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
        description: 'To serve our clients with integrity, honesty, and professionalism.'
      },
      vision: {
        pretext: '#1 in the philippines',
        title: 'Our Vision',
        img: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
        description: 'To be the preferred real estate service provider in the Philippines.'
      }
    }
  },
  components: {
    Menu,
    Cover,
    Featured,
    Services,
    Board,
    Footer
  }
}
</script>
<style lang="scss" scoped>
//@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css?family=Karla");
@import '@/styles/styles.scss';
  .logo-image {
    margin-top: -10px;
  }
  .container {
    padding: 4rem 0;
    // font-family: 'Lato'!important;
  }

  ::v-deep {
    .container {
      padding: 4rem 0;
    }
    .v-card {
      &.card-section {
        box-shadow: none;
      }
    }
    .v-card .col { padding: 0; }
    .v-card__subtitle{
      color: $primary!important;
      font-size: 1rem;
      font-weight: bold;
      font-family: 'Karla';
      text-transform: uppercase;
      border-bottom: 1px solid $primary;
      padding: 1rem 3rem 1rem 2rem;
    }
    .v-card__text {
      padding: 2rem;
    }
    .v-card__text .text--primary,
    .v-container__main-title {
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: normal;
      margin-bottom: 2rem;
    }
    .v-container__main-title {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
</style>
