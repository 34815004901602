<template>
    <v-row>
        <v-col
            cols="6"
        >
            <v-select
                :items="default_options.listing_status"
                v-model="data.listing_status"
                label="Listing status"
                outlined
                required
            />
        </v-col>
        <v-col cols="6">
            <v-select
                :items="default_options.property_type"
                v-model="data.property_type"
                label="Property type*"
                :readonly="project_present"
                outlined
                required
            ></v-select>
        </v-col>
        <v-col cols="6">
            <v-select
                :items="default_options.property_subtype"
                v-model="data.property_subtype"
                label="Property sub type*"
                :readonly="project_present"
                outlined
                required
            ></v-select>
        </v-col>
        <v-col cols="6" v-if="property_unit_type && property_unit_type.length > 0 && data.property_subtype != 0">
            <v-select
                :items="property_unit_type"
                :rules="[property_unity_rules]"
                v-model="data.property_unit_type"
                label="Property unit type*"
                outlined
                required
                @change="handlePropertyUnitTypeChange"
            ></v-select>
        </v-col>
        <v-col cols="12">
            <v-select
                :items="sortedProjects"
                v-model="data.project"
                label="Project"
                @change="project_change"
                outlined
                clearable
                required
            ></v-select>
        </v-col>
        <v-col cols="12">
            <v-text-field
                v-model="price_display"
                :label="this.data.listing_status == 0 ? 'Price' : 'Price (monthly)'"
                prefix="₱"
                outlined
                required
                @keyup="numOnly($event, 'price_display')"
            ></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-select
                :items="default_options.financing_options"
                v-model="data.financing_options"
                label="Financing options"
                outlined
                required
                multiple
            ></v-select>
        </v-col>
        <v-col cols="12">
            <v-text-field
                v-model="data.listing_owner"
                label="Listing owner"
                :rules="[v => !!v || 'Field is required']"
                outlined
                required
            />
        </v-col>
        <v-col cols="12">
            <v-text-field
                v-model="data.property_owner"
                label="Property owner"
                :rules="[v => !!v || 'Field is required']"
                outlined
                required
            />
        </v-col>
        <template v-if="data.listing_status == 0">
            <v-col cols="12">
                <div class="headline mb-4 font-weight-bold">For Sale</div>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    label="Title number"
                    v-model="data.title_number"
                    outlined
                    required
                    class="mb-0"
                    :rules="[v => (v || '' ).length <= 20 || 'Title Number']"
                />
            </v-col>
            <v-col cols="12">
                <v-text-field
                    label="Tax declaration number"
                    v-model="data.tax_declaration_number"
                    outlined
                    required
                    class="mb-0"
                />
            </v-col>
        </template>
        <template v-if="data.listing_status == 1">
            <v-col cols="12">
                <div class="headline mb-4 font-weight-bold">For Rent</div>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    label="Terms of payment"
                    v-model="data.terms_of_payment"
                    outlined
                    class="mb-0"
                />
            </v-col>
        </template>
    </v-row>
</template>

<script>
    import { eventBus } from '@/services/eventBus';

    export default {
        props: [
            'options',
            'initial_data'
        ],
        data: () => ({
            default_options: {
                listing_status: [
                    {text: 'For Sale', value: 0},
                    {text: 'For Rent', value: 1},
                ],
                property_type: [
                    {text: 'Residential', value: 0},
                    {text: 'Commercial', value: 1},
                    {text: 'Agricultural', value: 2},
                    {text: 'Industrial', value: 3},
                    {text: 'Memorial', value: 4},
                    {text: 'Special properties', value: 5}
                ],
                property_subtype: [
                    {text: 'House and lot', value: 1},
                    {text: 'Condominium', value: 2},
                    {text: 'Lot Only', value: 0},
                ],
                property_unit_type: [
                    {text: 'Single-attached', value: 0},
                    {text: 'Single-detached', value: 1},
                    {text: 'Duplex', value: 2},
                    {text: 'Townhouse', value: 3},
                    {text: 'Bungalow', value: 4},
                ],
                financing_options: [
                    {text: 'Bank Financing', value: 0},
                    {text: 'PAG-IBIG Financing', value: 1},
                    {text: 'In-House', value: 2},
                    {text: 'Cash', value: 3},
                ],
            },
            data: {
                project: 0,
                listing_status: 0,
                property_type: 0,
                property_subtype: 1,
                property_unit_type: -1,
                price: 1000000,
                financing_options: [0],
                title_number: '',
                tax_declaration_number: '',
                terms_of_payment: '',
                listing_owner: '',
                property_owner: ''
            }
        }),
        computed: {
            project_list() {
                return [] + this.options.projects
            },
            price_display: {
                get() {
                    return this.data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('')
                    this.$set(this.data, 'price', no_comma)
                }
            },
            project_present() {
                return !!this.data.project
            },
            property_unit_type() {
                return this.data.project && this.data.project > 0 ? this.options.project_unit_types ? this.options.project_unit_types[this.data.project] : [] : this.default_options.property_unit_type;
            },
            sortedProjects() {
              return this.options.projects.sort((a, b) => (a.text > b.text) ? 1 : -1)
            }
        },
        methods: {
            property_unity_rules(value) {
                if (!this.property_unit_type || this.property_unit_type.length == 0) {
                    return true
                }
                if (value >= 0) {
                    return true
                }
                return 'Field is Required'
            },
            project_change(data){
                if (data) {
                    let {property_type, property_subtype} = this.options.projects.find(p => p.value == data)
                    this.$set(this.data, 'property_type', property_type)
                    this.$nextTick(_ => {
                        this.$set(this.data, 'property_subtype', property_subtype)
                        this.$set(this.data, 'property_unit_type', -1)
                    });
                    if(property_subtype == 0) {
                      eventBus.$emit('handlePropertyUnitTypeChange', {data: 'Lot Only'})
                    }
                } else {
                    this.$set(this.data, 'property_type', 0)
                    this.$nextTick(_ => {
                        this.$set(this.data, 'property_subtype', 0)
                    });
                    eventBus.$emit('handleProjectChange', {data: 'removed'})
                }
            },
            numOnly(event, value) {
              this[value] = event.target.value.replace(/[^\d]/g,'');
              event.target.value = this[value];
            },
            handlePropertyUnitTypeChange(value) {
              setTimeout(() => {
                let data = this.property_unit_type.find(obj => obj.value === value) ? this.property_unit_type.find(obj => obj.value === value).text : '';
                eventBus.$emit('handlePropertyUnitTypeChange', {data: data})
              }, 0)
            }
        },
        created() {
            this.$nextTick(() => {
                let data = Object.assign({}, this.data)
                if (this.initial_data) {
                    data = {
                        project: this.initial_data.project,
                        listing_status: this.initial_data.brokerage_type,
                        property_type: this.initial_data.property_type,
                        property_subtype: this.initial_data.property_subtype,
                        property_unit_type: this.initial_data.property_unit_type,
                        price: this.initial_data.price,
                        financing_options: this.initial_data.financing_option,
                        title_number: this.initial_data.title_number,
                        tax_declaration_number: this.initial_data.tax_declaration_number,
                        terms_of_payment: this.initial_data.terms_of_payment,
                        listing_owner: this.initial_data.listing_owner,
                        property_owner: this.initial_data.owner_of_the_property,
                    }
                    this.data = Object.assign({}, data)
                } else {
                    this.data = Object.assign({}, data)
                }
                this.$emit('update', data)
            });
        },
        watch: {
            'data.property_type'(new_data, old_data) {
                eventBus.$emit('handlePropertyUnitTypeChange', {data: ''})
                switch (new_data) {
                    case 0: //'Residential'
                        this.$set(this.default_options, 'property_subtype', [
                            {text: 'House and lot', value: 1},
                            {text: 'Condominium', value: 2},
                            {text: 'Lot Only', value: 0},
                        ])
                        if (this.data.property_subtype >= 3) {
                            this.$set(this.data, 'property_subtype', 0)
                        }
                        this.$set(this.data, 'property_subtype', 1)
                        break;
                    case 1: // 'Commercial'
                        this.$set(this.default_options, 'property_subtype', [
                            {text: 'Building', value: 3},
                            {text: 'Hotel', value: 4},
                            {text: 'Resort', value: 5},
                            {text: 'Lot Only', value: 0},
                        ])
                        if (this.data.property_subtype <= 2 && this.data.property_subtype >= 1) {
                            this.$set(this.data, 'property_subtype', 0)
                        }
                        break;
                    default:
                        this.$set(this.default_options, 'property_subtype', [
                            {text: 'Lot Only', value: 0},
                        ])
                        this.$set(this.data, 'property_subtype', 0)
                        break;
                }
            },
            'data.property_subtype'(new_data, old_data) {
                if(!this.initial_data) { eventBus.$emit('handlePropertyUnitTypeChange', {data: ''}) }
                if (this.data.property_type == 0) {
                    switch (new_data) {
                        case 1:
                            this.$set(this.default_options, 'property_unit_type', [
                                {text: 'Single-attached', value: 0},
                                {text: 'Single-detached', value: 1},
                                {text: 'Duplex', value: 2},
                                {text: 'Townhouse', value: 3},
                                {text: 'Bungalow', value: 4},
                            ])
                            if (this.data.property_unit_type == null) {
                                this.$set(this.data, 'property_unit_type', 0)
                                eventBus.$emit('handlePropertyUnitTypeChange', {data: ''})
                            }
                            break;
                        case 2:
                            this.$set(this.default_options, 'property_unit_type', [
                                {text: 'Studio', value: 5},
                                {text: 'One-bedroom', value: 6},
                                {text: 'Two-bedroom', value: 7},
                                {text: 'Three-bedroom', value: 8},
                                {text: 'Loft', value: 9},
                                {text: 'Penthouse', value: 10},
                                {text: 'Home office', value: 11},
                            ])
                            if (this.data.property_unit_type == null) {
                                this.$set(this.data, 'property_unit_type', 0)
                                eventBus.$emit('handlePropertyUnitTypeChange', {data: ''})
                            }
                            break;
                        default:
                            this.$set(this.data, 'property_unit_type', -1)
                            this.default_options.unit_type = []
                            eventBus.$emit('handlePropertyUnitTypeChange', {data: ''})
                            break;


                    }
                    if(this.initial_data && this.initial_data.property_unit_type) {
                      this.handlePropertyUnitTypeChange(this.initial_data.property_unit_type)
                    }
                } else {
                    this.$set(this.data, 'property_unit_type', -1)
                    this.$set(this.default_options, 'property_unit_type', [])
                    eventBus.$emit('handlePropertyUnitTypeChange', {data: ''})
                }

            },
            data: {
                handler(newValue, oldValue) {
                    this.$emit('update', newValue)
                },
                deep: true,

            },

        }
    }
</script>
