import axios from '@/services/axios-auth';
import API from '@/store/api-routes';
import Cookie from 'js-cookie';

const { MUTATION } = require('../mutation-types');
const { ACTION } = require('../action-types');

const state = {};
const getters = {};

const actions = {
    // GUESS
    [ACTION.FETCH_PROJECT]({ commit }, slug) {
        return axios.get(`${API.FETCH_PROJECT}${slug}`)
    },
    // ADMIN
    [ACTION.GET_PROJECTS]({ commit }, listParams) {
        return axios.get(`${API.FETCH_ADMINPROJECTS}?${new URLSearchParams(listParams).toString()}`)
    },
    [ACTION.GET_PROJECT]({ commit }, {id}) {
      console.log("id", id)
        return axios.get(`${API.FETCH_ADMINPROJECTS}${id}/`)
    },
    [ACTION.CREATE_PROJECT]({ commit }, payload) {
        return axios.post(API.FETCH_ADMINPROJECTS, payload)
    },
    [ACTION.UPDATE_PROJECT]({ commit }, {id, payload}) {
        console.log('id', id)
        console.log('payload', payload)
        return axios.patch(`${API.FETCH_ADMINPROJECTS}${id}/`, payload)
    },
    [ACTION.DELETE_PROJECT]({ commit }, id) {
        return axios.delete(`${API.FETCH_ADMINPROJECTS}${id}/`, )
    },
    [ACTION.UPLOAD_IMAGE_PROJECT]({commit}, data) {
        return axios.post(`${API.FETCH_ADMINPROJECTS}${data.id}/upload_images/`, data.payload, res => {
          successCb(res);
        }, error => {
          errorCb(error);
        });
    },
    [ACTION.DELETE_IMAGE_PROJECT]({commit}, id){
        return axios.delete(`${API.ADMIN_PROJECT_IMAGES}${id}/`, res => {
          successCb(res);
        }, error => {
          errorCb(error);
        })
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
  }
