// auth.js
// export const REGISTER = 'REGISTER';
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

//GUESS
//
// listings
export const GET_LISTINGS = 'GET_LISTINGS'
// project
export const FETCH_PROJECT = 'FETCH_PROJECT'
// developer
export const FETCH_DEVELOPER = 'FETCH_DEVELOPER'


// ADMIN
//
// LISTING
export const GET_LISTING = 'GET_LISTING'
export const GET_ALISTINGS = 'GET_ALISTINGS'
export const GET_ALISTING = 'GET_ALISTING'
export const GET_ADMINLISTINGS = 'GET_ADMINLISTINGS'
export const GET_ADMINLISTING = 'GET_ADMINLISTING'
export const CREATE_LISTING = 'CREATE_LISTING'
export const FETCH_CREATE_DATA = 'FETCH_CREATE_DATA'
export const CREATE_BROKERAGE_LISTING = 'CREATE_BROKERAGE_LISTING'
export const CREATE_PROJECT_LISTING = 'CREATE_PROJECT_LISTING'
export const FETCH_EDIT_LISTING = 'FETCH_EDIT_LISTING'
export const FETCH_VIEW_LISTING = 'FETCH_VIEW_LISTING'
export const UPDATE_LISTING = 'UPDATE_LISTING'
export const DELETE_LISTING = 'DELETE_LISTING'
export const UPLOAD_IMAGE_LISTING = 'UPLOAD_IMAGE_LISTING'
export const FETCH_BROKERS = 'FETCH_BROKERS'

//developers
export const GET_DEVELOPERS = 'GET_DEVELOPERS'
export const GET_ADEVELOPERS = 'GET_ADEVELOPERS'
export const GET_ADEVELOPER = 'GET_ADEVELOPER'
export const CREATE_DEVELOPER = 'CREATE_DEVELOPER'
export const UPDATE_DEVELOPER = 'UPDATE_DEVELOPER'
export const DELETE_DEVELOPER = 'DELETE_DEVELOPER'


// Amenities
export const ADMIN_GET_AMENITIES = 'ADMIN_GET_AMENITIES'

//Projects
export const GET_PROJECTS = 'GET_PROJECTS'
export const GET_PROJECT = 'GET_PROJECT'
export const CREATE_PROJECT = 'CREATE_PROJECT'
export const UPDATE_PROJECT = 'UPDATE_PROJECT'
export const DELETE_PROJECT = 'DELETE_PROJECT'
export const UPLOAD_IMAGE_PROJECT = 'UPLOAD_IMAGE_PROJECT'
export const DELETE_IMAGE_PROJECT = 'DELETE_IMAGE_PROJECT'

//Amenities
export const GET_AMENITIES = 'GET_AMENITIES'
export const CREATE_AMENITY = 'CREATE_AMENITY'
export const UPDATE_AMENITY = 'UPDATE_AMENITY'
export const DELETE_AMENITY = 'DELETE_AMENITY'


export const ACTION = {
    //REGISTER,
    LOGIN,
    LOGOUT,
    GET_LISTINGS,
    GET_LISTING,
    GET_ALISTINGS,
    GET_ALISTING,
    GET_ADMINLISTINGS,
    GET_ADMINLISTING,
    CREATE_LISTING,
    UPDATE_LISTING,
    DELETE_LISTING,
    GET_DEVELOPERS,
    GET_ADEVELOPERS,
    GET_ADEVELOPER,
    CREATE_DEVELOPER,
    UPDATE_DEVELOPER,
    DELETE_DEVELOPER,
    FETCH_DEVELOPER,

    ADMIN_GET_AMENITIES,
    CREATE_PROJECT_LISTING,
    CREATE_BROKERAGE_LISTING,
    FETCH_EDIT_LISTING,
    FETCH_VIEW_LISTING,
    UPLOAD_IMAGE_LISTING,
    FETCH_BROKERS,

    FETCH_PROJECT,
    GET_PROJECTS,
    GET_PROJECT,
    CREATE_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT,
    UPLOAD_IMAGE_PROJECT,
    DELETE_IMAGE_PROJECT,
    GET_AMENITIES,
    CREATE_AMENITY,
    UPDATE_AMENITY,
    DELETE_AMENITY,
    FETCH_CREATE_DATA
}
