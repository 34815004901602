<template>
  <v-app>
    <Menu v-if="!isDashboard"/>
    <MainContainer />
  </v-app>
</template>

<script>
import Menu from '@/components/Menu.vue'
import MainContainer from '@/container/MainContainer.vue'

export default {
  name: 'App',
  components: {
    Menu,
    MainContainer
  },
  computed: {
    isDashboard() {
      return this.$route.fullPath.split("/")[1] == 'dashboard' ? true : false;
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
