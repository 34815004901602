<template>
  <v-main>
      <router-view></router-view>
  </v-main>
</template>

<script>

export default {
  name: 'MainContainer'
}
</script>
<style lang="scss" scoped>

</style>
