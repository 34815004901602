import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import axios from '../services/axios-auth'

import localForage from 'localforage'
import router from '../router'


import auth from './modules/auth'
import listing from './modules/listing'
import developer from './modules/developer'
import amenities from './modules/amenities'
import projects from './modules/project'
import leads from './modules/lead'
import broker from './modules/broker'
import salesperson from './modules/salesperson'
import realty from './modules/realty'

Vue.use(Vuex)

const vuexStorage = new VuexPersist({
  key: 'sourcelink',
  storage: localForage
})

const modules = {
    auth,
    listing,
    developer,
    amenities,
    projects,
    leads,
    broker,
    salesperson,
    realty
}

export default new Vuex.Store({
  plugins: [vuexStorage.plugin],
  	modules
})
