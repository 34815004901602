<template>
    <v-card
        :to="{ name: 'developerpage', params: { slug: item.slug } }"
        class="ma-4"
        max-width="800"
    >
        <v-card-title class="ml-2 font-weight-bold d-flex justify-space-between "><span>DEVELOPER INFORMATION</span> <span>Contact #:{{ item.contact_number }}</span></v-card-title>
        <v-row class="ma-0 pa-4">
            <v-col cols="1">
                <v-avatar size="60">
                    <v-img :src="item.image"></v-img>
                </v-avatar>
            </v-col>
            <v-col cols="11" class="pl-4">
                <v-card-title class="font-weight-bold pa-0"><span>{{ item.name }}</span></v-card-title>
                <div class="green--text font-weight-bold"><v-icon class="mr-1 green--text">mdi-check</v-icon> Certfied Partner</div>
                <div class="d-flex align-center mt-1">
                    <v-icon class="mr-1">mdi-map-marker</v-icon>{{ item.location }}
                </div>
            </v-col>
        </v-row>
        <v-card-text class="pt-0">
            {{ item.details }}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: 'Developer',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    listingTitle () {
      return this.item.title.replace(/\s+/g, '-')
    },
    // width() {
    //     return this.$route.name == 'home' ? '360' : '340';
    // }
  }
}
</script>
<style lang="scss" scoped>
  ::v-deep {
    .container {
      padding: 4rem 0;
    }
    .v-card {
      &.card-section {
        box-shadow: none;
      }
    }
    .v-card .col { padding: 0; }
    .v-card__subtitle{
      color: $primary!important;
      font-size: 1rem;
      font-weight: bold;
      font-family: 'Karla';
      text-transform: uppercase;
      border-bottom: 1px solid $primary;
      padding: 1rem 3rem 1rem 2rem;
    }
    .v-card__text {
      padding: 2rem;
    }
    .v-card__text .text--primary,
    .v-container__main-title {
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: normal;
      margin-bottom: 2rem;
    }
    .v-container__main-title {
      text-align: center;
      margin-bottom: 2rem;
    }
    .listing-price {
        position: absolute;
        bottom: 8px;
        left: 6px;
        background-color: rgba(0,0,0,.1);
        color: $white;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 1.2rem;
    }
  }
</style>
