<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          rounded
          v-bind="attrs"
          v-on="on"
          class="ml-2"
        >
          Edit
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline my-4">Edit</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                label="Listing name*"
                v-model="this.current_listing.title"
                outlined
                required
                class="mb-0"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
            >
              <v-select
                :items="listingType"
                v-model="this.current_listing.listing_type"
                label="Listing types"
                outlined
                required
              ></v-select>
            </v-col>
            <v-col
              cols="6"
            >
              <v-select
                :items="projectType"
                v-model="this.current_listing.project_type"
                label="Project"
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="6"
            >
              <v-select
                :items="unitType"
                v-model="this.current_listing.unit_type"
                label="Unit type*"
                outlined
                required
              ></v-select>
            </v-col>
            <v-col
              cols="6"
            >
              <v-select
                :items="propertyStatus"
                v-model="this.current_listing.property_status"
                label="Property status*"
                outlined
                required
              ></v-select>
            </v-col>
            <v-col
              cols="3"
            >
              <v-text-field
                label="Lot area*"
                v-model="this.current_listing.lot_area"
                outlined
                required
                class="mb-0"
              ></v-text-field>
            </v-col>
            <v-col
              cols="3"
            >
              <v-text-field
                label="Floor area*"
                v-model="this.current_listing.floor_area"
                outlined
                required
                class="mb-0"
              ></v-text-field>
            </v-col>
            <v-col
              cols="3"
            >
              <v-text-field
                label="Bedroom"
                v-model="this.current_listing.bedroom"
                outlined
                required
                class="mb-0"
              ></v-text-field>
            </v-col>
            <v-col
              cols="3"
            >
              <v-text-field
                label="Bathroom"
                v-model="this.current_listing.bathroom"
                outlined
                required
                class="mb-0"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="this.current_listing.amenities"
                :items="items"
                :search-input.sync="search"
                hide-selected
                label="Amenities"
                multiple
                persistent-hint
                small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
              <v-card-text class="pt-0 pl-0 red--text">Type amenity and press enter to create a new one</v-card-text>
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                type="number"
                v-model="this.current_listing.minimum_price"
                label="Minimum price"
                outlined
                required
                class="mb-0"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                type="number"
                v-model="this.current_listing.maximum_price"
                label="Maximum price"
                outlined
                required
                class="mb-0"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                type="number"
                v-model="this.current_listing.equity"
                label="Equity percentage"
                outlined
                required
                class="mb-0"
              ></v-text-field>
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                label="Equity Terms (months)"
                v-model="this.current_listing.terms"
                outlined
                required
                class="mb-0"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                label="Location*"
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
            >
              <v-select
                :items="availability"
                v-model="this.current_listing.availability"
                label="Availability*"
                outlined
                required
              ></v-select>
            </v-col>
            <!-- <v-col
              cols="12"
            >
              <v-combobox
                :items="units"
                label="Units*"
                multiple
                chips
                required
              ></v-combobox>
            </v-col> -->
            <v-col cols="12">
              <v-simple-table dark class="mb-4 mt-2">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Equity</td>
                      <td>200k - 400k</td>
                    </tr>
                    <tr>
                      <td>Loanable Amount</td>
                      <td>800k - 1.6m</td>
                    </tr>
                    <tr>
                      <td>Monthly Equity</td>
                      <td>4,1663 - 8,333</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                label="Details"
                v-model="this.current_listing.details"
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
            >
              <v-file-input
                v-model="images"
                color="blue accent-4"
                counter
                label="Images"
                multiple
                placeholder="Add images(.jpeg, .jpg, .png)"
                prepend-icon="mdi-image"
                outlined
                :show-size="1000"
                @change="onFileChange"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>

                  <span
                    v-else-if="index === 2"
                    class="overline grey--text text--darken-3 mx-2"
                  >
                    +{{ imageFiles.length - 2 }} File(s)
                  </span>
                </template>
              </v-file-input>
            </v-col>
            <v-col cols="12" v-if="invalidImages.length > 0">
              <v-alert type="error">
                Invalid image format:
                <span v-for="(invalidImage, key) in invalidImages" :key="`invalidImage-${key}`"
                  class="font-weight-bold"
                >{{ invalidImage }}</span>
              </v-alert>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="3" v-for="(image, key) in this.current_listing.images" :key="`image-${key}`">
                  <v-img :src="images[key]"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            Back
          </v-btn>
          <v-btn
            color="primary"
            @click="updateListing"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>

import { mapActions } from 'vuex'
import { ACTION } from '@/store/action-types'

export default {
  name: 'EditListingModal',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    current_listing: {},
    // listingType: ['Project', 'Brokerage'],
    // projectType: ['Residential', 'Option2', 'Option3'],
    // propertySubType: ['Condominium', 'House and Lot'],
    // propertyStatus: ['Pre-Selling', 'Ready to Occupy'],
    // availability: ['Available', 'Soldout'],
    // unitType: ['Studio', 'One Bedroom', 'Two Bedroom', 'Three Bedroom', 'Four Bedroom'],
    // items: ['Parking', 'Security Guard'],
    model: [],
    imageFiles: [],
    images: [],
    invalidImages: [],
    search: null
  }),
  watch: {
    model (val) {
      // if (val.length > 5) {
      //   this.$nextTick(() => this.model.pop())
      // }
    }
  },
  created () {
    this.fetch_data(this.id)
      .then(res => {
        this.current_listing = {
          title: res.data.name,
          contractor: res.data.developer_name,
          price: res.data.price,
          type: res.data.property_type,
          bedrooms: res.data.bedroom,
          bathroom: res.data.bathroom,
          lotarea: '0',
          floorarea: `${res.data.floor_area} sqm`,
          location: res.data.location,
          availability: res.data.availability,
          amenities: res.data.amenities.map(am => am.name),
          description: res.data.details,
          images: res.data.pictures.map(pic => pic.url)
        }
    })
  },
  methods: {
     ...mapActions({
        fetch_data: ACTION.GET_LISTING
    }),
    onFileChange (files) {
      if (!files) {
        return
      }

      //this.images = [] // default to empty
      this.invalidImages = [] // default to empty

      for (let i = 0; i < this.imageFiles.length; i++) {
        if (!this.imageFiles[i].type.match(/.(jpg|jpeg|png)$/i)) {
          this.invalidImages.push(this.imageFiles[i].name)
          this.imageFiles.splice(i, 1)
        } else {
          const reader = new FileReader()

          reader.onload = (e) => {
            this.current_listing.images.push(reader.result)
          }

          reader.readAsDataURL(this.imageFiles[i])
        }
      }
  },

    updateListing() {
        this.$emit('updateListing', this.item)
    }
  }
}
</script>
<style scoped>
.col {
    padding-bottom: 0;
    padding-top: 0;
}
</style>
