<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          rounded
          v-bind="attrs"
          v-on="on"
          class="ml-2"
        >
          Delete
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline  my-4">Delete</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            Are you sure you want to delete {{ item.name }}?
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="deleteProject"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'DeleteProjectModal',
    props: {
        item: {
          type: Object,
          required: true
        }
    },
    data: () => ({
      dialog: false
    }),
    methods: {
        deleteProject() {
            this.$emit('deleteProject', this.item.id)
            this.dialog = false;
        },
    }
}
</script>
<style scoped>
.col {
    padding-bottom: 0;
    padding-top: 0;
}
</style>
