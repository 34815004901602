<template>
    <v-parallax
        dark
        height="100%"
        homecover
        :src="require('@/assets/cover-bg.jpg')"
    >
        <v-row
            align="center"
            justify="center"
        >
            <v-col class="text-center" cols="12">
                <h2 class="subheading">{{ label }}</h2>
            </v-col>
        </v-row>
    </v-parallax>
</template>
<script>
import Search from '@/components/Search.vue'

export default {
  name: 'HomeStaticCover',
  components: {
    Search
  },
  props: {
    label: {
      type: String,
      default: "YDH Realty Services, Inc."
    }
  },
  data () {
    return {}
  },
  methods: {
    search (query) {
      this.$emit('search', query)
    }
  }
}
</script>
<style lang="scss" scoped>
    .v-parallax {
        height: 500px;

        h1 {
            //font-family: Lato!important;
            font-size: 10rem !important;
            font-weight: bold;
            line-height: 10rem;
        }

        .subheading {
            font-size: 3rem;
            font-weight: bold;
            @media screen and (max-width: 600px) {
                font-size: 2rem;
            }
        }
    }

    ::v-deep .v-parallax__content {
        background: rgba(0, 0, 0, .6);
    }
    ::v-deep .v-parallax__image {
      //transform: none !important;
      top: -40%;
      width: 100% !important;
    }
</style>
