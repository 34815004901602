<template>
  <v-container fluid class="grey lighten-3">
      <v-container>
          <h2 class="mb-4 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</h2>
        <v-row
          align="center"
          justify="center"
        >
          <v-col class="text-center" cols="3"
              v-for="(member, i) in board"
              :key="i"
          >
              <v-avatar size="200">
                  <img
                      :src="require(`@/assets/${member.img}`)"
                      alt="John"
                  >
              </v-avatar>
              <h3 class="mt-4">{{ member.name }}</h3>
              <p class="subheading">{{ member.bio }}</p>
          </v-col>
        </v-row>
      </v-container>
  </v-container>
</template>
<script>
export default {
  name: 'HomeStaticServices',
  data () {
    return {
      board: [
        {
          img: 'antonio.jpg',
          name: 'Antonio Tan Jr, CPA',
          bio: 'VP-Finance'
        },
        {
          img: 'randy.jpg',
          name: 'Engr. Randy Angeles, REB, REA',
          bio: 'VP-Operations'
        },
        {
          img: 'rodel.jpg',
          name: 'Rodel Revilla, REB, REA, LPT',
          bio: 'President'
        },
        {
          img: 'sydrick.jpg',
          name: 'Atty. Sydrick Deparine',
          bio: 'VP-Legal'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
  h2 {
    font-size: 2rem;
    font-weight: normal;
  }
  h3 {
    font-family: "Karla";
    font-size: 1rem;
    text-transform: uppercase;
  }
  .v-avatar {
    border: 10px solid $white;
    box-shadow: 0 0 5px 8px rgba(0,0,0,.01);
  }
</style>
