<template>
    <div class="home">
        <Menu/>
        <Cover @search="search"/>
        <v-container>
            <v-row class="justify-space-between">
                <v-col cols="11" sm="12" class="mx-auto pb-0"><h2>Recently added</h2></v-col>
                <v-col cols="11" sm="12" class="mx-auto my-0 py-0 text-caption">These are the recently added items</v-col>
            </v-row>
            <v-row class="justify-space-between">
                <v-sheet
                    class="mx-auto homepage-listing"
                    max-width="100%"
                >
                    <v-slide-group
                        class="py-4 px-0"
                        show-arrows
                    >
                        <v-slide-item
                            v-for="(item, id) in listing"
                            :key="id"
                            v-slot="{ active, toggle }"
                        >
                            <List :item="item" :key="`list-key-${id}`"/>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </v-row>
            <v-row class="justify-space-between">
                <v-col cols="11" sm="12" class="mx-auto pb-0"><h2>Featured properties</h2></v-col>
                <v-col cols="11" sm="12" class="mx-auto my-0 py-0 text-caption">Check out our featured listings</v-col>
            </v-row>
            <v-row class="justify-space-between">
                <v-sheet
                    class="mx-auto homepage-listing"
                    max-width="100%"
                >
                    <v-slide-group
                        class="py-4 px-0"
                        show-arrows
                    >
                        <v-slide-item
                            v-for="(item, id) in featuredListing"
                            :key="id"
                            v-slot="{ active, toggle }"
                        >
                            <List :item="item" :key="`list-key-${id}`"/>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </v-row>
        </v-container>
        <Footer/>
    </div>
</template>

<script>
    import Menu from '@/components/Menu.vue'
    import Cover from '@/components/Cover.vue'
    import List from '@/components/Listing.vue'
    import Footer from '@/components/Footer.vue'
    import {mapActions} from 'vuex'
    import {ACTION} from '@/store/action-types'
    // @ is an alias to /src
    // import HelloWorld from '@/components/HelloWorld.vue'

    export default {
        name: 'Home',
        data() {
            return {
                listing: [],
                featuredListing: []
            }
        },
        components: {
            Menu,
            Cover,
            List,
            Footer
        },
        created() {
            let recent_query = {recent: true, size: 4}
            let featured_query = {featured: true, size: 4}
            if (this.$route.query.status && this.$route.query.status == 'For Rent') {
                recent_query.status = 'For Rent'
                featured_query.status = 'For Rent'
            } else {
                recent_query.status = 'For Sale'
                featured_query.status = 'For Sale'
            }
            this.get_listing((recent_query))
                .then(res => {
                  //console.log(res)
                    this.listing = this.toData(res.data)
                })

            this.get_listing((featured_query))
                .then(res => {
                  //console.log(res)
                    this.featuredListing = this.toData(res.data)
                })
        },
        methods: {
            ...mapActions({
                get_listing: ACTION.GET_LISTINGS
            }),
            search(data) {
                this.get_listing(data)
                    .then(res => {
                        this.listing = this.toData(res.data)
                    })
            },
            toData(data) {
                return data.results.map(item => ({
                    id: item.id,
                    title: item.name,
                    developer: item.developer_name,
                    project: item.project_name,
                    developer_slug: item.developer_slug,
                    project_slug: item.project_slug,
                    price: item.price,
                    type: item.property_type,
                    bedrooms: item.bedroom,
                    bathroom: item.bathroom,
                    monthly_equity: item.monthly_equity,
                    parking: item.parking, // not needed
                    location: item.location,
                    floorarea: `${item.floor_area}`,
                    lotarea: `${item.lot_area}`,
                    status: item.property_status,
                    subtype: item.property_subtype,
                    image: item.image,
                    slug: item.slug,
                    unit_type: item.unit_type
                }))
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/styles/styles.scss';
    .home {
      @media only screen and (max-width: 600px) {
          padding-bottom: 4rem;
      }
    }

    .container {
        padding: 4rem 0;
    }

    .homepage-listing {
        box-shadow: none;
    }

    ::v-deep {
        .container {
            padding: 4rem 0;
            @media screen and (max-width: 600px) {
                padding: 1rem 0;
            }
        }

        .v-card {
            &.card-section {
                box-shadow: none;
            }

            @media screen and (max-width: 600px) {
                max-width: 320px;
                margin: 5px !important;
            }

        }

        .v-card .col {
            padding: 0;
        }

        .v-card__subtitle {
            color: $primary !important;
            font-size: 1rem;
            font-weight: bold;
            font-family: 'Karla';
            text-transform: uppercase;
            border-bottom: 1px solid $primary;
            padding: 1rem 3rem 1rem 2rem;
        }

        .v-card__text {
            padding: 2rem;
        }

        .v-card__text .text--primary,
        .v-container__main-title {
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: normal;
            margin-bottom: 2rem;
        }

        .v-container__main-title {
            text-align: center;
            margin-bottom: 2rem;
        }
    }
</style>
