<template>
    <v-row>
           <v-btn
            color="primary"
            dark
            x-large
            @click="dialog=true"
        >
            Add
        </v-btn>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-card-title>
                    <span class="headline my-4">Add salesperson</span>
                </v-card-title>
                <v-form ref="form">
                <v-card-text class="pt-0 mt-0">
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    label="Name*"
                                    v-model="data.name"
                                    outlined
                                    :error="data.name == '' && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    required
                                    class="mb-0"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    label="Position*"
                                    v-model="data.position"
                                    :error="data.position == '' && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    outlined
                                    required
                                    class="mb-0"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="data.email"
                                    label="Email address"
                                    outlined
                                    required
                                    class="mb-0"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    label="Contact numbers*"
                                    v-model="data.contact"
                                    :error="data.contact == '' && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    outlined
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="mb-4 grey--text">
                                <small>separated by / i.e 09123456788/09123456789</small>
                            </v-col>
                            <v-col cols="12">
                                <v-row
                                    v-for="(item, index) in licenseItems"
                                    :key="`form-${index}`"
                                    class="px-2 pb-2"
                                  >
                                    <v-col class="pa-0 d-flex justify-end">
                                      <v-text-field
                                        label="Accreditation"
                                        v-model="item.name"
                                        hide-details="auto"
                                        outlined
                                        class="mx-1"
                                      ></v-text-field>
                                      <v-btn
                                        v-if="licenseItems.length > 1"
                                        x-small
                                        depressed
                                        fab
                                        color="error"
                                        class="ml-2"
                                        @click="deleteLicense(index)"
                                      >
                                        <v-icon> mdi-close </v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                            </v-col>
                            <v-col cols="12 d-flex justify-end">
                                <div class="mt-1 mb-0">
                                    <v-btn color="primary" small depressed @click="addLicense" class="mt-1">
                                        ADD ANOTHER ACCREDITATION
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col cols="12" class="mt-4">
                                <v-row
                                    v-for="(item, index) in snsItems"
                                    :key="`form-${index}`"
                                    class="px-2 pb-2"
                                  >

                                    <v-col cols="3" class="pa-0">
                                      <v-select
                                        label="Social media"
                                        v-model="item.name"
                                        :items="snsList"
                                        hide-details="auto"
                                        outlined
                                      ></v-select>
                                    
                                    </v-col>
                                    <v-col class="pa-0 d-flex justify-end">
                                      <v-text-field
                                        label="Account"
                                        v-model="item.account"
                                        hide-details="auto"
                                        outlined
                                        class="mx-1"
                                      ></v-text-field>
                                      <v-btn
                                        v-if="snsItems.length > 1"
                                        x-small
                                        depressed
                                        fab
                                        color="error"
                                        class="ml-2"
                                        @click="deleteItem(index)"
                                      >
                                        <v-icon> mdi-close </v-icon>
                                      </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12 d-flex justify-end">
                                <div class="mt-2 mb-4">
                                    <v-btn color="primary" small depressed @click="addItem">
                                        Add another account
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col cols="12" class="mb-6">
                                <v-select
                                    label="Broker"
                                    v-model="data.broker_id"
                                    :items="brokerList"
                                    :item-text="'name'"
                                    :item-value="'id'"
                                    hide-details="auto"
                                    outlined
                                    ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <v-file-input
                                    color="blue accent-4"
                                    counter
                                    label="Add photo*"
                                    placeholder="Add photo(.jpeg, .jpg, .png)"
                                    :error="data.photo == '' && showError"
                                    :rules="[(v) => !!v || 'Field is required']"
                                    prepend-icon="mdi-image"
                                    outlined
                                    :show-size="1000"
                                    @change="onFileChange"
                                >
                                    <template v-slot:selection="{ index, text }">
                                        <v-chip
                                            v-if="index < 2"
                                            color="deep-purple accent-4"
                                            dark
                                            label
                                            small
                                        >
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeModal"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="handleAddSalesperson"
                        :loading="loader"
                        :disabled="loader"
                    >
                        Add
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';

    export default {
        name: 'AddSalespersonModal',
        data: () => ({
            showError: false,
            menu: [],
            snsList: ['Facebook', 'Youtube', 'Instagram', 'Twitter', 'Whatsapp', 'Viber', 'LinkedIn', 'Tiktok'],
            licenseItems: [{ name: "" }],
            snsItems: [ { name: null, account: "" } ],
            data: {
                name: "",
                position: "",
                contact: "",
                email: "",
                email_address: "",
                photo: null,
                broker_id: 4
            },
            brokerList: [],
            loader: false,
            dialog: false,
            image: null
        }),
        mounted() {
            this.clearData();
            this.getBrokersList();
        },
        computed: {
            computedDateFormatted () {
              return this.formatDate(this.date)
            },
        },
        watch: {
            dialog(newVal, odlVal) {
                this.$nextTick()
                    .then(_ => {
                        this.clearData()
                        this.$refs.form.reset()
                    })
            },
            errors: {
                deep: true,
                handler(newVal, oldVal) {
                    this.onError()
                }
            }
        },
        methods: {
            ...mapActions(["addSalesperson", "updateSalesperson", "getBrokers"]),
            getBrokersList() {
              this.getBrokers()
                .then((response) => {
                    this.brokerList = response.data
                })
                .catch((error) => {
                    console.log(error);
                })
            },
            formatDate (date) {
              if (!date) return null

              const [year, month, day] = date.split('-')
              return `${month}/${day}/${year}`
            },
            parseDate (date) {
                console.log('date', date)
              if (!date) return null

              const [month, day, year] = date.split('/')
              return `${month}/${day}/${year}`
            },
            dateContactedPickerSave(date, ref) {
              this.$refs[`${ref}`].save(date);
            },
            addItem() {
                let item = {
                    name: "",
                    account: "",
                }
                this.snsItems.push(item)
            },
            addLicense() {
                let item = { name: "" }
                this.licenseItems.push(item)
            },
            deleteLicense(index){
                this.licenseItems.splice(index, 1)
            },
            deleteItem(index){
                this.snsItems.splice(index, 1)
            },
            handleAddSalesperson() {
                this.loader = true
                let isValid = this.$refs.form.validate()
                if (!isValid) {
                    this.loader = false
                    return
                }
                let dataToAdd = this.data
                dataToAdd.email_address = this.data.email;
                dataToAdd.socials = this.snsItems;
                dataToAdd.license = this.licenseItems;
                
                this.addSalesperson(dataToAdd)
                    .then((response) => {
                        this.loader = false;
                        if(this.image) {
                            let formData = new FormData();
                            formData.append("image", this.image);
                            this.updateSalesperson({id: response.data.id, formData })
                            .then((responses) => {
                                this.loader = false;
                                this.dialog = false;
                            })
                            .catch((error) => {
                                console.log(error);
                                this.loader = false;
                            });
                        } else {
                            this.loader = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loader = false;
                    })
            },
            closeModal() {
                this.dialog = false;
                this.clearData();
            },
            clearData() {
                this.data.name = "";
                this.data.pictures = "";
                this.data.broker_id = 4;
                this.loader = false
            },
            onFileChange(file) {
                this.data.photo = file;
                this.image = file;
            },
            onError() {
                this.loader = false
            }
        }
    }
</script>
<style scoped>
    .col {
        padding-bottom: 0;
        padding-top: 0;
    }
</style>
