<template>
  <div>
    <v-container class="pb-12" fluid>
        <Breadcrumbs :items="crumbs"/>
        <Gallery :images="current_project.images"/>
        <v-container v-model="current_project">
            <div class="listing-price d-flex align-center">
                <h1 class="text-h4 font-weight-bold">{{ current_project.title }}</h1>
            </div>
            <v-divider class="mt-3"/>
            <h2 class="text-h5 mt-4 font-weight-bold">{{ current_project.developer }}</h2>
            <v-col>
                <v-icon class="mr-1">mdi-map-marker</v-icon>
                <span class="subheading">{{ current_project.location }}</span>
            </v-col>

            <h3 class="mt-4">Property type</h3>
            <v-col>
                {{ current_project.property_type }}
            </v-col>
            <h3 class="mt-4">Property subtype</h3>
            <v-col>
                {{ current_project.property_subtype }}
            </v-col>
            <template v-if="current_project.unit_type.length > 0">
                <h3 class="mt-4">Unit type</h3>
                <v-col>
                    <v-chip v-for="(unit, key) in current_project.unit_type" :key="`unit-${key}`"
                            class="mr-2">
                        {{ unit }}
                    </v-chip>
                </v-col>
            </template>
            <template v-if="current_project.amenities.length > 0">
              <h3 class="mt-4">Amenities</h3>
              <v-col>
                  <div class="d-flex justify-start">
                      <v-chip v-for="(amenity, key) in current_project.amenities" :key="`amenity-${key}`"
                              class="mr-2">
                          {{ amenity }}
                      </v-chip>
                  </div>
              </v-col>
            </template>

            <h3 class="mt-4">Description</h3>
            <v-col class="description">
                <p v-html="current_project.details"></p>
            </v-col>
            <h3 class="mt-4">Listings</h3>
            <v-row class="justify-space-between">
                <v-sheet
                    class="mx-auto homepage-listing"
                    max-width="100%"
                >
                    <v-slide-group
                        class="py-4 px-0"
                        show-arrows
                    >
                        <v-slide-item
                            v-for="(item, id) in listing"
                            :key="id"
                            v-slot="{ active, toggle }"
                        >
                            <List :item="item" :key="`list-key-${id}`"/>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </v-row>
        </v-container>
    </v-container>
    <Footer />
  </div>
</template>

<script>
    import ContactAgent from '@/components/ContactAgent.vue'
    import Breadcrumbs from '@/components/Breadcrumbs.vue'
    import Gallery from '@/components/Gallery.vue'
    import List from '@/components/Listing.vue'
    import Footer from '@/components/Footer.vue'
    import {mapActions} from 'vuex'
    import {ACTION} from '@/store/action-types'

    export default {
        name: 'ProjectPage',
        data() {
            return {
                current_project: {
                    title: '',
                    property_type: '',
                    property_subtype: '',
                    unit_type: [],
                    developer: '',
                    location: '',
                    amenities: [],
                    images: [],
                    slug: '',
                    details: ''
                },
                listing: [],
                colors: [
                    'indigo',
                    'warning',
                    'pink darken-2',
                    'red lighten-1',
                    'deep-purple accent-4'
                ],
                slides: [
                    'First',
                    'Second',
                    'Third',
                    'Fourth',
                    'Fifth'
                ],
            }
        },
        computed: {
            crumbs() {
                return [
                    {
                        text: 'Home',
                        to: '/'
                    },
                    {
                        text: this.current_project.name,
                        to: this.$route.fullPath
                    }
                ]
            },
            listingssss() {
              return this.toData(this.current_project.listing)
            }
        },
        created() {
            this.fetch_data(this.$route.params.slug)
                .then(res => {
                    this.current_project = {
                        title: res.data.name,
                        property_type: res.data.property_type,
                        property_subtype: res.data.property_subtype,
                        unit_type: res.data.property_unity_type,
                        developer: res.data.developer.name,
                        location: res.data.location,
                        amenities: res.data.amenities,
                        images: res.data.pictures,
                        slug: res.data.slug,
                        details: res.data.details,
                        listing: res.data.listing
                    }
                    this.listing = this.toData(res.data.listing)
                })
                .catch( res => {
                    if (res.status == 404) {
                        this.$router.push({name: 'notFound'})
                    }
                })


        },
        methods: {
            ...mapActions({
                fetch_data: ACTION.FETCH_PROJECT,
            }),
            toData(data) {
              console.log(data)
                return data.map(item => ({
                    id: item.id,
                    title: item.name,
                    developer: item.developer_name,
                    developer_slug: item.developer_slug,
                    price: item.price,
                    type: item.property_type,
                    bedrooms: item.bedroom,
                    bathroom: item.bathroom,
                    parking: item.parking, // not needed
                    location: item.location,
                    floorarea: item.floor_area,
                    lotarea: item.lot_area,
                    status: item.property_status,
                    image: item.image,
                    slug: item.slug,
                    project: item.project_name,
                    project_slug: item.project_slug
                }))
            }
        },
        components: {
            ContactAgent,
            Breadcrumbs,
            Gallery,
            List,
            Footer
        }
    }
</script>
<style lang="scss" scoped>
    .description p,
    .description p p {
        white-space: break-spaces;
    }

    .description p ::v-deep p {
        white-space: break-spaces;
    }

    ::v-deep .project-list {
        width: 45%;
    }
</style>
