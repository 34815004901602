<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="400px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!hiddenBtn"
        rounded
        v-bind="attrs"
        color="error"
        small
        v-on="on"
        class="ml-2"
      >
        {{btnTitle}}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{title}}</span>
      </v-card-title>
      <v-card-text>
        <v-container v-if="item">
          Are you sure you want to delete <strong>{{ item.name }}</strong>?
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="close"
        >
          Close
        </v-btn>
        <v-btn
          color="error"
          @click="handleBtn"
        >
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
    name: 'DeleteDialog',
    props: {
      show: Boolean,
      item: {
        type: Object
      },
      title: String,
      hiddenBtn: {
        type: Boolean,
        default: () => true
      },
      title: String,
      message: String,
      btnTitle: String
    },
    data: () => ({
      dialog: false
    }),
    methods: {
      handleBtn() {
        this.$emit('delete');
      },
      close() {
        this.$emit('close')
      }
    },
    watch: {
      show: {
        handler: function (newValue) {
          this.dialog = newValue;
        },
      },

      dialog(newVal) {
        if (!newVal) {
          this.close();
        }
      },
    },
}
</script>
<style scoped>
.col {
    padding-bottom: 0;
    padding-top: 0;
}
</style>
