import axios from '@/services/axios-auth';
import router from '@/router'
import Cookie from "js-cookie";


const state = {
    leads: [],
    leadpage: ''
};

const getters = {
    leads: (state) => {
        return state.leads;
    },
    leadpage: (state) => {
        return state.leadpage;
    },
};

const actions = {
    getLeads({ commit }, listParams) {
        return axios.get(`/admin/leads/?${new URLSearchParams(listParams).toString()}`)
    },
    getLead({ commit }, listParams) {
        return axios.get(`/admin/leads/${listParams.id}/`)
    },
    markLeadAsSpam({ commit }, listParams) {
        const updateParams = { is_spam: listParams.is_spam, status: listParams.status }
        return axios.patch(`/admin/leads/${listParams.id}/`, updateParams)
    },
    closeLead({ commit }, listParams) {
        const updateParams = { status: listParams.status }
        return axios.patch(`/admin/leads/${listParams.id}/`, updateParams)
    },
    setleadAssignee({ commit }, listParams) {
        const updateParams = { assignee: listParams.assignee }
        return axios.patch(`/admin/leads/${listParams.id}/`, updateParams)
    },
    setListingURL({ commit }, listParams) {
        const updateParams = { listing_url: listParams.listing_url }
        return axios.patch(`/admin/leads/${listParams.id}/`, updateParams)
    },
    deleteLead({ commit }, listParams) {
        axios.delete(`/admin/leads/${listParams.id}/`)
        .then(response => {
            console.log('response', response)
            router.push({name: listParams.name })
        })
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
};

const mutations = {
    setLeads(state, leads) {
        state.leads = leads
    },
    setLeadPage(state, leadpage) {
        console.log('lead', leadpage)
        state.leadpage = leadpage
    }
};

export default {
    state,
    getters,
    actions,
    mutations
  }
