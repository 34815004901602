import axios from '@/services/axios-auth'
import API from '@/store/api-routes'
import Cookie from "js-cookie";

const { MUTATION } = require('../mutation-types')
const { ACTION } = require('../action-types')


const state = {
  listings: []
}

const getters = {
  listings: (state) => {
    return state.listings;
  },
  listing: (state) => (id) => {
    return state.listings.find(listing => listing.id === id);
  }
};

const actions = {
  [ACTION.GET_LISTINGS] ({ commit }, { q = '', featured = '', size = '', recent = '', status = '', type = '', price = '' } = {}) {
    let query = []
    if (q) {
      query.push(`q=${q}`)
    }
    if (featured) {
      query.push(`featured=${featured}`)
    }
    if (size) {
      query.push(`page_size=${size}`)
    }
    if (recent) {
      query.push(`recent=${recent}`)
    }
    if (status) {
      query.push(`status=${status}`)
    }
    if (price) {
      query.push(`price=${price}`)
    }
    if (type) {
      query.push(`type=${type}`)
    }
    return axios.get(`${API.FETCH_LISTINGS}?${query.join('&')}`)
  },
  [ACTION.GET_LISTING] ({ commit }, slug) {
    return axios.get(`${API.FETCH_LISTING}${slug}`)
  },
  //admin
  [ACTION.GET_ADMINLISTINGS] ({ commit }, params) {
    const { page = null, itemsPerPage = null, q = null } = params
    let queryParams = `?page=${page}&page_size=${itemsPerPage}`
    if (q != null) {
      queryParams += `&q=${q}`
    }
    return axios.get(API.FETCH_ADMINLISTINGS + queryParams)
  },
  [ACTION.GET_ADMINLISTING] ({ commit }, data) {
    return axios.get(`${API.ADMINLISTING}${data.id}`)
  },
  [ACTION.CREATE_LISTING] ({ commit }, data) {
    axios.post(API.ADMINLISTING, data, res => {
      successCb(res)
    }, error => {
      errorCb(error)
    });
  },
  [ACTION.FETCH_CREATE_DATA] ({ commit }, data) {
    return axios.get(API.ADMIN_LISTING_FETCH_CREATE_DATA)
  },
  [ACTION.CREATE_PROJECT_LISTING] ({ commit }, data) {
    return axios.post(`${API.ADMINLISTING}?listing_type=project`, data);
  },
  [ACTION.CREATE_BROKERAGE_LISTING] ({ commit }, data) {
    return axios.post(`${API.ADMINLISTING}?listing_type=brokerage`, data);
  },
  [ACTION.FETCH_EDIT_LISTING] ({ commit }, data) {
    return axios.get(`${API.ADMINLISTING}${data.id}/fetch_edit_listing/`, res => {
      successCb(res);
    }, error => {
      errorCb(error);
    });
  },
  [ACTION.FETCH_VIEW_LISTING] ({ commit }, data) {
    return axios.get(`${API.ADMINLISTING}${data.id}/fetch_view_listing/`, res => {
      successCb(res);
    }, error => {
      errorCb(error);
    });
  },
  [ACTION.UPDATE_LISTING] ({ commit }, data) {
    return axios.post(`${API.ADMINLISTING}${data.id}/update/`, data.payload, res => {
      successCb(res);
    }, error => {
      errorCb(error);
    });
  },
  [ACTION.UPLOAD_IMAGE_LISTING] ({ commit }, data) {
    return axios.post(`${API.ADMINLISTING}${data.id}/upload_images/`, data.payload, res => {
      successCb(res);
    }, error => {
      errorCb(error);
    });
  },
  [ACTION.DELETE_LISTING] ({ commit }, data) {
    axios.delete(`${API.ADMINLISTING}${data.id}/`, res => {
      successCb(res);
    }, error => {
      errorCb(error);
    });
  },
  [ACTION.FETCH_BROKERS] ({ commit }) {
    return axios.get(`${API.FETCH_BROKERS}`, res => {
      successCb(res);
    }, error => {
      errorCb(error);
    });
  },
  delete_picture ({ commit }, id) {
    return axios.delete(`${API.ADMIN_LISTING_IMAGES}${id}/`, res => {
      successCb(res);
    }, error => {
      errorCb(error);
    })
  },
  upload_brokerage_docs ({ commit }, data) {
    return axios.post(
            `${API.ADMINLISTING}${data.id}/upload_brokerage_docs/?brokerage_field=${data.field}`,
            data.payload,
            res => {
              successCb(res);
            }, error => {
              errorCb(error);
            });
  }
}

const mutations = {
  [MUTATION.SET_LISTINGS] (state, data) {
    state.listings = data
  },
  [MUTATION.ADDLISTING] (state, data) {
    state.listings.push(data);
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
