import axios from '@/services/axios-auth'
import router from '@/router'
import API from '@/store/api-routes'
import Cookies from 'js-cookie';
const {MUTATION} = require('../mutation-types')
const {ACTION} = require('../action-types')

const state = {
    tokenId: null,
    userId: null,
    user: null,
    user_type: 0
}

const getters = {
    loggedIn: state => !!state.tokenId,
    user_type: state => state.user_type,
    user: state => state.user
}

const actions = {
    [ACTION.LOGIN]({commit}, authData) {
        axios.post(API.LOGIN, {
            username: authData.username,
            password: authData.password,
            returnSecureToken: true
        })
            .then(res => {
                const now = new Date()
                //const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
                const userType = 1
                commit(MUTATION.SET_USER, {
                    tokenId: res.data.access,
                    user: authData.username,
                    user_type: userType
                })

                Cookies.set('login-token', res.data.access);
                //localStorage.setItem('login-token', res.data.access)

                router.push({name: 'developers'})
            })
            .catch(error => {
                authData.errorCb(error)
            })
    },
    [ACTION.LOGOUT]({commit}) {
        Cookies.remove('login-token');
        //localStorage.removeItem('login-token')
        commit(MUTATION.REMOVE_USER)
        router.push('/login')
    }
}

const mutations = {
    [MUTATION.SET_USER](state, userData) {
        state.tokenId = userData.tokenId
        state.userId = userData.userId
        state.expirationDate = userData.expirationDate
        state.user = userData.user
        state.user_type = userData.user_type
    },
    [MUTATION.REMOVE_USER](state) {
        state.tokenId = ''
        state.userId = ''
        state.expirationDate = ''
        state.user = ''
        state.user_type = 0
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
