<template>
    <v-row class="mb-5">
        <v-col cols="12">
            <h4>{{ title }}</h4>
        </v-col>
        <transition name="fade">
        <v-alert
          v-if="maxImagesReached"
          dismissible
          type="warning"
          border="left"
          elevation="2"
          colored-border
          icon="mdi-information"
          class="ml-6"
        >
          You can only upload up to 20 images.
        </v-alert>
      </transition>
        <v-col cols="12">
            <v-file-input
                v-model="image_files"
                color="blue accent-4"
                counter
                accept="image/*"
                :label="title"
                multiple
                placeholder="Add images (.jpeg, .jpg, .png)"
                prepend-icon="mdi-image"
                outlined
                :show-size="1000"
            >
                <template v-slot:selection="{ index, text }">
                    <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                    >
                        {{ text }}
                    </v-chip>

                    <span
                        v-else-if="index === 2"
                        class="overline grey--text text--darken-3 mx-2"
                    >
                +{{ image_files.length - 2 }} File(s)
              </span>
                </template>
            </v-file-input>
            <v-btn
                color="blue-grey"
                class="ma-2 white--text"
                @click="upload"
                :disabled="imageFilesLength == 0 || imageFilesLength > 20"
            >
                Upload
                <v-icon
                    right
                    dark
                >
                    mdi-cloud-upload
                </v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12">
            <v-sheet
                class="grey lighten-1"
                max-width="1080"
              >
                <v-slide-group
                    class="mx-auto pa-4"
                    show-arrows
                >
                    <div class="caption" v-if="pictures.length == 0">No Images</div>
                    <v-slide-item
                        v-for="img in pictures"
                        :key="img.id + 'img'"
                        v-slot="{ active, toggle }"
                    >
                    <v-card
                      class="ma-4"
                      height="200"
                      width="200"
                    >
                        <v-img
                            :src="img.image"
                            height="200"
                            width="200"
                        >
                            <template v-slot:placeholder>
                                <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                            <v-app-bar
                                flat
                                color="rgba(0, 0, 0, 0)"
                                class="pt-0"
                                height="24"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="error"
                                    icon
                                    small
                                    dark
                                    @click="trigger_delete_picture(img.id)"
                                >
                                    <v-icon>mdi-cancel</v-icon>
                                </v-btn>
                            </v-app-bar>
                        </v-img>
                      </v-card>
                    </v-slide-item>
                </v-slide-group>
            </v-sheet>
        </v-col>

    </v-row>
</template>

<script>
    import {mapActions} from 'vuex'
    import {UPLOAD_IMAGE_LISTING} from '@/store/action-types'

    export default {
        components: {
            UPLOAD_IMAGE_LISTING
        },
        props: [
            'listing_id',
            'initial_data',
            'upload_fn',
            'delete_fn',
            'title'
        ],
        data: () => ({
            image_files: [],
            pictures: [],
            model: null,
            modal_picture: '',
            modal_title: '',
            maxImagesReached: false
        }),
        computed: {
            imageFilesLength() {
              return this.image_files.length;
            }
        },
        created() {
            this.$nextTick(() => {
                this.pictures = this.initial_data
            })
        },
        methods: {
            upload() {
                if (this.image_files.length == 0) {
                    return
                }

                let payload = new FormData()

                // let payload = this.image_files.map(item=> ({'image': item}))
                this.image_files.forEach((item, index) => {
                    console.log('item', item)
                    payload.append('image[]', item)
                });
                this.upload_fn({id: this.listing_id, payload})
                    .then((res) => {
                        let data = res.data
                        this.pictures = [...this.pictures, ...data]
                    })
                this.image_files = []
            },
            trigger_delete_picture(id) {
                this.delete_fn(id)
                    .then(() => {
                        this.pictures = this.pictures.reduce((accu, cur) => {
                            if (cur.id != id) {
                                accu.push(cur)
                            }
                            return accu
                        }, [])
                    })
            }
        },
        watch: {
          imageFilesLength(value) {
            if(value > 20) {
              this.maxImagesReached = true;
              setTimeout(() => {
                this.maxImagesReached = false
              }, 8000)
            }
          }
        }
    }
</script>
<style scoped lang="scss">
    .download {
        position: absolute;
        bottom: 0;
        right: 0;
    }
</style>
