<template>
    <v-container fluid>
        <h1>Projects</h1>
        <v-divider class="my-4"></v-divider>
        <v-card-title class="mb-4">
            <AddProjectModal
                @createProject="createProject"
                :items="amenities"
                :developers="developers"
                :errors="add_form_errors"
                :visible="addDialogModal"
                @trigger="addDialogModalTrigger"
            />
            <ManageAmenitiesModal @createAmenity="createAmenity" :initial_amenities="amenities"/>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            >
            </v-text-field>
        </v-card-title>
        <v-card>

            <!-- <v-data-table
                :headers="headers"
                :items="projects"
                :search="search"
                :server-items-length="pagination.itemsLength"
                :options.sync="pagination"
                @update:options="tablePaginated"
            > -->
            <v-data-table
                :headers="headers"
                :items="projects"
                :search="search"
                :loading="loading"
            >
                <template v-slot:item.units="{ item }">
                    {{ item.units }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex my-4">
                        <ViewProjectModal
                            :id="item.id"
                            :amenities="amenities"
                            :item="item.allDetails"/>
                        <!-- <EditProjectModal :id="item.id" :items="amenities" :developers="developers"
                                          :item="item.allDetails" @updateProject="updateProject"/> -->
                        <v-btn
                            color="primary"
                            rounded
                            class="ml-2"
                            @click="openEditModal(item.id)"
                        >
                            Edit
                        </v-btn>
                        <DeleteProjectModal
                            :item="item"
                            :developers="developers"
                            @deleteProject="deleteProject"/>
                    </div>
                </template>
            </v-data-table>
        </v-card>
        <template v-if="current_edited_item">
            <EditProjectModal
                :id="current_edited_item.id"
                :items="amenities"
                :developers="developers"
                :item="current_edited_item"
                @updateProject="updateProject"
                :errors="edit_form_errors"
                :visible="editDialogModal"
                @trigger="editDialogModalTrigger"
            />
        </template>
    </v-container>
</template>
<script>

    import AddProjectModal from '@/components/modal/AddProject'
    import EditProjectModal from '@/components/modal/EditProject'
    import ViewProjectModal from '@/components/modal/ViewProject'
    import DeleteProjectModal from '@/components/modal/DeleteProject'

    import ManageAmenitiesModal from '@/components/modal/ManageAmenities'
    import {mapActions} from 'vuex'
    import {ACTION} from '@/store/action-types'

    export default {
        name: 'Projects',
        components: {
            AddProjectModal,
            EditProjectModal,
            DeleteProjectModal,
            ManageAmenitiesModal,
            ViewProjectModal
        },
        data() {
            return {
                search: '',
                pagination: {
                  page: 1,
                  itemsLength: 0,
                  itemsPerPage: 10
                },
                loading: false,
                headers: [
                    {text: 'Project', value: 'project'},
                    {text: 'Developer', value: 'developer'},
                    {text: 'Property Type', value: 'type', sortable: false},
                    {text: 'Units', value: 'units', sortable: false},
                    {text: 'Location', value: 'location', sortable: false},
                    {text: 'Actions', value: 'actions', class: 'actions', sortable: false}
                ],
                projects: [],
                amenities: [],
                developers: [],
                addDialogModal: false,
                add_form_errors: {
                    name: false
                },
                current_edited_item: null,
                edit_form_errors: {
                    name: false
                },
                editDialogModal: false
            }
        },
        created() {
            this.loading = true
            this.get_projects()
            .then(res => {
                console.log('res', res)
                this.projects = this.toData(res.data)
                this.loading = false
            })
            this.get_amenities()
            .then(res => {
                console.log('res amnities', res)
                this.amenities = res.data.map(item => ({
                    text: item.name,
                    value: item.id,
                }))
            });
            this.get_developers()
            .then(res => {
                this.developers = res.data.map(dev => ({
                    id: dev.id,
                    name: dev.name,
                    office_address: dev.office_address,
                    contact_number: dev.contact_number,
                    contact_person: dev.contact_person,
                    details: dev.details,
                    pictures: dev.pictures
                }))
            })
        },
        methods: {
            ...mapActions({
                get_projects: ACTION.GET_PROJECTS,
                create_project: ACTION.CREATE_PROJECT,
                update_project: ACTION.UPDATE_PROJECT,
                delete_project: ACTION.DELETE_PROJECT,
                create_amenity: ACTION.CREATE_AMENITY,
                update_amenity: ACTION.UPDATE_AMENITY,
                delete_amenity: ACTION.DELETE_AMENITY,
                get_developers: ACTION.GET_DEVELOPERS,
                get_amenities: ACTION.ADMIN_GET_AMENITIES
            }),
            tablePaginated(event) {
                // this.get_projects({ next: event.page })
                // .then(res => {
                //     console.log('res', res)
                //     this.projects = this.toData(res.data)
                // })
            },
            toData(data) {
                return data.map(item => ({
                    id: item.id,
                    project: item.name,
                    developer: item.developer.name,
                    type: item.property_type_name,
                    units: item.property_unity_type.map(item => item.name).join(', '),
                    location: item.location,
                    allDetails: Object.assign({}, item),
                    pictures: item.pictures
                }))
            },
            updateProject(data) {
                this.update_project(data)
                .then(res => {
                    let res_data = this.toData([res.data])
                    this.projects = this.projects.map(item => {
                        if (item.id === data.id) {
                            return res_data[0]
                        }
                        return item
                    });
                    this.editDialogModal = false
                    this.current_edited_item = null
                })
                .catch(res => {
                    let data = res.response.data
                    if (data.name) {
                        this.edit_form_errors = Object.assign(this.edit_form_errors, {name: true})
                    }
                })
            },
            deleteProject(id) {
                this.delete_project(id)
                this.projects = this.projects.filter(item => item.id != id)
            },
            createProject(data) {
                this.add_form_errors = Object.assign(this.add_form_errors, {name: false})
                return this.create_project(data)
                    .then(res => {
                        let res_data = this.toData([res.data])
                        this.projects.push(res_data[0])
                        this.addDialogModal = false
                    })
                    .catch(res => {
                        let data = res.response.data
                        if (data.name) {
                            this.add_form_errors = Object.assign(this.add_form_errors, {name: true})
                        }
                    })
            },
            createAmenity(data) {
                this.amenities.push({
                    text: data.name,
                    value: data.id,
                })
            },
            unitsToString(list) {
                return list.join(', ')
            },
            addDialogModalTrigger(data) {
                this.addDialogModal = data
                if (!data) {
                    this.add_form_errors = Object.assign(this.add_form_errors, {name: false})
                }
            },
            openEditModal(id) {
                console.log('editmodal', id)
                let item = Object.assign({}, this.projects.find(item => item.id == id))
                this.$nextTick(_ => {
                    console.log('item', item)
                    this.current_edited_item = item.allDetails
                    this.editDialogModal = true
                })
            },
            editDialogModalTrigger(data) {
                this.editDialogModal = data
                if (!data) {
                    this.current_edited_item = null
                    this.edit_form_errors = Object.assign(this.edit_form_errors, {name: false})
                }
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>
