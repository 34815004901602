<template>
  <v-overlay
    :absolute="false"
    :opacity="opacity"
    :value="true"
    :z-index="9"
    class="login-overlay"
  >
    <v-card
      width="100%"
      max-width="500"
      light
      class="mx-auto"
    >
      <div class="login-loader" v-if="loading">
        <RectLoader/>
      </div>
      <v-col class="pa-10">
        <v-img
          :src="require('../assets/ydh-logo.jpg')"
          contain
          height="200"
        />
        <v-card-title primary-title class="mt-4">
          <h2 class="login-headline text-center red--text text--darken-4">{{ card_title }}</h2>
        </v-card-title>
        <v-alert
          :value="errorMessage !== ''"
          type="error"
        >
          {{ errorMessage }}
      </v-alert>
        <v-card
          class="mb-5 pa-5"
          outlined
        >
          <form>
            <v-text-field
              prepend-icon="far fa-envelope"
              type="text"
              label="Username"
              placeholder=""
              v-model="username"
              @keydown.enter="loginAccount"
              required
            ></v-text-field>
            <v-text-field
              prepend-icon="fas fa-lock"
              type="password"
              label="Password"
              placeholder=""
              v-model="password"
              @keydown.enter="loginAccount"
              required
              class="mb-2"
            ></v-text-field>
            <v-btn @click="loginAccount" rounded color="primary mr-2 ">Login</v-btn>
          </form>
        </v-card>
      </v-col>
    </v-card>
  </v-overlay>
</template>

<script>
import RectLoader from '@/components/loading/Rect.vue'

export default {
  name: 'Login',
  components: {
    RectLoader
  },
  data: () => ({
    opacity: 0.6,
    card_title: 'We find your dream home',
    card_text: 'Lorem ipsum dolor sit amet, brute iriure accusata ne mea.',
    username: '',
    password: '',
    errorMessage: '',
    loading: false
  }),
    methods: {
        loginAccount () {
      const params = {
        username: this.username,
        password: this.password,
        errorCb: error => {
          this.loading = false
          this.errorMessage = 'Username and password mismatch'
        },
        successCb: res => { }
      }
      // this.$store.dispatch('LOGIN', params )
      this.$store.dispatch('LOGIN', params).then(() => {
        this.loading = true
      })
    }
  }
}
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Pacifico");
@import '@/styles/styles.scss';
.login-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
.login-overlay {
  font-family: 'Ubuntu'!important;
  height: 100vh;
  width: 100%;
  background: rgb(35,151,203);
  background: linear-gradient(90deg, rgba(35,151,203,1) 0%, rgba(14,30,103,1) 100%);
  position: fixed;
  .v-overlay__content{
    width: 100%;
    margin: 20px;
  }
  .login-headline {
    font-family: 'Pacifico'!important;
    width: 100%;
  }
  .v-btn{
    box-shadow: none;
  }
}
</style>
