<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                x-large
                class="ml-4"
            >
                Manage Amenities
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="d-flex justify-space-between">
                <span class="headline my-4">Manage amenities</span>
                <v-icon
                    medium
                    right
                    @click="dialog = false"
                >
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <v-data-table
                :headers="headers"
                :items="amenities"
            >
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex justify-end my-4">
                        <v-btn
                            @click="editAmenity(item)"
                        >
                            Edit
                        </v-btn>
                        <v-btn
                            color="primary"
                            class="ml-2"
                            @click="removeAmenity(item)"
                        >
                            Remove
                        </v-btn>
                    </div>
                </template>
            </v-data-table>
            <template v-if="deleting">

                <v-container>
                    Are you sure you want to delete {{ toBeDeleted }}?
                    <v-btn
                        @click="dialog = false"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="deleteAmenity"
                    >
                        Yes
                    </v-btn>
                </v-container>

            </template>
            <v-alert
                v-if="alertMessage !== ''"
                text
                :type="alertType"
            >
                {{ this.alertMessage }}
            </v-alert>
            <v-card-text class="mt-4" v-if="!deleting">
                <v-container>
                    <v-row>
                        <v-col
                            cols="10"
                        >
                            <v-text-field
                                label="Amenity"
                                v-model="amenity"
                                outlined
                                required
                                class="mb-0"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="2"
                        >
                            <v-btn
                                v-if="!editing"
                                color="primary"
                                dark
                                x-large
                                class="mx-0 px-0"
                                @click="createAmenity"
                            >
                                Add
                            </v-btn>
                            <v-btn
                                v-else
                                color="primary"
                                dark
                                x-large
                                class="mx-0 px-0"
                                @click="updateAmenity"
                            >
                                Update
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import {mapActions} from 'vuex'
    import {ACTION} from '@/store/action-types'

    export default {
        name: 'ManageAmenitiesModal',
        props: [
            'initial_amenities'
        ],
        data: () => ({
            dialog: false,
            editing: false,
            deleting: false,
            toBeDeleted: '',
            id: '',
            amenity: '',
            amenities: [],
            headers: [
                {text: 'Amenity', value: 'name'},
                {text: '', value: 'actions', class: 'actions', sortable: false}
            ],
            alertMessage: '',
            alertType: ''
        }),
        mounted() {
            this.amenity = '';
        },
        created() {
            // this.amenities = this.initial_amenities.map( item => ({
            //         id: item.value,
            //         name: item.text
            //     }))
            // this.get_amenities()
            //     .then(res => {
            //         this.amenities = this.toData(res.data)
            //     })
            // this.$nextTick( _ => {

            //     console.log(this.initial_amenities)
            // })
        },
        methods: {
            ...mapActions({
                get_amenities: ACTION.ADMIN_GET_AMENITIES,
                create_amenity: ACTION.CREATE_AMENITY,
                update_amenity: ACTION.UPDATE_AMENITY,
                delete_amenity: ACTION.DELETE_AMENITY,
            }),
            toData(data) {
                return data.map(item => ({
                    id: item.id,
                    name: item.name,
                }))
            },
            createAmenity() {
                let formData = new FormData();

                formData.append('name', this.amenity);

                this.create_amenity(formData)
                    .then(res => {
                        if (res.status == 201) {
                            this.alertType = 'success'
                            this.alertMessage = 'Amenity Created';
                            this.hideAlert();
                            this.amenity = '';
                            this.$emit('createAmenity', res.data)
                        }
                    })
            },
            editAmenity(item) {
                this.editing = true;
                this.id = item.id;
                this.amenity = item.name;
            },
            updateAmenity() {
                let formData = new FormData();

                formData.append("name", this.amenity);

                this.update_amenity({id: this.id, formData: formData})
                    .then(res => {
                        if (res.status == 200) {
                            this.alertType = 'success'
                            this.alertMessage = 'Amenity Updated';
                            this.hideAlert();
                            this.editing = false;
                            this.amenity = '';
                            this.get_amenities()
                                .then(res => {
                                    this.amenities = this.toData(res.data)
                                })
                        }
                    })
            },
            removeAmenity(item) {
                this.deleting = true;
                this.id = item.id;
                this.toBeDeleted = item.name;
            },
            deleteAmenity() {
                this.delete_amenity(this.id)
                    .then(res => {
                        if (res.status == 204) {
                            this.alertType = 'info'
                            this.alertMessage = 'Amenity Deleted';
                            this.hideAlert();
                            this.deleting = false;
                            this.get_amenities()
                                .then(res => {
                                    this.amenities = this.toData(res.data)
                                })
                        }
                    })
            },
            hideAlert() {
                window.setInterval(() => {
                    this.alertMessage = '';
                    this.alertType = '';
                }, 3000)
            }
        },
        watch: {
            initial_amenities(newVal) {
                this.amenities = newVal.map( item => ({
                    id: item.value,
                    name: item.text
                }))
            }
        }
    }
</script>
<style scoped>
    .col {
        padding-bottom: 0;
        padding-top: 0;
    }
</style>
