import axios from '@/services/axios-auth';

const state = {
    realty: [],
    realtypage: ''
};

const getters = {
    realty: (state) => {
        return state.realty;
    },
    realtypage: (state) => {
        return state.realtypage;
    },
};

const actions = {
    addRealty({commit}, data) {
        data.image = null;
        return axios.post('/admin/realty/', data)
        .then(response => response)
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
    updateRealty({commit}, data) {
        console.log('updateRealty', data)
        return axios.patch(`/admin/realty/${data.id}/`, data.formData)
        .then(response => response)
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
    getRealties({ commit }, listParams) {
        return axios.get(`/admin/realty/?${new URLSearchParams(listParams).toString()}`)
    },
    getRealty({ commit }, listParams) {
        return axios.get(`/admin/realty/${listParams.id}/`)
    },
    deleteRealty({commit}, data) {
        return axios.delete(`/admin/realty/${data.id}`)
        .then(response => response)
        .catch(error => {
            console.log('error', error)
            return error
        })
    },
};

const mutations = {
    setRealty(state, realty) {
        state.realty = realty
    },
    setRealtypage(state, realtypage) {
        console.log('lead', realtypage)
        state.realtypage = realtypage
    }
};

export default {
    state,
    getters,
    actions,
    mutations
  }
