<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
  >
  <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!hiddenBtn"
        color="primary"
        rounded
        dark
        small
        @click="dialog=true"
      >
        Update
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
          <span class="headline my-4">Update salesperson</span>
      </v-card-title>
      <v-form ref="form">
        <v-card-text class="pt-0 mt-0">
            <v-container>
                <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Name*"
                        v-model="data.name"
                        outlined
                        :error="data.name == '' && showError"
                        :rules="[(v) => !!v || 'Field is required']"
                        required
                        class="mb-0"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="Position*"
                        v-model="data.position"
                        :error="data.position == '' && showError"
                        :rules="[(v) => !!v || 'Field is required']"
                        outlined
                        required
                        class="mb-0"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                          label="Contact numbers*"
                          v-model="data.contact"
                          :error="data.contact == '' && showError"
                          :rules="[(v) => !!v || 'Field is required']"
                          outlined
                          required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mb-4 grey--text">
                        <small>separated by / i.e 09123456788/09123456789</small>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            label="Email address*"
                            v-model="data.email_address"
                            outlined
                            :error="data.email_address == '' && showError"
                            :rules="[(v) => !!v || 'Field is required']"
                            required
                            class="mb-0"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="mt-6 mb-2">
                        <v-row
                            v-for="(item, index) in licenseItems"
                            :key="`form-${index}`"
                            class="px-2 pb-2"
                          >
                            <v-col class="pa-0 d-flex justify-end">
                              <v-text-field
                                label="License*"
                                v-model="item.name"
                                hide-details="auto"
                                outlined
                                class="mx-1"
                              ></v-text-field>
                              <v-btn
                                v-if="licenseItems.length > 1"
                                x-small
                                depressed
                                fab
                                color="error"
                                class="ml-2"
                                @click="deleteItem(index)"
                              >
                                <v-icon> mdi-close </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                    </v-col>
                    <v-col cols="12 d-flex justify-end">
                        <div class="mt-0 mb-4">
                          <v-btn color="primary" small depressed @click="addLicense" class="mt-1">
                            Add more license
                          </v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" class="mt-6 mb-2">
                        <v-row
                            v-for="(item, index) in snsItems"
                            :key="`form-${index}`"
                            class="px-2 pb-2"
                          >

                            <v-col cols="3" class="pa-0">
                              <v-select
                                label="Social media*"
                                v-model="item.name"
                                :items="snsList"
                                hide-details="auto"
                                outlined
                              ></v-select>
                            
                            </v-col>
                            <v-col class="pa-0 d-flex justify-end">
                              <v-text-field
                                label="Account"
                                v-model="item.account"
                                hide-details="auto"
                                outlined
                                class="mx-1"
                              ></v-text-field>
                              <v-btn
                                v-if="snsItems.length > 1"
                                x-small
                                depressed
                                fab
                                color="error"
                                class="ml-2"
                                @click="deleteItem(index)"
                              >
                                <v-icon> mdi-close </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                    </v-col>
                    <v-col cols="12 d-flex justify-end">
                        <div class="mt-0 mb-4">
                        <v-btn color="primary" small depressed @click="addItem" class="mt-1">
                            Add another account
                        </v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" class="mb-6">
                      <v-select
                        label="Broker"
                        v-model="data.broker_id"
                        :items="brokerList"
                        :item-text="'name'"
                        :item-value="'id'"
                        hide-details="auto"
                        outlined
                        ></v-select>
                    </v-col>
                    <v-col cols="2" v-if="data.image && typeof(data.image) == 'string'" class="image-box">
                      <v-img :src="data.image" max-width="100" height="auto"/>
                      <v-btn icon @click="clearImage" absolute top right>
                        <v-icon color="error">mdi-close-circle</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="mt-4" v-if="!data.image">
                      <v-file-input
                        color="blue accent-4"
                        counter
                        placeholder="Add photo(.jpeg, .jpg, .png)"
                        :rules="[(v) => !!v || 'Field is required']"
                        prepend-icon="mdi-image"
                        outlined
                        :show-size="1000"
                        @change="onFileChange"
                      >
                        <template v-slot:selection="{ index, text }">
                            <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                            >
                                {{ text }}
                            </v-chip>
                        </template>
                      </v-file-input>
                    </v-col>
                </v-row>
            </v-container>
            <small>*indicates required field</small>
        </v-card-text>
      </v-form>
      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              @click="close"
          >
              Close
          </v-btn>
          <v-btn
              color="primary"
              @click="handleUpdate"
              :loading="loader"
              :disabled="loader"
          >
              Save
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: 'UpdateSalesperson',
    props: {
      show: Boolean,
      item: {
        type: Object
      },
      hiddenBtn: {
        type: Boolean,
        default: () => true
      },
    },
    data: () => ({
      showError: false,
      menu: [],
      data: {
        name: "",
        position: "",
        email_address: "",
        contact: "",
        image: null,
        license: [],
        socials: [],
        broker_id: 4
      },
      brokerList: [],
      image: null,
      snsList: ['Facebook', 'Youtube', 'Instagram', 'Twitter', 'Whatsapp', 'Viber', 'LinkedIn', 'Tiktok'],
      licenseItems: [ { name: "" } ],
      snsItems: [ { name: null, account: "" } ],
      loader: false,
      dialog: false
    }),
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },
    mounted() {
      this.getBrokersList();
    },
    methods: {
      ...mapActions(["updateSalesperson", "getBrokers"]),
      getBrokersList() {
        this.getBrokers()
          .then((response) => {
              this.brokerList = response.data;
          })
          .catch((error) => {
              console.log(error);
          })
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
          console.log('date', date)
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${month}/${day}/${year}`
      },
      logEvent() {
        let source = document.querySelector('.cke_source');
        if(source) {
          this.$set(this.data, 'details', source.value)
        }
      },
      addItem() {
        let item = {
            name: "",
            account: "",
        }
        this.snsItems.push(item)
      },
      addLicense() {
          let item = {
            name: "",
          }
          this.licenseItems.push(item)
      },
      handleUpdate() {
        this.loader = true
        let isValid = this.$refs.form.validate()
        if (!isValid) {
            this.loader = false
            return
        }
        this.data.socials = this.snsItems;
        this.data.license = this.licenseItems;
        delete this.data.image
        this.updateSalesperson({id: this.data.id, formData: this.data })
          .then((response) => {
            console.log('response', response)
            if(this.image) {
              let formData = new FormData();
              formData.append("image", this.image);
              this.updateSalesperson({id: response.data.id, formData })
              .then((responses) => {
                this.loader = false;
              })
              .catch((error) => {
                console.log(error);
                this.loader = false;
              });
            } else {
              this.loader = false;
            }
            this.update()
          })
          .catch((error) => {
            console.log(error);
            this.loader = false;
          })
      },
      clearData() {
        this.data.name = "";
        this.licenseItems = [ { name: "" } ];
        this.snsItems = [ { name: null, account: "" } ];
        this.data.image = "";
        this.loader = false
      },
      clearImage() {
        console.log('clearImage', this.data.image)
        this.data.image = null
        this.image = null
      },
      onFileChange(file) {
        this.data.image = file;
        this.image = file;
      },
      deleteItem(index){
        this.licenseItems.splice(index, 1)
      },
      onError() {
        this.loader = false
      },
      close() {
        this.clearData()
        this.$emit('close')
      },
      update() {
        this.clearData()
        this.$emit('update')
      }
    },
    watch: {
      show: {
        handler: function (newValue) {
          if(newValue) {
            Object.assign(this.data, this.item)
            if(this.item.license && this.item.license.length > 0){
              this.licenseItems = this.item.license;
            }
            if(this.item.socials && this.item.socials.length > 0){
              this.snsItems = this.item.socials
            }

            this.data.broker_id = this.data.broker ? this.data.broker.id : 4;
          }
          this.dialog = newValue;
        },
      },

      dialog(newVal) {
        if (!newVal) {
          this.close();
        }
      },
    },
}
</script>
<style scoped lang="scss">
.col {
    padding-bottom: 0;
    padding-top: 0;
}
.image-box {
  position: relative;
  .v-btn--icon {
    top: -13px;
    right: 0px;
    i {
      background: #fff;
      border-radius: 100%;
    }
  }
}
</style>
