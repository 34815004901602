<template>
    <v-card
        :to="{ name: 'projectpage', params: { slug: item.slug } }"
        class="ma-4"
        max-width="540"
    >
    <v-img
        height="270"
        width="540"
        :src="images[0]"
    ></v-img>
        <!-- <v-carousel :show-arrows="false">
            <v-carousel-item
                v-for="(image,i) in images"
                :key="i"
                :src="image.src"

                ></v-carousel-item>
        </v-carousel> -->
        <v-card-title class="ml-2 font-weight-bold d-flex justify-space-between "><span>PROJECT INFORMATION</span></v-card-title>
        <v-row class="ma-0 pa-4 pt-0">
            <!-- <v-col cols="5">
                <v-img :src="item.image"></v-img>
            </v-col> -->
            <v-col cols="12" class="pl-4">
                <v-card-title class="font-weight-bold pa-0"><span>{{ item.name }}</span></v-card-title>
                <div class="d-flex align-center mt-1">
                    <v-icon class="mr-1">mdi-map-marker</v-icon>{{ item.location }}
                </div>
            </v-col>
        </v-row>
        <v-card-text class="pt-0" v-html="details">
        </v-card-text>
    </v-card>
</template>

<script>
export default {
  name: 'Project',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  },
  computed: {
    listingTitle () {
      return this.item.name.replace(/\s+/g, '-')
    },
    images () {
        return this.item.images.map((str) => ({ src: str }));
    },
    imagess () {
        return this.item.images;
    },
    details() {
      return this.truncate(this.item.details, 100, '...');
    }
  },
  methods: {
    truncate(text, length, clamp){
        clamp = clamp || '...';
        return text.length > length ? text.slice(0, length) + clamp : text;
    }
  }
}
</script>
<style lang="scss" scoped>
  ::v-deep {
    .container {
      padding: 4rem 0;
    }
    .v-card {
      &.card-section {
        box-shadow: none;
      }
    }
    .v-card .col { padding: 0; }
    .v-card__subtitle{
      color: $primary!important;
      font-size: 1rem;
      font-weight: bold;
      font-family: 'Karla';
      text-transform: uppercase;
      border-bottom: 1px solid $primary;
      padding: 1rem 3rem 1rem 2rem;
    }
    .v-card__text {
      padding: 2rem;
    }
    .v-card__text .text--primary,
    .v-container__main-title {
      text-transform: uppercase;
      font-size: 2rem;
      font-weight: normal;
      margin-bottom: 2rem;
    }
    .v-container__main-title {
      text-align: center;
      margin-bottom: 2rem;
    }
    .listing-price {
        position: absolute;
        bottom: 8px;
        left: 6px;
        background-color: rgba(0,0,0,.1);
        color: $white;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 1.2rem;
    }
  }
</style>
