<template>
    <v-row>
        <v-btn
            color="primary"
            dark
            x-large
            @click="dialog=true"
        >
            Add
        </v-btn>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="800px"
        >
            <v-card>
                <v-form ref="form">
                    <v-card-title>
                        <span class="headline my-4">Add project</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-text-field
                                        label="Project name*"
                                        v-model="data.name"
                                        outlined
                                        required
                                        :rules="[v => !!v || 'Field is required']"
                                        class="mb-0"
                                        :error-messages="!errors.name ? [] : ['Name Already Exists']"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <v-select
                                        :items="developers"
                                        :item-text="'name'"
                                        :item-value="'id'"
                                        v-model="data.developer_id"
                                        label="Developer name*"
                                        outlined
                                        required
                                        :rules="[v => !!v || 'Field is required']"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="data.dhsud_lts"
                                        label="DHSUD LTS*"
                                        outlined
                                        required
                                        class="mb-0"
                                        :rules="[v => !!v || 'Field is required']"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-select
                                        :items="propertyType"
                                        v-model="data.property_type"
                                        label="Property type*"
                                        outlined
                                        required
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-select
                                        :items="propertySubType"
                                        v-model="data.property_subtype"
                                        label="Property sub type*"
                                        outlined
                                        required
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    v-if="data.property_type == 0 && (data.property_subtype == 1 || data.property_subtype == 2)"
                                >
                                    <v-select
                                        :items="unitType"
                                        v-model="data.property_unit_type"
                                        label="Unit type*"
                                        multiple
                                        outlined
                                        required
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    v-if="data.property_type == 0 || data.property_type == 4"
                                >
                                    <v-select
                                        v-model="data.amenities"
                                        :items="items"
                                        label="Amenities"
                                        multiple
                                        persistent-hint
                                        small-chips
                                    />
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <v-text-field
                                        v-model="data.location"
                                        label="Location"
                                        outlined
                                        required
                                        :rules="[v => !!v || 'Field is required']"
                                        class="mb-0"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <div class="headline mt-4 mb-2 font-weight-bold">Details</div>
                                    <ckeditor v-model="data.details" :config="editorConfig" class="mb-4"></ckeditor>
                                </v-col>
                                <v-col
                                    v-if="data.property_type == 0"
                                    cols="12"
                                >
                                    <v-select
                                        :items="financingOptions"
                                        v-model="data.financing_option"
                                        label="Financing options"
                                        :rules="[v =>  v.length > 0 || 'Field is required']"
                                        outlined
                                        multiple
                                        required
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                        <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="dialog = false"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="createProject"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import {mapActions} from 'vuex'
    import {ACTION} from '@/store/action-types'

    export default {
        name: 'AddProjectModal',
        props: [
            'items', 'developers', 'errors', 'visible'
        ],
        data: () => ({
            // dialog: false,
            editorConfig: {
                toolbarGroups: [
                    {name: 'document', groups: ['document', 'mode', 'doctools']},
                    {name: 'clipboard', groups: ['clipboard', 'undo']},
                    {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
                    {name: 'forms', groups: ['forms']},
                    {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
                    {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
                    {name: 'links', groups: ['links']},
                    {name: 'insert', groups: ['insert']},
                    '/',
                    {name: 'styles', groups: ['styles']},
                    {name: 'colors', groups: ['colors']},
                    {name: 'tools', groups: ['tools']},
                    {name: 'others', groups: ['others']},
                    {name: 'about', groups: ['about']}
                ],
                removeButtons: 'Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Button,ImageButton,HiddenField,CopyFormatting,RemoveFormat,BidiRtl,Language,Link,Unlink,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,About,ShowBlocks'
            },
            propertyType: [
                {text: 'Residential', value: 0},
                {text: 'Commercial', value: 1},
                {text: 'Agricultural', value: 2},
                {text: 'Industrial', value: 3},
                {text: 'Memorial', value: 4},
                {text: 'Special properties', value: 5}
            ],
            propertySubType: [
                {text: 'House and lot', value: 1},
                {text: 'Condominium', value: 2},
                {text: 'Lot Only', value: 0},
            ],
            unitType: [
                {text: 'Single-attached', value: 0},
                {text: 'Single-detached', value: 1},
                {text: 'Duplex', value: 2},
                {text: 'Townhouse', value: 3},
                {text: 'Bungalow', value: 4},
            ],
            search: null,
            financingOptions: [
                {text: 'Bank Financing', value: 0},
                {text: 'PAG-IBIG Financing', value: 1},
                {text: 'In-House', value: 2},
                {text: 'Cash', value: 3},
            ],
            imageFiles: [],
            images: [],
            invalidImages: [],
            data: {
                name: '',
                developer_id: '',
                property_type: 0,
                property_subtype: 1,
                property_unit_type: [0],
                amenities: [],
                location: '',
                details: '',
                reservation_fee: '',
                financing_option: [0],
                dhsud_lts: ''
            }
        }),
        computed: {
            dialog: {
                get() {
                    return this.visible
                },
                set(value) {
                    return this.$emit('trigger', value)
                }
            }
        },
        methods: {
            ...mapActions({
                get_developers: ACTION.GET_DEVELOPERS,
                get_amenities: ACTION.GET_AMENITIES
            }),
            toData(data) {
                return data.map(item => ({
                    id: item.id,
                    name: item.name,
                    office_address: item.office_address,
                    contact_number: item.contact_number,
                    contact_person: item.contact_person,
                    details: item.details,
                    pictures: item.pictures
                }))
            },
            createProject() {
                let valid = this.$refs.form.validate()
                if (!valid) {
                    return
                }
                let data = Object.assign({}, this.data)
                if (data.property_type != 0) {
                    delete data.property_unit_type
                    delete data.financing_option
                    delete data.reservation_fee
                }
                if (data.property_type != 0 && data.property_type != 4) {
                    delete data.amenities
                }
                if (data.property_subtype == 0) {
                    delete data.property_unit_type
                }

                this.$emit('createProject', data)
            },
            closeModal() {
                return this.$emit('trigger', false)
            },
        },
        watch: {
            dialog(newVal, odlVal) {
                this.$nextTick()
                    .then(_ => {
                        this.$refs.form.reset()
                        this.data.details = '' // manually reset since its ckeditor
                    })
            },
            'data.property_type'(new_data, old_data) {
                switch (new_data) {
                    case 0: //'Residential'
                        this.propertySubType = [
                            {text: 'House and lot', value: 1},
                            {text: 'Condominium', value: 2},
                            {text: 'Lot Only', value: 0},
                        ]
                        this.$set(this.data, 'property_subtype', 1)
                        break;
                    case 1: // 'Commercial'
                        this.propertySubType = [
                            {text: 'Building', value: 3},
                            {text: 'Hotel', value: 4},
                            {text: 'Resort', value: 5},
                            {text: 'Lot Only', value: 0},
                        ]
                        this.$set(this.data, 'property_subtype', 0)
                        break;
                    default:
                        this.propertySubType = [
                            {text: 'Lot Only', value: 0},
                        ]
                        this.$set(this.data, 'property_subtype', 0)
                        break;
                }
            },
            'data.property_subtype'(new_data, old_data) {
                if (this.data.property_type == 0) {
                    switch (new_data) {
                        case 1:
                            this.unitType = [
                                {text: 'Single-attached', value: 0},
                                {text: 'Single-detached', value: 1},
                                {text: 'Duplex', value: 2},
                                {text: 'Townhouse', value: 3},
                                {text: 'Bungalow', value: 4},
                            ]
                            this.$set(this.data, 'property_unit_type', [0])
                            break;
                        case 2:
                            this.unitType = [
                                {text: 'Studio', value: 5},
                                {text: 'One-bedroom', value: 6},
                                {text: 'Two-bedroom', value: 7},
                                {text: 'Three-bedroom', value: 8},
                                {text: 'Loft', value: 9},
                                {text: 'Penthouse', value: 10},
                                {text: 'Home office', value: 11},
                            ]
                            this.$set(this.data, 'property_unit_type', [5])
                            break;
                        default:
                            this.$set(this.data, 'property_unit_type', [-1])
                            this.unitType = []
                            break;
                    }
                } else {
                    this.$set(this.data, 'property_unit_type', [-1])
                    this.unitType = []
                }

            },
        }
    }
</script>
<style scoped>
    .col {
        padding-bottom: 0;
        padding-top: 0;
    }
</style>
