import axios from '@/services/axios-auth';
import API from '@/store/api-routes';
import Cookie from "js-cookie";
const { ACTION } = require('../action-types');

const state = {};
const getters = {};

const actions = {
    [ACTION.ADMIN_GET_AMENITIES]({ commit }) {
        console.log('ADMIN_GET_AMENITIES')
        return axios.get(API.FETCH_ADMINAMENITIES)
    },
    [ACTION.GET_AMENITIES]({ commit }, q) {
        return axios.get(`${API.FETCH_ADMINAMENITIES}${q}/`, res => {
          successCb(res);
        }, error => {
          errorCb(error);
        });
    },
    [ACTION.CREATE_AMENITY]({ commit }, data) {
        return axios.post(API.ADMINAMENITY, data, res => {
          successCb(res);
        }, error => {
          errorCb(error);
        });
    },
    [ACTION.UPDATE_AMENITY]({commit}, data) {
        return axios.patch(`${API.ADMINAMENITY}${data.id}/`, data.formData, res => {
          successCb(res);
        }, error => {
          errorCb(error);
        });
    },
    [ACTION.DELETE_AMENITY]({commit}, id) {
        return axios.delete(`${API.ADMINAMENITY}${id}/`, res => {
          successCb(res);
        }, error => {
          errorCb(error);
        });
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
  }
