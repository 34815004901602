<template>
    <v-container class="mx-auto search-container">
        <v-tabs
           v-model="tab"
           dark
        >
           <v-tab
             v-for="item in items"
             :key="item.tab"
             @mouseover="handleHoverIn"
             @mouseout="handleHoverOut"
           >
              <a :href="$router.resolve({ name: 'home', query: { status: item.status} }).href">{{ item.tab }}</a>
           </v-tab>
      </v-tabs>
      <v-card flat light>
        <v-card-text>
            <v-row class="search-form">
                <v-col
                class="py-0 px-1"
                cols="3"
                sm="3"
                >
                    <v-select
                        :items="property"
                        v-model="type"
                        label="Type"
                        solo
                    ></v-select>
                </v-col>
                <v-col
                class="py-0 px-1 text-field"
                cols="9"
                sm="7"
                >
                    <v-text-field
                        solo
                        v-model="search"
                        label="Enter a location, project, or developer"
                        @keydown.enter="handleKeyPress"
                        outlined
                    ></v-text-field>
                    <v-icon v-if="search !== ''" @click="clearTextField">mdi-close</v-icon>
                </v-col>
                <v-col
                class="py-0 px-1"
                cols="12"
                sm="2"
                >
                    <v-btn
                        :to="{ name: 'search', query: { search: search, type: type, status: tab == 0 ? 'For Sale' : 'For Rent' } }"
                        color="primary"
                        class="white--text"
                    >
                        Search<v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>
<script>
export default {
    name: 'SearchComponent',
    props: {
        home: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
          tab: 0,
          search: '',
          items: [
            { tab: 'Buy', content: 'Buy', status: 'For Sale' },
            { tab: 'Rent', content: 'Rent', status: 'For Rent' }
          ],
          type: 'Any',
          property: ['Any', 'House', 'Land', 'Condominium', 'Commercial']
        }
    },
    computed: {
        navStatus() {
            return this.$route.query.status ? this.$route.query.status : '';
        },
    },
    created() {
        if (this.$route.query.status) {
            this.tab = this.$route.query.status == 'For Sale'? 0 : 1
        }
    },
    methods: {
        handleHoverIn() {
            document.querySelector('.v-tabs-slider-wrapper').style.display = 'none';
        },
        handleHoverOut() {
            document.querySelector('.v-tabs-slider-wrapper').style.display = 'block';
        },
        handleKeyPress(event) {
            let query = {
                search: this.search,
                type: this.type,
                tab: this.tab == 0 ? 'For Sale' : 'For Rent',
                status: this.tab == 0 ? 'For Sale' : 'For Rent'
            }
            this.$router.push({name: 'search', query : query }).catch(err => {
                // Ignore the vuex err regarding  navigating to the page they are already on.
                if (
                  err.name !== 'NavigationDuplicated' &&
                  !err.message.includes('Avoided redundant navigation to current location')
                ) {
                  // But print any other errors to the console
                  logError(err);
                }
              });

        },
        clearTextField() {
            this.search = '';
        },
    },
    watch: {
        navStatus: function(newVal, oldVal) {
            if(newVal !== false || newVal !== ''){
                this.tab = newVal == 'For Sale' ? 0 : 1;
            }
        },
        tab: function(newVal, oldVal) {
            if(newVal !== null && oldVal !== null){
                //this.tab = newVal == 'For Sale' ? 0 : 1;
                let status = newVal == 0 ? 'For Sale' : 'For Rent';
                this.$router.push({name: 'home', query : { status : status } }).catch(err => {
                // Ignore the vuex err regarding  navigating to the page they are already on.
                if (
                  err.name !== 'NavigationDuplicated' &&
                  !err.message.includes('Avoided redundant navigation to current location')
                ) {
                  // But print any other errors to the console
                  logError(err);
                }
              });
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .search-container {
        max-width: 800px;
        ::v-deep{
          .v-tabs-bar__content .v-tab:hover ~ .v-tabs-slider-wrapper {
          //  .v-tabs-slider-wrapper {
              display: none;
          //  }
          }
            .v-tabs-bar__content .v-tab:hover ~ .v-tabs-slider-wrapper {
              display: none;
            }
            .v-card {
              max-width: 100%;
              margin: 0 auto!important;
            }
            .v-tabs-bar{
                background-color: transparent;
                a {
                  color: inherit;
                  text-decoration: none;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  //font-weight: bold;
                }
            }
            .v-card__text {
                padding: 1rem 1rem .5rem 1.5rem;
                @media only screen and (max-width: 600px) {
                    padding: .5rem 1rem;
                }
            }
            .v-tab {
                //background: rgba(255,255,255,.1);
                background-color: transparent;
                padding: 0;
                //border-radius: 4px 4px 0 0;
                &::after {
                  content: " ";
                  background-color: #0E5EA3;
                  height: 4px;
                  width: 100%;
                  position: absolute;
                  bottom: 8px;
                  display: none;
                }
                &:hover {
                  &::after {
                    display: block;
                  }
                }
            }
            .v-tab.v-tab--active {
                //background: rgba(255,255,255,.2);
            }
            .v-tabs-slider-wrapper {
                bottom: 8px;
                height: 4px!important;
            }
            .v-tabs-slider {
                background-color: $primary;
            }

            .v-card {
                background: rgba(255,255,255,.2);
            }
            .theme--light.v-tabs-items {
                background-color: transparent;
            }
            .v-btn {
                background-color: $primary;
                min-height: 60px;
            }
            .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
                min-height: 60px;
            }
            .v-text-field__details {
                display: none;
            }
            .text-field {
                position: relative;
                .v-icon {
                    position: absolute;
                    top: 12px;
                    right: 23px;
                    font-size: 2.2rem;
                }
            }
            @media only screen and (max-width: 600px) {
                .col-12 {
                    a, button { width: 100%; }
                }
            }
        }

    }
</style>
