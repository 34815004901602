<template>
    <v-container fluid>
        <h1>Listings</h1>
        <v-divider class="my-4"></v-divider>
        <v-card-title class="mb-4">
            <router-link :to="{ name: 'addlisting' }">
                <v-btn
                    color="primary"
                    dark
                    x-large
                >Add Listing
                </v-btn>
            </router-link>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            >
            </v-text-field>
        </v-card-title>
        <v-card>

            <!-- <v-data-table
                :headers="headers"
                :items="listings"
                :search="search"
                :server-items-length="pagination.itemsLength"
                :options.sync="pagination"
                @update:options="tablePaginated"
            > -->
            <v-data-table
                :headers="headers"
                :items="listings"
                :loading="loading"
                :options.sync="options"
                :server-items-length="totalListing"
            >
                <template v-slot:item.availability="{ item }">
                  <template v-if="item.type == 'Project'">{{ item.availability }}</template>
                  <template v-else>
                    <template v-if="item.availability == 'Available'">{{ item.availability }}</template>
                    <template v-else>Sold</template>
                  </template>
                </template>
                <template v-slot:item.listing_status="{ item }">
                  {{ listingStatus(item) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <div class="d-flex my-4">
                        <router-link :to="{ name: 'viewlisting', params: { id: item.id } } ">
                            <v-btn
                                color="primary"
                                rounded
                                class="ml-2"
                            >
                                View
                            </v-btn>
                        </router-link>
                        <router-link :to="{ name: 'editlisting', params: { id: item.id } } ">
                            <v-btn
                                color="primary"
                                rounded
                                class="ml-2"
                            >
                                Edit
                            </v-btn>
                        </router-link>
                        <DeleteListingModal :item="item" @deleteListing="deleteListing"/>
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>

// import AddListing from '@/components/AddListing'
import EditListingModal from '@/components/modal/EditListing'
import ViewListingModal from '@/components/modal/ViewListing'
import DeleteListingModal from '@/components/modal/DeleteListing'
import { mapActions } from 'vuex'
import { ACTION } from '@/store/action-types'

export default {
  name: 'DashboardListings',
  components: {
    EditListingModal,
    ViewListingModal,
    DeleteListingModal
  },
  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true
    },
    search () {
      this.fetchData()
    }
  },
  data () {
    return {
      search: '',
      totalListing: 0,
      loading: false,
      headers: [
        { text: 'Name', value: 'title' },
        { text: 'Type', value: 'type' },
        { text: 'Listing Status', value: 'listing_status' },
        { text: 'Listing Address', value: 'location' },
        { text: 'Project', value: 'project_name' },
        { text: 'Developer', value: 'contractor' },
        { text: 'Availability', value: 'availability' },
        { text: 'Actions', value: 'actions', class: 'actions', sortable: false }
      ],
      listings: [],
      options: {}
    }
  },
  methods: {
    ...mapActions({
      get_listings: ACTION.GET_ADMINLISTINGS,
      create_listing: ACTION.CREATE_LISTING,
      update_listing: ACTION.UPDATE_LISTING,
      delete_listing: ACTION.DELETE_LISTING
    }),
    tablePaginated (event) {
      console.log('event', event)
    },
    toData (data) {
      return data.map(item => ({
        id: item.id,
        title: item.name,
        contractor: item.developer_name,
        listing_status: item.property_status,
        type: item.property_type,
        location: item.location,
        project_name: item.project_name,
        availability: item.availability,
        subtype: item.property_subtype
      }))
    },
    fetchData () {
      this.loading = true
      const { page, itemsPerPage } = this.options
      this.get_listings({ page, itemsPerPage, q: this.search })
        .then(res => {
          this.listings = this.toData(res.data.results)
          this.loading = false
          this.totalListing = res.data.count
        })
    },
    updateListing (data) {
      this.update_listing(data)
    },
    deleteListing (id) {
      this.delete_listing({ id })
      this.listings = this.listings.reduce((accu, cur) => {
        if (cur.id !== id) {
          accu.push(cur)
        }
        return accu
      }, [])
    },
    createListing (data) {
      this.create_listing(data)
        .then(res => {
          this.listings = this.toData(res.data)
        })
    },
    listingStatus (item) {
      return item.listing_status == 'Ready for Occupancy' ? ['memorial', 'lot only'].includes(item.subtype.toLowerCase()) ? 'Ready-to-use' : 'Ready-for-occupancy' : item.listing_status;
    },
  }
}
</script>
<style lang="scss" scoped>

</style>
