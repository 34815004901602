<template>
    <v-container fluid>
        <h1>Salespersons</h1>
        <v-divider class="my-4"></v-divider>
        <v-card-title class="mb-4">
            <AddSalespersonModal />
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            >
            </v-text-field>
        </v-card-title>
        <v-card>

            <v-data-table
                :headers="headers"
                :items="salespersons"
                :search="search"
                :loading="loading"
            >
                <template v-slot:item.contact="{ item }">
                    {{ item.contact }} <br />
                    {{ item.emal_address }}
                </template>
                <template v-slot:item.license="{ item }">
                    <div v-for="(license, index) in item.license">
                        {{license.name}}
                    </div>
                </template>
                <template v-slot:item.actions="{ item }">
                <div class="d-flex my-4">
                    <ViewSalesperson :item="item" />
                    <v-btn color="primary" rounded dark small
                        @click="showUpdate(item)"
                        class="mx-1"
                    >
                        Update
                    </v-btn>
                    <v-btn color="error" rounded dark small
                        @click="showDelete(item)"
                    >
                        Delete
                    </v-btn>
                </div>
            </template>
            </v-data-table>
        </v-card>
        <DeleteDialog
          :show="deleteItem"
          :item="activeItem"
          @delete="handleDelete"
          @close="closeDialog"
          title="Delete Salesperson"
          btnTitle="Delete"/>
        <EditSalesperson
          :show="updateItem"
          :item="activeItem"
          @close="closeDialog"
          @update="updateDone"
          btnTitle="Delete"/>
    </v-container>
</template>
<script>

    import AddSalespersonModal from '@/components/modal/AddSalesPerson'
    import ViewSalesperson from '../components/modal/ViewSalesperson.vue'
    import DeleteDialog from '../components/modal/DeleteDialog.vue';
    import EditSalesperson from '../components/modal/EditSalesperson.vue';
    import { mapActions } from 'vuex'

    export default {
        name: 'Salespersons',
        components: {
            AddSalespersonModal,
            DeleteDialog,
            EditSalesperson,
            ViewSalesperson
        },
        data() {
            return {
                activeItem: null,
                deleteItem: false,
                updateItem: false,
                search: '',
                headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Name', value: 'name'},
                    {text: 'Position', value: 'position', sortable: false},
                    {text: 'Contact Numbers', value: 'contact', sortable: false},
                    {text: 'License', value: 'license', sortable: false},
                    {text: 'Actions', value: 'actions', class: 'actions', sortable: false}
                ],
                loading: false,
                // salespersons: [],
                salespersons: [],
                add_form_errors: {
                    name: false
                },
                current_edited_item: null,
                addDialogModal: false,
                edit_form_errors: {
                    name: false
                },
                editDialogModal: false
            }
        },
        mounted() {
          this.getSalespersonsList();
        },
        methods: {
            ...mapActions(["getSalespersons", "deleteSalesperson", "deleteLicense"]),
            getSalespersonsList() {
                this.loading = true;
              this.getSalespersons()
                .then((response) => {
                    this.salespersons = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                })
            },
            async handleDelete() {
                let item = this.activeItem;
              await this.deleteSalesperson({id: this.activeItem.id})
                .then(() => {
                //     console.log('item.license', item.license)
                //   item.license.forEach(async(element) => {
                //     await this.deleteLicense(element.id)
                //   });
                  this.getSalespersonsList();
                  this.closeDialog()
                })
                .catch((error) => {
                  console.log(error);
                  this.closeDialog()
                })
            },
            showUpdate(item) {
              console.log('update item', item)
              this.activeItem = item;
              this.updateItem = true;
            },
            showDelete(item) {
              this.activeItem = item;
              this.deleteItem = true;
            },
            closeDialog() {
              this.activeItem = null;
              this.deleteItem = false;
              this.updateItem = false;
            },
            updateDone() {
                this.closeDialog();
                this.getSalespersonsList();
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>
