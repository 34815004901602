<template>
    <v-card
        :to="{ name: 'listingpage', params: { slug: item.slug } }"
        class="mx-4 my-4"
        :max-width="width"
    >

        <v-img
            height="270"
            width="400"
            :src="item.image"
        >
            <div class="img-overlay-bottom">
                <div class="content">
                    <v-row>
                        <span class="list-type"><span>{{ itemType }}</span>{{ status }}</span>
                    </v-row>
                    <v-row>
                        <span class="listing-price font-weight-medium">
                            <span v-html="item.price"></span>
                            <span v-if="monthly_equity" class="equity font-weight-regular font-italic ml-3">
                                Eq. {{ monthly_equity }}/mo
                            </span>
                        </span>
                    </v-row>
                </div>
            </div>
        </v-img>

        <v-card-title class="font-weight-medium pt-3 pb-0 blue--text darken-1">{{ item.title }}</v-card-title>
        <v-card-text class="py-0">
            <v-row v-if="item.developer" class="px-3 my-1 dev-project">
                <router-link :to="{name: 'projectpage', params: {'slug': item.project_slug}}" class="pr-1">
                  {{ item.project }}
                </router-link>
                <router-link :to="{name: 'developerpage', params: {'slug': item.developer_slug}}" class="pl-1">
                  {{ item.developer }}
                </router-link>
            </v-row>
            <v-row v-else class="px-3 my-1 dev-project">
                Brokerage
            </v-row>
            <v-row class="px-2">
                <v-icon class="mr-1 map-marker" x-small>mdi-map-marker</v-icon>
                <span class="subheading location">{{ item.location }}</span>
            </v-row>
            <v-row
                align="center"
                class="justify-start px-3 bed-bath-floor pb-3"
            >
                <div class="mr-5" v-show="item.subtype !== 'Lot Only' && item.subtype !== 'Building' && unitType !== 'Studio'">
                  <span class="mr-1 font-weight-bold">{{ item.bedrooms }}</span>
                  <span class="subheading">bed</span>
                </div>
                <div class="mr-5" v-if="item.subtype !== 'Lot Only' && item.subtype !== 'Building'"><span class="mr-1 font-weight-bold">{{ item.bathroom }}</span><span
                    class="subheading">bath</span></div>
                <div class="mr-5" v-if="item.subtype !== 'Lot Only'">
                  <span class="subheading mr-1"><span class="font-weight-bold">{{ floorarea }}</span> sqm</span>
                  <span class="subheading">flr</span>
                </div>
                <div class="mr-5" v-if="item.subtype !== 'Condominium'">
                  <span class="subheading mr-1"><span class="font-weight-bold">{{ lotarea }}</span> sqm</span>
                  <span class="subheading">lot</span>
                </div>
                <!-- <div>
                    <span class="subheading mr-2" v-if="item.parking">With Parking</span>
                    <span class="subheading mr-2" v-else>No Parking</span>
                </div> -->
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import {mapActions} from 'vuex'
    import {ACTION} from '@/store/action-types'

    export default {
        name: 'List',
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
              listing_details: {}
            }
        },
        computed: {
            listingTitle() {
                return this.item.title.replace(/\s+/g, '-')
            },
            width() {
                return this.$route.name == 'home' ? '360' : '360';
            },
            floorarea() {
              return Number(this.item.floorarea).toLocaleString()
            },
            lotarea() {
              return this.item.lotarea ? Number(this.item.lotarea).toLocaleString() : 0;
            },
            itemType() {
                switch(this.item.type){
                    case 'Residential':
                      switch(this.item.subtype){
                          case 'Lot Only':
                            return 'Residential lot';
                            break;
                          default:
                            return this.unitType;
                            break;
                      }
                      break;
                    case 'Commercial':
                      switch(this.item.subtype){
                          case 'Lot Only':
                            return 'Commercial lot';
                            break;
                          default:
                            return 'Commercial';
                            break;
                      }
                      break;
                    case 'Special properties':
                      return 'Special Property';
                      break;
                    default:
                      return `${this.item.type} lot`;
                      break;
                }
            },
            monthly_equity() {
              return this.item.monthly_equity? this.item.monthly_equity.replace(/\s+/g, '') : '';
            },
            statusBefore() {
              return this.item.status.charAt(0).toUpperCase() + this.item.status.slice(1).toLowerCase();
            },

            status() {
              return this.statusBefore == 'Ready for occupancy' ? ['memorial lot', 'lot only'].includes(this.subtype.toLowerCase()) ? 'Ready-to-use' : 'Ready-for-occupancy' : this.statusBefore;
            },
            
            unitTypeBefore() {
              return this.item ? this.item.unit_type ?  this.item.unit_type : this.listing_details.unit_type : '';
            },
            unitType() {
                return this.unitTypeBefore ? this.unitTypeBefore.charAt(0).toUpperCase() + this.unitTypeBefore.slice(1).toLowerCase() : '';
            }
        },
        created() {
            this.fetch_data(this.item.slug)
                .then(res => {
                    this.listing_details = {
                        title: res.data.name,
                        property_type: res.data.property_type,
                        property_subtype: res.data.property_subtype,
                        unit_type: res.data.property_unit_type
                    }
                })
                .catch( res => {
                    if (res.status == 404) {
                        console.log("notFound")
                    }
                })
        },
        methods: {
            ...mapActions({
                fetch_projectdata: ACTION.FETCH_PROJECT,
                fetch_data: ACTION.GET_LISTING
            }),
        },
    }
</script>
<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
    .map-marker {
      margin-left: 2px
    }

    .listing-price {
      line-height: 1;
      margin: 0 0 8px;
    }

    ::v-deep {
        .container {
            padding: 4rem 0;
        }

        .v-card {
            &.card-section {
                box-shadow: none;
            }
        }

        .v-card .col {
            padding: 0;
        }

        .v-card__subtitle {
            color: $primary !important;
            font-size: 1rem;
            font-weight: bold;
            font-family: 'Karla';
            text-transform: uppercase;
            border-bottom: 1px solid $primary;
            padding: 1rem 3rem 1rem 2rem;
        }

        .v-card__text {
            padding: 0 16px!important;
            line-height: 1.5;
        }

        .v-card__title {
            font-size: 1.1rem;
            line-height: 1;
            padding-top: 9px!important;
            padding-bottom: 1px!important;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            white-space: nowrap;
            display: block;
        }

        .location {
            font-size: .8rem;
            color: #212121;
        }

        .bed-bath-floor {
            color: #212121;
            font-size: .8rem;
            padding-bottom: 7px!important;
            .font-weight-bold {
              font-weight: 900!important;
            }
            .mr-5{
              margin-right: 20px!important;
            }
            .mr-5:last-child {
              margin-right: 0px!important;
            }
        }

        .v-card__text .text--primary,
        .v-container__main-title {
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: normal;
            margin-bottom: 2rem;
        }

        .v-container__main-title {
            text-align: center;
            margin-bottom: 2rem;
        }

        .v-image {
            .img-overlay-bottom {
                position: absolute;
                bottom: 0px;
                left: 0px;
                background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
                color: $white;
                width: 100%;
                height: 30%;
                padding: 0px 8px;
                font-size: 1.4rem;

                .content {
                    position: absolute;
                    bottom: 0;
                    left: 28px;
                }

                .list-type {
                    font-size: .8rem;
                    span {
                      border-right: 1px solid $white;
                      margin-right: 6px;
                      padding-right: 8px;
                      height: 11px;
                      display: inline-flex;
                      align-items: center;
                    }
                }
            }

            .img-overlay-top {
                position: absolute;
                top: 0px;
                left: 0px;
                color: $white;
                width: 100%;
                height: 10%;
                padding: 0px 8px;

                .chip {
                    opacity: .4;
                }

                .developer {
                    font-size: .8rem;
                }

            }

            &:hover {
                .chip {
                    opacity: 1;
                }
            }
        }

        .dev-project {
            font-size: .8rem;
            color: #1e1e1e;
            margin-bottom: 6px!important;
            height: 10px;
            line-height: 1;

            a {
                color: #1e1e1e;
                height: 14px;
                line-height: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 48%;
                white-space: nowrap;
                display: block;
                padding-top: 1px;

                &:hover {
                    color: #0d47a1;
                }
                &:first-child{
                  border-right: 1px solid #1e1e1e;
                  padding-right: 11px!important;
                  margin-right: 7px;
                }
            }
        }

        .equity {
            font-size: .9rem;
        }
    }
</style>
