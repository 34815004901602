import { render, staticRenderFns } from "./DeleteProject.vue?vue&type=template&id=12a18cf3&scoped=true&"
import script from "./DeleteProject.vue?vue&type=script&lang=js&"
export * from "./DeleteProject.vue?vue&type=script&lang=js&"
import style0 from "./DeleteProject.vue?vue&type=style&index=0&id=12a18cf3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a18cf3",
  null
  
)

export default component.exports