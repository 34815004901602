<template>
  <v-container fluid d-flex pa-0 class="dashboard-page">
    <v-col class="col-3 pa-0">
      <Sidebar />
    </v-col>
    <v-col class="col-9">
      <div class="main-container">
        <router-view></router-view>
      </div>
    </v-col>
  </v-container>
</template>
<script>
import router from '@/router'

import Sidebar from '@/components/Sidebar.vue'
import Developers from '@/views/Developers.vue'
import Projects from '@/views/Projects.vue'
import Listings from '@/views/Listings.vue'
import ListingCreate from '@/views/ListingCreate.vue'

export default {
  name: 'Dashboard',
  data () {
    return {
      //componentName: ''
    }
  },
  // created () {
  //   const user_type = this.$store.getters.user_type
  //   switch (user_type) {
  //     case 1:
  //       this.componentName = 'Admin'
  //       // router.push({name: 'developers'});
  //       break
  //     case 2:
  //       this.componentName = 'Moderator'
  //       break
  //     default:
  //       this.componentName = 'Member'
  //       break
  //   }
  // },
  components: {
    Sidebar,
    Developers,
    Projects,
    Listings,
    ListingCreate
  }
}
</script>
