<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          rounded
          v-bind="attrs"
          v-on="on"
          class="ml-2"
        >
          View
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline my-4">{{ item.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="mr-1">mdi-map-marker</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ item.location }}</v-list-item-title>
              <v-list-item-subtitle>Location</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="mr-1">mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ item.contact_person }}</v-list-item-title>
              <v-list-item-subtitle>Contact Person</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon class="mr-1">mdi-phone</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ item.contact_person }}</v-list-item-title>
              <v-list-item-subtitle>Contact Number</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="dialog = false"
          >
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: 'ViewPListingModal',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>
<style scoped>
.col {
    padding-bottom: 0;
    padding-top: 0;
}
</style>
