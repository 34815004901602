<template>
    <v-row>
        <v-divider
                    :vertical="true"
                ></v-divider>
        <v-col cols="12">
            <v-select
                :items="sortedProjects"
                v-model="data.project"
                label="Project*"
                :rules="[v => !!v || 'Field is required']"
                @change="handleProjectChange"
                outlined
                required
            ></v-select>
        </v-col>
        <v-col cols="12">
            <v-select
                :items="property_unit_type"
                v-model="data.property_unit_type"
                label="Unit type*"
                :rules="[property_unity_rules]"
                :disabled="property_unit_type && property_unit_type.length === 0"
                :readonly="property_unit_type && property_unit_type.length === 0"
                :required="property_unit_type && property_unit_type.length > 0"
                @change="handlePropertyUnitTypeChange"
                outlined
            ></v-select>
        </v-col>
        <v-col cols="12">
            <v-select
                :items="property_status_options"
                v-model="data.property_status"
                label="Property statussss*"
                outlined
                required
            ></v-select>
        </v-col>

        <v-col cols="6">
            <v-text-field
                v-model="minimum_price_display"
                label="Minimum price"
                outlined
                required
                prefix="₱"
                class="mb-0"
                @keyup="numOnly($event, 'minimum_price_display')"
            ></v-text-field>
        </v-col>
        <v-col cols="6">
            <v-text-field
                v-model="maximum_price_display"
                label="Maximum price"
                outlined
                required
                prefix="₱"
                class="mb-0"
                @keyup="numOnly($event, 'maximum_price_display')"
            ></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-text-field
                v-model="reservation_fee_display"
                label="Reservation fee"
                outlined
                prefix="₱"
                class="mb-0"
                @keyup="numOnly($event, 'reservation_fee_display')"
            ></v-text-field>
        </v-col>
        <v-col cols="6">
            <v-text-field
                v-model="data.equity_percentage"
                label="Equity percentage"
                outlined
                required
                prefix="%"
                class="mb-0"
                @keyup="numOnly($event, 'data.equity_percentage')"
            ></v-text-field>
        </v-col>
        <v-col cols="6">
            <v-text-field
                label="Equity terms (months)"
                v-model="data.equity_terms"
                outlined
                required
                class="mb-0"
                @keyup="numOnly($event, 'data.equity_terms')"
            ></v-text-field>
        </v-col>
        <v-col cols="6">
            <v-text-field
                v-model="equity"
                label="Total equity amount"
                outlined
                required
                prefix="₱"
                class="mb-0"
                readonly
            ></v-text-field>
        </v-col>
        <v-col cols="6">
            <v-text-field
                v-model="loanable_amount"
                label="Loanable amount"
                outlined
                required
                prefix="₱"
                class="mb-0"
                readonly
            ></v-text-field>
        </v-col>
        <v-col cols="6" v-if="!isMonthlyEquityFixed">
            <v-text-field
                v-model="monthly_equity"
                label="Monthly equity"
                outlined
                required
                prefix="₱"
                class="mb-0"
                readonly
            ></v-text-field>
        </v-col>
        <v-col cols="6" v-else>
            <v-text-field
                v-model="equity_fixed_display"
                label="Monthly equity"
                outlined
                required
                prefix="₱"
                class="mb-0"
            ></v-text-field>
        </v-col>
        <v-col cols="6">
            <v-checkbox
                v-model="isMonthlyEquityFixed"
                label="Add a fixed equity"
            ></v-checkbox>
        </v-col>
    </v-row>
</template>

<script>
    import {mapActions} from "vuex"
    import {GET_PROJECT} from '@/store/action-types'
    import SingleFileUpload from '@/components/SingleFileUpload.vue'
    import { eventBus } from '@/services/eventBus';

    export default {
        name: 'CreateListingProject',
        components: {
            SingleFileUpload
        },
        props: [
            'options',
            'initial_data'
        ],
        computed: {
            minimum_price_display: {
                get() {
                    return this.data.min_price ? this.data.min_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('')
                    this.$set(this.data, 'min_price', no_comma)
                }
            },
            maximum_price_display: {
                get() {
                    return this.data.max_price ?this.data.max_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('')
                    this.$set(this.data, 'max_price', no_comma)
                }
            },
            reservation_fee_display: {
                get() {
                    return this.data.reservation_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('').replace(/[^\d.-]/g, '')
                    this.$set(this.data, 'reservation_fee', no_comma)
                }
            },
            equity_fixed_display: {
                get() {
                    return this.data.equity_fixed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                set(value) {
                    let no_comma = value.toString().split(',').join('').replace(/[^\d.-]/g, '')
                    this.$set(this.data, 'equity_fixed', no_comma)
                }
            },
            property_status_options() {
                let options = this.default_options.property_status;
                if(this.property_unit_type == 4 || this.property_unit_type == 0) {
                    options = [ {text: 'Pre-selling', value: 0}, {text: 'Ready-to-use', value: 1} ]
                }
                return options;
            },
            equity() {
                let result = parseFloat(this.data.equity_percentage) / 100.0;
                return ((parseFloat(this.data.max_price) * result) - parseFloat(this.data.reservation_fee)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            loanable_amount() {
                let max_price = parseFloat(this.data.max_price)
                let result = parseFloat(this.data.equity_percentage) / 100.0
                let equity = parseFloat(max_price) * result
                return (max_price - equity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            monthly_equity() {
                let no_comma = this.equity.toString().split(',').join('')
                return (parseFloat(no_comma) / parseFloat(this.data.equity_terms)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            property_unit_type() {
                return this.options.project_unit_types[this.data.project]
            },
            isEquityFixed() {
              return this.data.equity_fixed == 0 ? false : true;
            },
            sortedProjects() {
              return this.options.projects.sort((a, b) => (a.text > b.text) ? 1 : -1)
            }
        },
        created() {
            this.$nextTick(() => {
                if (this.initial_data) {
                    this.data = {
                        project: this.initial_data.project,
                        property_unit_type: this.initial_data.property_unit_type.length > 0 ? this.initial_data.property_unit_type[0] : 0,
                        property_status: this.initial_data.property_status,
                        min_price: this.initial_data.minimum_price,
                        max_price: this.initial_data.maximum_price,
                        equity_terms: this.initial_data.equity_terms,
                        equity_percentage: this.initial_data.equity_percentage,
                        reservation_fee: this.initial_data.reservation_fee,
                        equity_fixed: this.initial_data.equity_fixed ? this.initial_data.equity_fixed : 0
                    }
                    if(this.initial_data.project) {
                        this.handleProjectChange(this.initial_data.project)
                    }
                    //to disable bedroom input
                    if(this.initial_data.property_unit_type) {
                      let property_unit_type = this.initial_data.property_unit_type.length > 0 ? this.initial_data.property_unit_type[0] : 0
                      this.handlePropertyUnitTypeChange(property_unit_type)
                    }
                    if(this.initial_data.equity_fixed > 0) {
                      this.isMonthlyEquityFixed = true;
                    }
                }
                this.$emit('update', this.data)
            });
        },
        data: () => ({
            default_options: {
                property_status: [
                    {text: 'Pre-selling', value: 0},
                    {text: 'Ready-for-occupancy', value: 1},
                ]
            },
            data: {
                project: 0,
                property_unit_type: -1,
                property_status: 0,
                min_price: 10000,
                max_price: 20000,
                equity_terms: '12',
                equity_percentage: '10',
                reservation_fee: 0,
                dhsud_lts: '',
                equity_fixed: 0
            },
            property_type: null,
            isMonthlyEquityFixed: false
        }),
        methods: {
            ...mapActions({
                fetch_project_data: GET_PROJECT
            }),
            property_unity_rules(value) {
                if (!this.property_unit_type || this.property_unit_type.length == 0) {
                    return true
                }
                if (value >= 0) {
                    return true
                }
                return 'Field is Required'
            },
            numOnly(event, value) {
              this[value] = event.target.value.replace(/[^\d]/g,'');
              event.target.value = this[value];

            },
            handlePropertyUnitTypeChange(event) {
                if(event) {
                    let text = this.property_unit_type?.find(obj => obj.value === event).text;
                    eventBus.$emit('handlePropertyUnitTypeChange', {data: text});
                }
            },
            handleProjectChange(value) {
              let type = this.options.projects.find(obj => obj.value === value);
              this.property_type = type ? type.property_type : 0;
            }
        },
        watch: {
            data: {
                handler(newValue, oldValue) {
                    this.$emit('update', newValue)
                },
                deep: true
            },
            isMonthlyEquityFixed: function(value) {
              if(!value) {
                this.data.equity_fixed = 0;
              }
            }
        }
    }
</script>
